@import 'styles/main.scss';

.loaderWrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  div {
    width: 10%;
  }
}

.sorting {
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;

  @media screen and (max-width: 1024px) {
    display: block;
    overflow: auto;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .left {
    display: flex;
    gap: 8px;

    @media screen and (max-width: 1024px) {
      width: auto;
      overflow: auto;
      padding-right: 16px;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .filter {
    padding: 9px 16px;
    border: 1px solid var(--accent-second);
    border-radius: 20px;
    display: flex;
    align-items: end;
    gap: 8px;
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    &::before{
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: -1;
      border-radius: inherit;
      pointer-events: none;
      transition: opacity 0.3s;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .free {
    @media screen and (min-width: $xs-up) {
      &:hover::before {
        opacity: 0.1;
        background: var(--ui-02-main);
      }
    }
  }

  .new {
    @media screen and (min-width: $xs-up) {
      &:hover::before {
        opacity: 0.1;
        background: var(--ui-03-main);
      }
    }
  }

  .hot {
    @media screen and (min-width: $xs-up) {
      &:hover::before {
        opacity: 0.1;
        background: var(--ui-01-main);
      }
    }
  }

  .sale {
    @media screen and (min-width: $xs-up) {
      &:hover::before {
        opacity: 0.1;
        background: var(--ui-05-main);
      }
    }
  }

  .topup {
    @media (min-width: $xs-up) {
      &:hover::before {
        opacity: 0.1;
        background: var(--ui-04-main);
      }
    }
  }

  .right {
    display: flex;
    gap: 8px;
    align-items: center;
    color: var(--text-light);

    .filter {
      color: var(--text);

      svg {
        width: 13px;
        height: 13px;
      }
    }
  }
}

.selectBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .selectWrapper {
    width: 260px;
  }

  .dropdown {
    height: 120px;
  }
}

.list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  @media screen and (max-width: 1300px) {
    gap: 16px;
  }

  @media screen and (max-width: 1140px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 724px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 2000px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.request-card {
  padding: 20px 18px;
  border-radius: 36px;
  box-shadow: var(--card-shadow);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .image {
    margin-bottom: 24px;
    width: 116px;
  }

  .title {
    @include font-20-bold;
    margin-bottom: 4px;
  }

  .text {
    @include font-12;
  }

  .button {
    width: 100%;
    height: 48px;
  }
}

.is-free {
  background-color: var(--ui-02-main);
  color: var(--accent-light);
  border-color:  var(--ui-02-main) !important;

  @media screen and (min-width: $xs-up) {
    &:hover {
      background-color: var(--ui-02-main);
    }
  }

  svg path {
    fill: var(--accent-light) !important;
  }
}

.is-new {
  background-color: var(--ui-03-main);
  color: var(--accent-light);
  border-color:  var(--ui-03-main) !important;

  @media screen and (min-width: $xs-up) {
    &:hover {
      background-color: var(--ui-03-main);
    }
  }

  svg path {
    fill: var(--accent-light) !important;
  }
}

.is-hot {
  background-color: var(--ui-01-main);
  color: $white;
  border-color: var(--ui-01-main) !important;

  @media screen and (min-width: $xs-up) {
    &:hover {
      background-color: $orange;
    }
  }

  svg path {
    fill: $white !important;
  }
}

.is-sale {
  background-color: var(--ui-05-main);
  color: $white;
  border-color: var(--ui-05-main) !important;

  @media screen and (min-width: $xs-up) {
    &:hover {
      background-color: var(--ui-05-main);
    }
  }

  svg path {
    fill: $white !important;
  }
}

.is-topup {
  background-color: var(--ui-04-main);
  color: $white;
  border-color: var(--ui-04-main) !important;

  @media screen and (min-width: $xs-up) {
    &:hover {
      background-color: var(--ui-04-main);
    }
  }

  svg path {
    fill: $white !important;
  }
}

.pagination {
  width: 100%;
  margin: 32px 0 0 0;
  display: flex;
  gap: 40px;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .button {
    height: 32px;

    // @media screen and (max-width: $sm) {
    //   width: 100%;
    // }
  }
}

.top {
  margin-bottom: 20px;
}

.form {
  height: 100%;
  overflow-y: auto;

  @media screen and (min-width: $xs-up) {
    width: 480px;
  }
}

.fixedButtonSelect {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  border-radius: 24px 24px 0 0;
  width: 100%;
  height: auto;
  padding: 32px 20px 90px 20px;
  z-index: 9998;
  animation: slideUp 0.3s ease-out;

  .buttonSelect {
    display: flex;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    height: 22px;
    padding: 20px 15px;
    text-align: left;
    justify-content: space-between;
    color: #323236;

    &:hover {
      background: var(--accent-light);
    }

    &.activeSelect {
      background: var(--accent-light);

      .checkIconSelect {
        display: flex;
        margin-top: 4px;
        width: 20px;
        height: 20px;

        svg {
          fill: var(--accent);
        }
      }
    }
  }

  &.close {
    animation: slideDown 0.3s ease-out;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}
