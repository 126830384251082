@import 'styles/helpers/variables.scss';

.footer {
  margin-top: auto;
  background-color: transparent;
  position: relative;
  z-index: 2;

  @media screen and (max-width: $sm) {
    display: none;
  }
}

.inside {
  z-index: 1;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0;
  background: #fff;
  border: none;

  .inner {
    padding: 20px 0;
    margin: auto;
    max-width: 1296px;
    border-top: 1px solid rgba(160, 156, 172, 0.3);

    @media screen and (max-width: 1336px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(160, 156, 172, 0.3);
  padding-top: 20px;
  padding-bottom: 20px;

  @media screen and (max-width: $xs-up) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  &__ru {
    display: flex;
    align-items: start;
    justify-content: space-between;
    border-top: 1px solid rgba(160, 156, 172, 0.3);
    padding: 20px 72px;
    gap: 20px;

    @media screen and (max-width: $xs) {
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 32px;
    }
    @media screen and (max-width: $phone) {
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 16px;
    }
  }
}

.copy {
  color: var(--text-light);
  @include font-12;
}

.menu {
  display: flex;
  align-items: center;

  @media screen and (max-width: $xs-up) {
    margin-bottom: 12px;
  }
}

.item {
  margin-right: 32px;

  &:last-child {
    margin-right: 0;
  }
}

.link {
  color: var(--text-light);
  transition: $transition;
  @include font-12;

  &:hover {
    color: var(--text);
  }
}

.fixed {
  position: fixed;
  bottom: 0;
  z-index: 4;
  background-color: #fff;
  left: 0;
  right: 0;
  padding-left: 16px;
  padding-right: 16px;
  border: none;
}

.image {
  filter: grayscale(100%);
}

.auth {
  display: block;
  .image {
    filter: grayscale(100%) brightness(2.5);
  }

  .inner {
    &__ru {
      border-top: none;
    }
  }

  .copy {
    color: var(--accent-light);
  }
}