@import 'styles/helpers/variables.scss';

.notice {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px 16px;

  .textBlock {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .noticeTitle {
      @include font-14-bold;
    }

    .noticeDescription {
      @include font-12;
    }
  }

  &.default {
    background: var(--ui-04-8);
    border-radius: 12px;
    padding: $space-xl;
    width: 748px;
    align-items: flex-start;

    .icon {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background: linear-gradient(128.22deg, rgba(110, 133, 248, 0.4) 2.75%, #6E85F8 93.39%);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-right: 16px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &.success {
    background: linear-gradient(
        0deg,
        rgba(0, 200, 83, 0.04),
        rgba(0, 200, 83, 0.04)
      ),
      #ffffff;
    border: 1px solid var(--ui-02-main);
  }

  &.warning {
    background: linear-gradient(
        0deg,
        rgba(243, 202, 55, 0.04),
        rgba(243, 202, 55, 0.04)
      ),
      #ffffff;
    border: 1px solid #f3ca37;
  }

  &.error {
    background: linear-gradient(
        0deg,
        rgba(245, 34, 45, 0.04),
        rgba(245, 34, 45, 0.04)
      ),
      #ffffff;
    border: 1px solid #f5222d;
  }
}

.icon {
  margin-right: 12px;
  display: flex;
  width: 14px;
  height: 14px;
  flex-shrink: 0;
}

.text {
  position: relative;
  top: 2px;
}
