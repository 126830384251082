@import 'styles/helpers/variables.scss';

.sticker {
  display: flex;
  padding: 4px 12px;
  align-items: center;
  border-radius: 36px;
  max-width: max-content;

  &__text {
    display: inline-block;
    @include font-11-bold;
    letter-spacing: 0.24px;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // text-transform: uppercase;
  }

  &_purple {
    color: var(--ui-03-main);
    background: var(--ui-03-8);

    &_global {
      color: #8362f3;
      background: #8362f314;
    }
  }

  &_yellow {
    background-color: rgba(#fab005, 0.08);
    color: #fab005;
  }

  &_green {
    background-color: var(--ui-02-8);
    color: var(--ui-02-main);

    &_global {
      background-color: rgba(#37b24d, 0.08);
      color: #37b24d;
    }
  }

  &_red {
    background-color: rgba(#f14b4b, 0.08);
    color: var(--error-color);
  }

  &_orange {
    background-color: var(--ui-05-8);
    color: var(--ui-05-main);
  }
}
