@import 'styles/helpers/variables.scss';

.list {
  display: flex;
  gap: 20px;
  width: 100%;
  overflow: auto;
}

.title {
  font-weight: 500;
  font-size: 32px;
  line-height: 130%;
  display: block;
  margin-bottom: 24px;
}

.item {
  padding: 32px 40px 56px 40px;
  border-radius: 36px;
  background: var(--accent-light);
  width: 100%;
  height: 100%;

  @media screen and (max-width: $xs-up) {
    padding: 25px;
  }
}

.icon {
  width: 56px;
  height: 56px;
  margin-bottom: 24px;

  svg {
    height: 100%;
  }
}

.subtitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 115%;
  margin-bottom: 8px;
}

.text {
  font-size: 20px;
  line-height: 130%;
  margin: 0;
}

.wrap {
  margin-top: 100px;

  @media screen and (max-width: $xs-up) {
    margin-bottom: 80px;
  }
}

// .swiper-slider {
//   width: 100%;
//   max-width: calc(100%/3 - 30px);

//   @media screen and (max-width: $xs-up) {
//     max-width: unset;
//     width: 280px;
//   }
// }

.swiper {
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
  width: calc(100% + 20px);
  align-items: stretch;
}

.swiper-slider {
  height: auto;
  width: 100%;
  max-width: calc(100% / 3 - 30px);
  opacity: 1;

  @media screen and (max-width: $xs-up) {
    width: 310px;
    max-width: unset;
  }
}
