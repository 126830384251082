@import 'styles/main.scss';

.table {
  background: var(--white);
  box-shadow: var(--fire-shadow);
  padding: 24px 24px 40px 24px;
  border-radius: 24px;
  max-width: max-content;

  &__row {
    display: grid;
    grid-template-columns: 30px 126px 222px 222px 222px 126px;
    border-bottom: 1px solid var(--accent-light);

    @media screen and (max-width: 1300px), (max-width: 800px) {
      grid-template-columns: 30px 126px repeat(3, 132px) 126px;
    }

    @media screen and (max-width: 1024px) and (min-width: 800px) {
      grid-template-columns: 30px 126px repeat(3, 162px) 126px;
    }

    &_mob {
      display: none;
    }

    &:first-child {
      @include font-12-bold;

      .table__cell {
        display: flex;
        align-items: center;
        gap: 6px;
        font-weight: 500;

        .container {
          flex-direction: column;
        }

        svg {
          width: 16px;
          height: 16px;
        }

        &:first-child {
          padding: 0;
        }
      }
    }
  }

  &__search {
    padding: 0;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    svg {
      width: 20px;
      height: 20px;
      path {
        stroke: var(--text-light);
      }
    }
  }

  &__sort {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    outline: none;
    color: var(--text);

    @media (max-width: $sm) {
      @include font-12;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 30px 126px 222px 222px 222px 126px;

    @media screen and (max-width: 1300px), (max-width: 800px) {
      grid-template-columns: 30px 126px repeat(3, 132px) 126px;
    }

    @media screen and (max-width: 1024px) and (min-width: 800px) {
      grid-template-columns: 30px 126px repeat(3, 162px) 126px;
    }
  }

  &__cell {
    padding: 12px 16px;

    &:first-child {
      padding: 12px 8px;
      color: var(--text-light);
    }
  }

  &__header {
    @include font-12;
  }

  &__text {
    @include font-11;
    color: var(--text-light);
  }

  &__sticker {
    &--red {
      background-color: var(--error-8);
      color: var(--error-main);
    }

    &--green {
      background-color: var(--green-unknown);
      color: var(--green-unknown-text);
    }

    &--yellow {
      background-color: var(--ui-01-8);
      color: var(--ui-01-main);
    }

    &--blue {
      background-color: var(--ui-04-8);
      color: var(--ui-04-main);
    }
  }
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.label {
  width: fit-content;
  padding: 4px 12px;
  border-radius: 36px;
  @include font-11-bold;
}
