@import 'styles/helpers/variables.scss';

.wrapper {
  margin-top: 48px;
}

.title {
  margin-bottom: 24px;
  @include font-22-bold;
  color: var(--text);

  @media (max-width: $sm) {
    @include font-22-bold;
  }
}

.images {
  display: flex;
  gap: 24px;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

.item {
  position: relative;
  height: 78px;
  width: fit-content;
}

.image {
  height: 100%;
  object-fit: contain;
}
