@import 'styles/main.scss';

.background {
  position: fixed;
  top: -130px;
  right: -130px;
  width: 600px;
  height: 550px;
  z-index: -1;
  background: var(--background-mobile-gradient);
  transform: rotate(45deg);
  pointer-events: none;
}

.wrapper {
  padding-top: 40px;
  padding-bottom: $space-xl;

  iframe {
    border: none;
  }
  .title {
    @include font-32-bold;
    padding: 8px 0 24px 0;
  }

  .headerContainer {
    display: flex;
    flex-direction: column;
    gap: $space-xl;
  }

  .selectContainer {
    .selectBox {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .selectWrapper {
        width: 400px;
      }
    }
  }

  .emptyPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 38.5px 24px;
    gap: 32px;
    margin: 0 -20px;

    &__icon {
      z-index: 1;
      position: relative;
      max-width: 104px;
      max-height: 104px;
    }

    &__image {
      z-index: 1;
      position: relative;
      width: 100%;
    }

    &__text {
      z-index: 1;
      position: relative;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
    }

    &:after {
      content: '';
      width: 100%;
      background-image: var(--cart-back);
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: top;
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
    }

    @media screen and (max-width: $sm) {
      &:after {
        background-image: var(--cart-back-mob);
      }
    }
  }

  .improvadoDashboard {
    iframe {
      height: 500px;
    }
  }
}

.powerbi_dashboard {
  box-shadow: var(--shadow-default);
  border-radius: 12px;
  padding: 24px;
  iframe {
    border: none;
    height: 500px !important;
  }
}
