.menu {
    display: flex;
    align-items: center;

    &__item {
        margin-right: 30px;
        display: flex;
        align-items: center;

        &:last-child {
            margin-right: 0;
        }


        @media screen and (max-width: $md) {
            margin-right: 10px;
        }
    }

    &__icon {
        margin-right: 13px;
        display: flex;

        @media screen and (max-width: $lg) {
            margin-right: 7px;
        }
    }

    &__link {
        color: var(--text);
        @include font-14;
        position: relative;

        &:after {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: var(--accent);
            opacity: 0;
            position: absolute;
            bottom: -5px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            transition: $transition;
        }

        &:hover,
        &.active {

            &:after {
                opacity: 1;
            }
        }

        &-wrap {
            position: relative;
            top: 2px;
        }
    }
}