@import 'styles/helpers/variables.scss';

.amount {
  display: flex;
  align-items: center;
  gap: 2px;
  @include font-12;

  svg {
    width: 12px;
    height: 12px;
  }

  &.red {
    color: var(--error-main);
    svg {
      path {
        stroke: var(--error-main);
      }
    }
  }

  &.green {
    color: var(--success-16);
    svg {
      path {
        stroke: var(--success-16);
      }
    }
  }
}
