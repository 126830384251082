@import 'styles/helpers/variables.scss';

.wrapper {
  padding: 32px 0;

  @media (max-width: 1024px) {
    padding: 46px 0;
  }

  @media screen and (max-width: 480px) {
    margin: 0 -20px;
  }
}

.title {
  display: flex;
  align-items: center;
  gap: 12px;
  @include font-32-bold;

  @media screen and (max-width: 1024px) {
    @include font-22-bold;
  }

  @media screen and (max-width: 480px) {
    margin-left: 16px;
  }

  &__icon {
    width: 38px !important;
    height: 38px !important;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  gap: 72px;

  @media screen and (max-width: 1024px) {
    margin-top: 24px;
  }
}
