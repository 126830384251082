@import 'styles/helpers/variables.scss';

.item {
  display: flex;
  border-radius: 36px;
  overflow: hidden;
  background-color: $white;
  box-shadow:
    0px 33px 100px rgba(28, 20, 37, 0.0201946),
    0px 7.37098px 22.3363px rgba(28, 20, 37, 0.0298054),
    0px 2.19453px 6.6501px rgba(28, 20, 37, 0.05);
  min-height: 273px;

  @media screen and (max-width: $xs) {
    min-height: unset;
    flex-direction: column;
  }
}

.deal {
  padding: 44px 16px;
  min-width: 70px;
  flex-shrink: 0;
  text-align: center;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  writing-mode: vertical-rl;
  color: var(--accent-light);
  transform-origin: center;
  transform: rotate(180deg);
  text-shadow:
    0px 33px 100px rgba(65, 25, 111, 0.0201946),
    0px 7.37098px 22.3363px rgba(65, 25, 111, 0.0298054),
    2px 1px 2px rgba(32, 13, 55, 0.06);

  position: relative;
  &::after {
    content: "";
    background: var(--text-light);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.5;
  }

  @media screen and (max-width: $xs-up) {
    padding: 12px 0px 12px 0px;
    line-height: 28px;
    font-size: 20px;
    writing-mode: horizontal-tb;
    transform: rotate(0deg);
  }
}

.activeDeal {
  background: var(--gradient-3);

  &::after {
    display: none;
  }

  box-shadow:
    0px 15px 125px rgba(201, 180, 250, 0.13),
    0px 4.52206px 37.6838px rgba(201, 180, 250, 0.0847066),
    0px 1.87823px 15.6519px rgba(201, 180, 250, 0.065),
    0px 0.67932px 5.661px rgba(201, 180, 250, 0.0452935);
}

.tag {
  background: var(--accent-light);
  border-radius: 36px;
  padding: 9px 12px 7px 12px;
  width: fit-content;
  color: var(--accent);
  margin-bottom: 16px;
  @include font-12-bold;
}

.hit {
  background-color: $orange;
  color: $white;
  display: flex;
  gap: 4px;
}

.tags {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;

  @media screen and (max-width: $xs-up) {
    margin-bottom: 0px;
  }
}

.favored {
  @media screen and (max-width: $xs-up) {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 1;
  width: 100%;
  padding: 24px 32px;

  @media screen and (max-width: $xs-up) {
    position: relative;
    padding: 20px;
  }
}

.title {
  color: var(--text);
  @include font-30-bold;
  line-height: 130%;
  &_block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &.disabled {
    opacity: 0.6;
  }

  @media screen and (max-width: $xs-up) {
    @include font-20-bold;
  }
}

.price {
  @media screen and (max-width: $xs-up) {
    margin-bottom: 12px;
  }
}

.priceDisabled {
  opacity: 0.5;
}

.priceTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 135%;
}

.priceItem {
  font-weight: 500;
  font-size: 36px;
  line-height: 130%;
  padding-right: 6px;

  @media screen and (max-width: $xs-up) {
    @include font-32-bold;
  }

  &--small {
    @include font-22-bold;

    @media screen and (max-width: 1024px) {
      @include font-20-bold;
    }
  }
}

.current {
  color: #898989;
  opacity: 0.7;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  position: relative;
  display: inline-block;
  max-width: fit-content;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 4px;
    width: 103%;
    height: 50%;
    border-bottom: 2px solid var(--text-light);
    transform-origin: left bottom;
    transform: rotate(-6deg);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.box {
  width: 100%;
  @media screen and (max-width: $xs-up) {
    margin-bottom: 24px;
  }
}

.buttonWrap {
  max-width: 170px;
  padding-bottom: 9px;

  @media screen and (max-width: $xs-up) {
    width: 100%;
    max-width: calc(100% - 47px);
    padding-bottom: 0;

    .favoriteButton {
      width: 40px;
      height: 40px;
    }
  }
}

.favoriteButton {
  svg {
    position: relative;
    left: -1px;
    fill: var(--accent);
  }

  @media screen and (max-width: $xs-up) {
    width: 40px;
    height: 40px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;

  @media screen and (max-width: $sm) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.tooltipWrap {
  position: relative;
  display: flex;
  align-items: center;

  .tooltip {
    min-width: 250px;
    white-space: normal;
    color: var(--text);
    display: block;
    background-color: #fff;
    font-size: 14px;
    line-height: 140%;
    box-shadow:
      0px 0px 8px rgb(0 0 0 / 10%),
      0px 4px 8px rgb(0 0 0 / 10%);
    border-radius: 8px;
    transition: 0.3s;
    width: 100%;
    text-align: center;

    @media (max-width: 1024px) {
      transform: translate(18px, 100%) !important;
    }

    * {
      white-space: normal;
    }

    :global(.common__tooltip-inner) {
      box-shadow: none;
      height: unset;
      background: transparent;
      display: block;
    }

    :global(.common__tooltip-text) {
      width: 100%;
      display: block;
    }

    @media screen and (max-width: $xs-up) {
      min-width: 200px;
      top: unset;
      bottom: -5px;
      z-index: 2;
      transform: translate(-60px, 100%);

      :global(.common__tooltip-inner:after) {
        top: 0;
        transform: translateY(-100%) rotate(180deg);
        right: 17px;
        left: unset;
      }
    }
  }
}

.tooltipContainer {
  position: relative;
}

.closeTimeInner {
  display: flex;
  color: var(--text-light);
  @include font-12;
  padding-right: 5px;
}

.closeTime {
  font-weight: 500;
  padding-left: 3px;
}

.button {
  padding: 9px 32px;
  font-size: 16px;
  height: 40px;
}

.in-cart {
  border: 1px solid var(--accent);
  display: flex;
  align-items: inherit;

  span {
    margin-left: 8px;
    top: 0;
    background: var(--accent);
    border-radius: 50%;
    color: $white;
    width: auto;
    min-width: 16px;
    height: 16px;
    text-align: center;
    padding: 1px 4px 0 4px;
    @include font-12-bold;
    min-width: 16px;
  }
}

.mobile-buttons {
  display: flex;
  gap: 8px;
  position: relative;
  z-index: 1;
  button {
    width: 200px;
    text-align: center;
  }

  .mobile-button {
    flex-grow: 1;
    padding: 0 16px;
    height: 100%;
    text-align: center;

    @media screen and (max-width: 1024px) {
      height: 40px;
    }
  }
}

.modal {
  position: relative;
  display: flex;
  min-height: 356px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding-bottom: 40px;
  }

  .left {
    padding: 40px;
    width: 472px;
    color: var(--text);

    @media screen and (max-width: 1024px) {
      width: 100%;
      padding: 0;
      flex-direction: column;
      padding-bottom: 80px;
    }

    .cart-title {
      margin-bottom: 8px;
      @include font-22-bold;
    }

    .text {
      margin-bottom: 32px;
      @include font-14;
    }

    .label {
      margin-bottom: 8px;
      margin-top: 32px;
      @include font-12-bold;
    }

    .add {
      margin-top: 16px;
      margin-bottom: 40px;
    }

    .sources {
      width: 100%;
      // overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 8px;

      @media screen and (max-width: 1024px) {
        max-height: none;
      }

      .source {
        input {
          height: 40px;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .source-wrapper {
        display: flex;
        width: 100%;
        gap: 8px;

        .source {
          width: 344px;

          @media screen and (max-width: 1024px) {
            width: auto;
          }
        }

        .delete {
          width: 40px;
          height: 40px;
          border: 1px solid #e4e4e5;
          border-radius: 8px;
          padding: 0;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    height: 40px;
    gap: 8px;

    @media screen and (max-width: 1024px) {
      flex-direction: column-reverse;
      height: auto;
      margin-top: 24px;
    }

    .button {
      @media screen and (max-width: 1024px) {
        height: 40px;
      }
    }
  }

  .right {
    position: relative;
    width: 288px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--text);
    border-radius: 0 36px 36px 0;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    .bg-image {
      object-fit: fill;
      /* height: 410px; */
      width: 100%;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    .price-count {
      position: relative;
      box-shadow:
        0px 15px 125px rgba(201, 180, 250, 0.13),
        0px 4.52206px 37.6838px rgba(201, 180, 250, 0.0847066),
        0px 1.87823px 15.6519px rgba(201, 180, 250, 0.065),
        0px 0.67932px 5.661px rgba(201, 180, 250, 0.0452935);
      border-radius: 16px;
      background-color: $white;
      padding: 17px 16px;
      width: 224px;

      @media screen and (max-width: 1024px) {
        position: relative;
        background-color: var(--accent-light);
        box-shadow: none;
        width: 100%;
        left: auto;
      }

      .info {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        align-items: baseline;
        @include font-12;

        span {
          @include font-14-bold;
        }
      }

      .total {
        margin-top: 13px;
        padding-top: 12px;
        border-top: 1px solid rgba(var(--text-light), 0.3);
        @include font-14;

        span {
          @include font-22-bold;
        }
      }
    }

    .hint {
      color: var(--text-light);
      margin-top: 12px;
      @include font-12;
    }
  }
}

.row {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
