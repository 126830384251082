@import 'styles/main.scss';

.dots {
  display: flex;
  justify-content: flex-end;

  svg {
    cursor: pointer;
  }

  .table__search {
    background: none;
    border: none;
  }

  .delete {
    cursor: pointer;
    color: var(--error-main);
  }
}

.inner {
  max-width: 100%;
  padding: 32px 20px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  will-change: transform;

  @media screen and (min-width: $xs-up) {
    width: 300px;
    height: unset;
    flex-direction: column;
    gap: 24px;
    padding: 40px;
  }

  .headerWrapper {
    .title {
      padding-right: 15px;
      @include font-22-bold;
      margin: 0;

      @media screen and (max-width: $xs) {
        @include font-18-bold;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .titleEmail {
      @include font-14;
      color: var(--text-light);
      margin: 0;
      margin-bottom: 24px;
    }

    .titleWithoutId {
      margin-bottom: 24px;
    }
  }

  .buttons {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    .button {
      width: 100%;
    }

    @media screen and (max-width: $xs-up) {
      flex-direction: column;
    }
  }
}

.innerDelete {
  padding: 24px;
  margin: 0;

  h3,
  p {
    margin: 0;
  }

  h3 {
    margin-bottom: 8px;
  }

  .buttonsDelete {
    gap: 12px;
  }
}

.titleRemove {
  @include font-14;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-line-clamp: 100;
  line-clamp: 100;
}

.title-description-remove {
  color: var(--text-light);
  @include font-12;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
