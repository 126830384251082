@import 'styles/main.scss';

.filter {
  padding: 0 0 28px 0;
  border-bottom: 1px solid $grey;
  margin-bottom: 21px;
  position: relative;

  .title {
    height: 24px;
    @include font-14-bold;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: -4px;
    margin-bottom: 24px;
    svg {
      fill: var(--accent);
    }
  }

  .clear,
  .clearPrice {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: baseline;
    color: var(--accent);
    gap: 7px;
    padding: 3px 8px;
    width: fit-content;
    min-width: 45px;
    height: 24px;
    border-radius: 36px;
    @include font-12-bold;

    svg {
      width: 10px;
      height: 9px;
      fill: var(--accent);
    }
  }

  .clearPrice {
    svg {
      height: 17px;
    }
  }

  .search-field {
    position: relative;
    margin-bottom: 12px;

    svg {
      position: absolute;
      top: 8px;
      left: 12px;
      z-index: 9999;
      width: 22px;
      height: 22px;
    }

    .search {
      input {
        padding: 9px 12px 7px 40px;
      }
    }
  }

  .choices {
    max-height: 440px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--accent-light);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--accent);
    }
  }

  .choices-budget {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .check {
    margin: 8px 0;

    span {
      font-size: 14px;
    }
  }

  .button {
    @include font-14;
    margin-left: -3px;
    margin-top: 6px;
  }

  .button-icon {
    margin-right: 8px;
    fill: var(--accent);

    svg {
      width: 24px;
      stroke: var(--accent);
    }
  }

  .rotate {
    transform: rotate(180deg);
  }
}

.filter:last-child {
  border: none;
}

.top {
  span {
    color: var(--text-light) !important;
  }
}

input[type='checkbox']:not(:disabled) ~ span {
  &:hover {
    color: var(--accent);
  }
}
