@import 'styles/helpers/variables.scss';

.wrapper {
  position: relative;
  min-height: calc(100vh - 160px);
  padding-top: 48px;
  padding-bottom: 48px;

  @media screen and (max-width: $xs-up) {
    margin-top: 24px;
  }
}

.inner {
  background: #ffffff;
  box-shadow: var(--cart-shadow);
  border-radius: 36px;
  padding: 40px;
  margin-bottom: 40px;

  @media screen and (max-width: $xs-up) {
    padding: 0px;
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
    // overflow-y: auto;
  }
}
