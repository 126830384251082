@import 'styles/main.scss';

.wrapper {
  padding-bottom: 70px;
}

.filter {
  padding: 0 0 28px 0;
  border-bottom: 1px solid $grey;
  margin-bottom: 21px;
  position: relative;

  .title {
    height: 24px;
    @include font-14-bold;
    display: flex;
    align-items: center;
    margin-left: -4px;
    margin-bottom: 24px;
    justify-content: space-between;

    span {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .rocket {
      path {
        fill: var(--text);
      }
    }
  }

  .choices-budget {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .count {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 21px;

    .text {
      color: var(--text-light);
      @include font-14;
    }

    .clear {
      @include font-14-bold;
    }
  }

  .clear {
    display: flex;
    align-items: baseline;
    color: var(--accent);
    gap: 7px;
    padding: 3px 8px;
    width: fit-content;
    min-width: 45px;
    height: 24px;
    border-radius: 36px;
    margin-left: 4px;
    @include font-12-bold;

    svg {
      width: 10px;
      height: 9px;
      fill: var(--accent);
    }
  }

  .search-field {
    position: relative;
    margin-bottom: 12px;

    svg {
      position: absolute;
      top: 8px;
      left: 12px;
      z-index: 99;
      width: 22px;
      height: 22px;
    }

    .search {
      input {
        padding: 9px 12px 7px 40px;
      }
    }
  }

  .check {
    margin: 8px 0;
  }

  .button {
    @include font-14-bold;
  }

  .button-icon {
    margin-right: 8px;

    svg {
      width: 24px;
    }
  }

  .rotate {
    transform: rotate(180deg);
  }
}

.filter:last-child {
  border: none;
}

.bottom-buttons {
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 100;
  bottom: 92px;
  right: -1000px;
  width: calc(100% - 24px);
  height: 56px;
  background-color: $white;
  box-shadow: var(--top-shadow);
  border-radius: 28px;
  padding: 8px;
  transition: 0.6s;

  .reset {
    width: 128px;
    text-align: center;
  }

  .button {
    height: 40px;
    display: flex;
    gap: 15px;

    svg {
      width: 20px;
      height: 20px;
      path {
        fill: var(--accent);
      }
    }
  }
}

.open {
  right: 12px;
}

.apply {
  width: 100%;
}

.bottom-buttons-apply {
  bottom: 20px;
}

.modalFilter {
  overflow: scroll;
  height: 100%;

  .emptyButton {
    height: 56px;
    width: 100%;
  }
}

.top {
  span {
    color: var(--text-light) !important;
  }
}

.modalMobileFilters {
  overflow: -moz-scrollbars-none;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.modalMobileFilters::-webkit-scrollbar {
  display: none;
}
