@import 'styles/helpers/variables.scss';

.inner {
  min-width: 100%;
  padding: 40px;
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (min-width: $xs-up) {
    min-width: 583px;
    height: unset;
    flex-direction: column;
    gap: 24px;
  }

  .success {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 32px;

    &-title {
      text-align: center;
      @include font-22-bold;
      margin: 0;
      margin-bottom: 8px;
    }

    &-description {
      text-align: center;
      @include font-14;
      margin: 0;
    }
  }

  .header {
    .title {
      @include font-22-bold;
      margin: 0;
    }

    .title-description {
      @include font-14;
      color: var(--text-light);
      margin: 0;
    }
  }

  .content {
    background-color: var(--accent);
    padding: 24px;
    border-radius: 24px;

    .balance-title {
      @include font-11-bold;
      color: $white;
      margin: 0;
    }

    .isTopUpBalance {
      @include font-11-bold;
      color: $white;
    }

    .balance-value {
      @include font-20-bold;
      margin: 0;
      color: $white;
      margin-bottom: 16px;
    }
  }

  .list {
    padding-top: 16px;
    border-top: 1px solid #ffeaea;

    .item {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      &-content {
        width: 70%;
      }

      &-name {
        @include font-12-bold;
        color: var(--accent);
        padding-bottom: 4px;
      }

      &-id {
        @include font-12-bold;
        color: var(--text);
      }

      &-amount {
        margin: 0;
        text-align: right;
        width: 30%;
      }
    }
  }
}

.btn {
  width: 100%;
}
