.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 15px 125px rgba(201, 180, 250, 0.13), 0px 4.52206px 37.6838px rgba(201, 180, 250, 0.0847066), 0px 1.87823px 15.6519px rgba(201, 180, 250, 0.065), 0px 0.67932px 5.661px rgba(201, 180, 250, 0.0452935);
    border-radius: 16px;
    padding: 12px 24px;

    &__section {
        display: flex;
        align-items: center;

        &-item {
            position: relative;

            &--deal {
                padding-right: 16px;
                border-right: 1px solid var(--accent-light);
            }

            &--search {
                padding-left: 16px;
                border-left: 1px solid var(--accent-light);
                padding-right: 22px;
            }

            &--fav {
                padding-right: 22px;
            }

            &--basket {
                padding-right: 32px;
            }
        }
    }

    &__favorite, &__basket {
        display: flex;
        align-items: center;
    }
}