@import 'styles/main.scss';

.container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100dvh;

  overflow-y: hidden;

  .logo {
    position: fixed;
    width: 60px;
    height: 40px;
    top: 48px;
    left: 48px;
    z-index: 10;

    &_ru {
      height: 24px;
      width: auto;
    }

    @media screen and (max-width: 1024px) {
      width: auto;
      height: 26px;
      top: 16px;
      left: 16px;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: 100%;
  }
}

.left {
  position: relative;
  width: 43%;
  background: var(--gradient-2);
  padding: 141px 48px;

  .image {
    object-fit: cover;
    width: calc(100% - 96px);
    position: absolute;
    right: 48px;
    left: 48px;

    @media screen and (max-width: 1024px) {
      width: 306px;
      left: 15%;
      top: 0;
      height: 266px;
    }
  }

  .image-two {
    position: absolute;
    z-index: -1;
    right: 0;
    top: 0;
  }

  .left-title {
    color: $white;
    margin-bottom: 16px;
    @include font-20;

    @media screen and (max-width: 1024px) {
      @include font-12;
      max-width: 211px;
    }
  }

  .left-text {
    color: $white;
    @include font-32-bold;

    @media screen and (max-width: 1024px) {
      @include font-14-bold;
      text-align: start;
      max-width: 211px;
    }
  }

  .success {
    object-fit: contain;
    width: 441px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 100%;

    @media screen and (max-width: 1024px) {
      width: 246px;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 288px;
    position: fixed;
    padding: 92px 16px;
  }
}

.right {
  position: relative;
  width: 57%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 156px;

  .lang {
    position: absolute;
    top: 48px;
    right: 48px;
    height: 30px;
  }

  @media screen and (max-width: 1270px) {
    padding: 0 80px;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 24px 16px;
    border-radius: 28px 28px 0px 0px;
    overflow: hidden;
    margin-top: 260px;
    background-color: white;
    z-index: 11;

    position: unset;

    .lang {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }

  .title {
    color: var(--text);
    margin-bottom: 4px;
    @include font-32-bold;

    @media screen and (max-width: 1024px) {
      @include font-22-bold;
    }
  }

  .text {
    @include font-14;
    margin-bottom: 32px;

    span {
      display: flex;
    }

    .link {
      margin-left: 8px;
      @include font-14-bold;

      a {
        color: #8362f3;
      }
    }
  }
  .multiselect-lang {
    width: 55px;
  }
}

.word-logo {
  width: 152px;
  margin-left: 4px;

  @media screen and (max-width: 1024px) {
    width: 114px;
  }
}

.form {
  .fields {
    display: grid;
    row-gap: 32px;
    margin-bottom: 40px;
  }

  .input-field {
    position: relative;

    .password {
      position: absolute;
      right: 0;
      top: 0;
      @include font-14-bold;

      a {
        color: #8362f3;
      }
    }

    .svg {
      fill: var(--text-light);
    }
  }

  .check {
    margin-top: 20px;
    width: fit-content;
  }

  .button {
    height: 40px;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .buttons {
    display: flex;
    gap: 8px;
  }

  .return-button {
    width: 40px;
    height: 40px;
    display: flex;
    background-color: $grey;
    border-radius: 80px;
    align-items: center;
    justify-content: center;
    padding-top: 6px;
    svg {
      fill: var(--accent);
    }
  }
}

.error {
  position: absolute;
  color: $red;
  width: fit-content;
  right: 12px;
  bottom: 4px;
  z-index: 10;
}

.captcha {
  position: relative;
  margin-top: 36px;
  margin-bottom: 30px;

  @media screen and (max-width: 1024px) {
    margin-top: 28px;
    margin-bottom: 22px;
  }
}

.mobile-error {
  background: linear-gradient(
      0deg,
      rgba(245, 34, 45, 0.04),
      rgba(245, 34, 45, 0.04)
    ),
    #ffffff;
  border: 1px solid $red;
  border-radius: 8px;
  margin-top: 28px;
  padding: 8px 16px;
}

.body {
  .title {
    @include font-22-bold;
    margin-bottom: 24px;

    @media screen and (max-width: 1024px) {
      @include font-20-bold;
    }
  }

  .email {
    display: flex;
  }

  .text {
    @include font-20;
    margin-bottom: 12px;

    span {
      margin-left: 4px;
      @include font-20-bold;
    }

    @media screen and (max-width: 1024px) {
      margin-bottom: 106px;
    }
  }

  .help {
    @include font-14;

    .link {
      margin-left: 3px;
      color: var(--accent);
      cursor: pointer;
      @include font-14-bold;

      a {
        color: #8362f3;
      }
    }
  }
}

.heading {
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-bottom: 21px;
  @include font-32-bold;

  @media screen and (max-width: 1024px) {
    @include font-22-bold;
  }

  svg {
    width: 64px;
    height: 53px;

    @media screen and (max-width: 1024px) {
      width: 64px;
      height: 53px;
      margin-bottom: 18px;
    }
  }
}

.back {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 4px;

  .icon {
    margin-top: 3px;
  }

  .backText {
    color: var(--text-light);
    text-transform: uppercase;
    @include font-12-bold;
  }
}
