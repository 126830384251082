@import "../../../styles/main.scss";

.swiper-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 66px;

  @media screen and (max-width: 1024px) {
    padding: 0 16px;
  }
}

.swiper {
  position: relative;
  height: 100%;
  min-height: 580px;
  width: 100%;
  max-width: 490px;

  @media screen and (max-width: 1024px) {
    height: 288px;
    min-height: auto;
  }
}

.swiper-slider {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 100%;
  transition: 0.7s;
  opacity: 0;

  @media screen and (max-width: 1024px) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.active {
  opacity: 1;
}

.image {
  width: 100%;
  object-fit: contain;
  margin-bottom: 32px;
  object-position: center;
  height: 280px;
  object-position: bottom;

  @media screen and (max-width: 1024px) {
    margin-bottom: -4px;
    width: unset;
    height: unset;
    max-width: 132px;
  }
}

.title {
  text-align: center;
  max-width: 340px;
  margin-bottom: 8px;
  @include font-30-bold;
  color: $white;

  @media screen and (max-width: 1024px) {
    @include font-14-bold;
    text-align: start;
    max-width: 171px;
  }
}

.text {
  text-align: center;
  max-width: 340px;
  @include font-14;
  color: $white;

  @media screen and (max-width: 1024px) {
    @include font-12;
    text-align: start;
    max-width: 171px;
  }
}
