@import 'styles/helpers/variables.scss';

.card {
  padding: 24px;
  border-radius: 24px;
  width: 280px;
  background: var(--white);
  box-shadow: var(--fire-shadow);

  @media (max-width: 1024px) {
    width: 100%;
  }

  &__title {
    display: inline-block;
    @include font-16-bold;
    line-height: 140%;
    margin-bottom: 12px;
  }

  &__marks {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .card__mark {
      display: flex;
      align-items: flex-start;
      gap: 4px;
      color: var(--text-light);
      @include font-12;

      svg {
        width: 16px;
        height: 16px;

        fill: var(--accent);
      }

      &--completed{
        svg {
          fill: var(--text-light);
        }
      }
    }
  }

  &__downloadFile {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 8px;
    min-height: 20px;
    color: var(--accent);
    @include font-12;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--text-light);
    @include font-12;
    line-height: 140%;

    svg{
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }

    &--active{
      color: var(--accent);
      font-weight: 700;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    margin-top: 24px;
    padding: 12px 24px;
    text-wrap: nowrap;

    &--next {
      padding: 12px 6px;
    }

    svg {
      transform: rotate(180deg) scale(1.2);
      fill: var(--accent);
    }

    &.disabled {
      gap: 8px;
      cursor: default;
      &:hover {
        opacity: 1;
      }

      svg {
        transform: scale(1.1);
        fill: var(--accent);
      }
    }
  }
}
