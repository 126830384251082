@import 'styles/helpers/variables.scss';

.empty {
  padding: 56px 32px;
  border-radius: 24px;
  background: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: var(--fire-shadow);
  flex-grow: 1;

  &__header {
    margin-bottom: 12px;
    @include font-22-bold;
  }

  &__text {
    margin-bottom: 24px;
  }

  &__btn {
    padding: 12px 24px;
    min-height: none;
  }
}
