@import 'styles/helpers/variables.scss';

.container {
  position: relative;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 768px) {
    padding: 23px 0 0;
  }
}

.title {
  margin: 32px 0 48px;
  text-align: center;
  @include font-32-bold;

  @media screen and (max-width: 768px) {
    margin: 24px 0;
    @include font-32-bold;
  }

  @media screen and (max-width: 576px) {
    span {
      display: block;
    }
  }
}

.preference {
  width: 490px;
  margin: 0 auto 32px;
  border-radius: 36px;
  background: $white;
  box-shadow:
    0px 2.19453px 6.6501px 0px rgba(28, 20, 37, 0.05),
    0px 7.37098px 22.33631px 0px rgba(28, 20, 37, 0.03),
    0px 33px 100px 0px rgba(28, 20, 37, 0.02);

  @media screen and (max-width: 768px) {
    width: 100%;
    box-shadow: none;
  }
}
