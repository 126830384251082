@import 'styles/helpers/variables.scss';

.card {
  display: flex;
  flex-direction: column;
  height: 502px;
  padding: 20px;
  border-radius: 24px;
  background: var(--white);
  box-shadow: var(--fire-shadow);
  position: relative;
  overflow: hidden;

  min-width: 328px;

  @media screen and (max-width: 1045px) {
    &:first-child {
      margin-left: 20px;
    }

    &:last-child {
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 480px) {
    margin: 0 !important;
  }

  &__tags {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 16px;
  }

  .imgBox {
    height: 193px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 12px;
    }
  }

  &__title {
    display: inline-block;
    @include font-20-bold;
    line-height: 140%;
    margin-top: 12px;
    margin-bottom: 4px;
  }

  &__description {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    @include font-12;
    line-height: 140%;
    max-height: 34px;
    margin: 0 0 auto 0;
    padding: 0;
  }

  &__marks {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .card__mark {
      display: flex;
      align-items: center;
      gap: 4px;
      color: var(--text-light);
      @include font-12;

      svg {
        width: 16px;
        height: 16px;

        fill: var(--accent);
      }

      &--completed{
        svg {
          fill: var(--text-light);
        }
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--accent);
    @include font-12;
    line-height: 140%;
  }

  &__downloadFile {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 8px;
    min-height: 20px;
    color: var(--accent);
    @include font-12;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__downloadPlaceholder {
    width: 100%;
    margin-top: 8px;
    height: 20px; 
    visibility: hidden; 
  }

  &__button {
    margin-top: 24px;
    padding: 12px 24px;
  }
}