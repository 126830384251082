@import 'styles/helpers/variables.scss';

@keyframes skeleton-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.skeleton {
  animation: skeleton-animation 2s infinite;
}

.filters {
  display: flex;
  flex-direction: column;

  .wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--skeleton-gray-light);

    &:first-of-type {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    .title {
      width: 100%;
      height: 20px;
      border-radius: 24px;
      background-color: var(--skeleton-gray);
      margin-bottom: 20px;
      @extend .skeleton;
    }

    .items {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .item {
        height: 16px;
        border-radius: 24px;
        background-color: var(--skeleton-gray-light);
        @extend .skeleton;

        &:nth-child(1),
        &:nth-child(5) {
          width: 110px;
        }

        &:nth-child(2),
        &:nth-child(4) {
          width: 80px;
        }

        &:nth-child(3) {
          width: 50px;
        }
      }
    }
  }
}
