@import 'styles/helpers/variables.scss';

.breadcrumbs {
  display: flex;
  @include font-14;

  &__item {
    display: flex;
    align-items: center;
    margin-right: 6px;
    text-wrap: nowrap;

    &::after {
      content: '';
      width: 3px;
      height: 3px;
      margin-left: 6px;
      background: var(--text);
      border-radius: 50%;
    }

    &:last-child {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .breadcrumbs__link {
        color: var(--text-light);
      }

      &::after {
        display: none;
      }
    }
  }

  &__link {
    color: inherit;
    transition: color 0.3s;

    &:hover {
      color: var(--accent);
    }
  }
}
