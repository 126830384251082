@import '~styles/main.scss';

.empty {
  padding: 40px 0 60px 0;
  border-radius: 36px;
  background: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__header {
    margin-bottom: 16px;
    @include font-26-bold;
  }

  &__text {
    margin-bottom: 40px;
  }

  &__back {
    border-radius: 80px;
    background: var(--accent);
    padding: 9px 32px;
    @include font-14;
    font-weight: 500;
    color: $white;
  }

  @media (max-width: 960px) {
    margin: 0 -20px;
    padding: 66px 20px;

    &__header {
      margin-bottom: 8px;
      @include font-22-bold;
    }

    &__text {
      margin-bottom: 32px;
      @include font-12;
    }
  }
}

.table {
  overflow-x: auto;

  &__header {
    @include font-12;
    color: var(--text);
    line-height: 120%;
  }

  &__firstLink {
    color: var(--accent);
  }

  &__text {
    @include font-11;
    color: var(--text-light);
  }

  &__status {
    width: fit-content;
    padding: 4px 12px;
    border-radius: 36px;
    @include font-11-bold;
    font-size: 11px !important;

    &__in_progress {
      color: var(--info-main) !important;
      background: rgba(var(--info-main-rgb), 0.08);
    }

    &__sent {
      color: var(--ui-04-main) !important;
      background: rgba(var(--ui-04-main-rgb), 0.08);
    }

    &__received {
      color: var(--ui-04-main) !important;
      background: rgba(var(--ui-04-main-rgb), 0.08);
    }

    &__rejected {
      color: var(--error-main) !important;
      background: rgba(var(--error-main-rgb), 0.08);
    }

    &__signed {
      color: var(--success-16) !important;
      background: rgba(var(--success-16-rgb), 0.08);
    }

    &__cancelled {
      color: var(--error-main) !important;
      background: rgba(var(--error-main-rgb), 0.08);
    }
  }

  @media (max-width: 1024px) {
    padding: 0;

    &__cell {
      &__act {
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
      }

      &__project {
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
      }

      // &__period {
      //   font-weight: 500;
      //   font-size: 12px;
      //   color: var(--text);
      //   line-height: 120%;
      // }

      &__amount {
        font-weight: 500;
        font-size: 22px;
        color: var(--text);
        line-height: 120%;
    }
  }
}

  tr {
    grid-template-columns: 30px 224px 156px 175px 156px 115px;
  }
}