@import 'styles/helpers/variables.scss';

.wrapper {
  display: flex;
  padding: 24px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 36px;
  background: var(--white);
  box-shadow: var(--fire-shadow);
}

.button {
  @include font-14;
  color: var(--text);
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: 8px;

  svg {
    width: 16px;
    height: 16px;
    transform: translateY(-2px);

    path {
      fill: var(--text);
    }
  }
}

.bonusList {
  width: 100%;
  padding: 16px 0px;
}

.pagination {
  align-self: center;
}
