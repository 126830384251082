@import "/src/styles/helpers/variables.scss";

.lg {
    @include font-32-bold
}

.md {
    @include font-32-bold;
    
    @media screen and (max-width: 1024px) {
        @include font-22-bold;
    }
}

.sm {
    @include font-30-bold
}

.subtitle {
    @include font-22
}

.xs {
    @include font-20
}

.xs-bold {
    @include font-20-bold
}

.simple {
    @include font-14
}

.over {
    @include font-12-bold-upper;
    color: var(--text-light);
}