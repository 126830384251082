@import '~styles/main.scss';

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;

  .item {
    display: flex;
    align-items: center;
    gap: 8px;
    text-align: left;
    @include font-12;
    cursor: pointer;

    .down {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
