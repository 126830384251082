@import 'styles/helpers/variables.scss';

.card {
  display: flex;
  flex-direction: column;
  height: 291px;
  padding: 20px;
  border-radius: 24px;
  background: var(--white);
  box-shadow: var(--fire-shadow);
  position: relative;
  z-index: 0;
  overflow: hidden;
  min-width: 328px;

  @media screen and (max-width: 1045px) {
    &:first-child {
      margin-left: 20px;
    }

    &:last-child {
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 480px) {
    margin: 0 !important;
  }

  .background {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    width: 200px;

    &.left {
      width: 344px;
      left: -3%;
      top: 7%;
    }

    &.bottom {
      left: 11%;
      top: 32%;
      width: 263px;

      @media screen and (max-width: 1024px) {
        left: 0;
      }
    }
  }

  &__sticker {
    span {
      font-size: 10px;
    }
    margin-bottom: 16px;
  }

  &__title {
    display: inline-block;
    @include font-20-bold;
    line-height: 140%;
    margin-bottom: 4px;
  }

  &__description {
    overflow: hidden;
    text-overflow: ellipsis;
    @include font-12;
    line-height: 140%;
    max-height: 102px;
    margin: 0 0 auto 0;
    padding: 0;
  }

  &__link {
    a {
      color: var(--accent);
    }
  }
}
