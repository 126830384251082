@import 'styles/helpers/variables.scss';

.wrapper {
  display: flex;
  gap: 8px;
  margin-top: 24px;

  @media screen and (max-width: 1024px) {
    padding-bottom: 10px;
    overflow-x: auto;
  }
}

.filter {
  padding: 9px 16px;
  border: 1px solid #f2ecff;
  border-radius: 20px;
  display: flex;
  gap: 8px;
  cursor: pointer;
  white-space: nowrap;
}

.disabled:not(.active) {
  opacity: 0.4;
  pointer-events: none;
}

.disabled:not(.active) {
  opacity: 0.4;
  pointer-events: none;
}

.count {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: var(--accent);
  background-color: var(--accent-light);
  @include font-11-bold;
}

.active {
  background-color: var(--accent);
  color: $white;
  opacity: 1;

  .count {
    color: var(--accent);
  }
}
