@import 'styles/main.scss';

.wrapper {
  display: flex;
  gap: 8px;
  margin-top: 24px;
  overflow-x: auto;
  @media (max-width: 1024px) {
    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
      color: transparent;
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
}

.filter {
  padding: 9px 16px;
  border: 1px solid var(--accent-second);
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  white-space: nowrap;
  @media (max-width: 680px) {
    padding: 6px 8px 6px 12px;
    gap: 4px;
  }
}

.count {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding: 2px 4px;
  border-radius: 50%;
  color: var(--accent);
  background-color: var(--accent-light);
  @include font-11-bold;
  @media (max-width: 680px) {
    font-size: 11px;
  }
}

.countMedium {
  width: 20px;
  height: 20px;
}

.active {
  background-color: var(--accent);
  color: $white;

  .count {
    color: var(--accent);
  }
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed;
}

@media (max-width: 680px) {
  .wrapper {
    margin-top: 12px;
  }
}
