@import 'styles/helpers/variables.scss';

.modal {
  position: fixed;
  top: 0;
  right: -1000px;
  z-index: 100;
  display: none;
  width: 100vw;
  height: 100dvh;
  padding: 12px;
  background-color: $white;
  overflow-y: auto;
  transition: 0.6s;

  @media screen and (min-width: 1025px) {
    left: 0;
    right: unset;
    display: none;
    align-items: center;
    backdrop-filter: blur(1.5px);
    background: rgba(13, 9, 24, 0.56);
    border-radius: 0;
  }

  &.mediaTablet {
    left: 0;
    right: unset;
    display: none;
    align-items: center;
    backdrop-filter: blur(1.5px);
    background: rgba(13, 9, 24, 0.56);
    border-radius: 0;
  }

  &.price-modal {
    left: 0;
    width: 101vw;
    right: unset;
    align-items: center;
    backdrop-filter: blur(1.5px);
    background: rgba(13, 9, 24, 0.56);
  }

  &.mediaMobile {
    @media screen and (max-width: 1024px) {
      scrollbar-width: none;
    }
  }
}

.modal::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.bottom-modal {
  position: fixed;
  left: 0;
  bottom: -1000px;
  width: 100vw;
  height: 103vh;
  padding: 12px;
  background-color: $white;
  overflow-y: auto;
  z-index: 100;
  transition: 0.6s;
  visibility: hidden;
  &.mediaMobile {
    @media screen and (max-width: 1024px) {
      scrollbar-width: none;
    }
  }
}

.bottom-modal::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.bottom-open {
  visibility: visible;
  bottom: 0px;

  .header {
    bottom: 12px;
    transition: 0.6s;
  }
}

.open {
  right: 0px;
  display: block;

  .header {
    right: 12px;
    transition: 0.6s;
  }

  @media screen and (min-width: 1025px) {
    right: unset;
    display: flex;
  }

  &.mediaTablet {
    right: unset;
    display: flex;
  }

  &.price-modal {
    right: unset;
    display: flex;

    .header {
      display: none;
    }

    .content {
      background-color: $white;
      border-radius: 20px;
      max-width: max-content;
      margin: auto;
      width: 100%;
      position: relative;
      padding: 0;
      height: unset;
    }

    .desktop {
      display: flex;
    }
  }
}

.header {
  position: fixed;
  z-index: 100;
  top: 12px;
  right: -1000px;
  width: calc(100% - 24px);
  height: 56px;
  background-color: $white;
  box-shadow: var(--top-shadow);
  padding: 16px;
  border-radius: 28px;

  @media screen and (min-width: 1025px) {
    display: none;
  }

  &.mediaTablet {
    display: none;
  }

  .wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      display: flex;
      gap: 8px;
      align-items: center;
      @include font-14-bold;

      svg {
        width: 24px;
        height: 24px;
        display: block;
      }
    }

    .close {
      width: 21px;
      height: 21px;

      svg {
        fill: var(--text);
      }
    }
  }
}

.basic {
  box-shadow: none;
  border-radius: 0;
}

.content {
  padding-top: 81px;
  padding-bottom: 56px;
  z-index: 99;
  height: 100%;

  @media screen and (min-width: 1025px) {
    background-color: $white;
    border-radius: 36px;
    max-width: max-content;
    margin: auto;
    width: 100%;
    position: relative;
    padding: 0;
    height: unset;
  }

  &.mediaTablet {
    background-color: $white;
    border-radius: 36px;
    max-width: max-content;
    margin: auto;
    width: 100%;
    position: relative;
    padding: 0;
    height: unset;
  }

  &.mediaMobile {
    @media screen and (max-width: 1024px) {
      scrollbar-width: none;
    }
  }
}

.content::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.sub-modal {
  z-index: 110;
  height: 100vh;
  padding-bottom: 60px;

  overflow: -moz-scrollbars-none;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;

  .header {
    right: 12px;
    transition: 0.6s;
  }
}

.sub-modal::-webkit-scrollbar {
  display: none;
}

.desktop {
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: $white;
  position: absolute;
  right: -65px;
  top: 0px;
  cursor: pointer;

  @media screen and (min-width: 1025px) {
    display: flex;
  }

  &.mediaTablet {
    display: flex;
  }
}

.mobile-filter {
  height: 100%;
  overflow: hidden;

  .content {
    overflow: auto;

    &.mediaMobile {
      @media screen and (max-width: 1024px) {
        scrollbar-width: none;
      }
    }
  }
}

.mobile-filter .content::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
