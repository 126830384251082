@import 'styles/helpers/variables.scss';

@keyframes skeleton-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.skeleton {
  animation: skeleton-animation 2s infinite;
}

.wrapper {
  padding: 40px 0 0;

  @media screen and (max-width: 960px) {
    padding: 54px 0;
  }

  .title {
    position: relative;
    margin-bottom: 24px;
    @include font-32-bold;

    @media screen and (max-width: 1024px) {
      margin-bottom: 16px;
      @include font-32-bold;
    }
  }

  .left {
    padding-right: 12px;

    @media (max-width: 960px) {
      padding-right: 0px;
    }

    .left-title {
      width: 100%;
      height: 59.58px;
      border-radius: 48px;
      background-color: var(--skeleton-gray-light);
      margin-bottom: 16px;
      @extend .skeleton;
    }

    .list {
      width: 100%;
      border-radius: 24px;
      padding: 24px;
      background-color: var(--skeleton-gray-light);
      @extend .skeleton;

      .item {
        padding-top: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid var(--skeleton-gray);
        border-top: 1px solid var(--skeleton-gray);
        display: flex;
        flex-direction: column;
        gap: 12px;
        @extend .skeleton;

        &:last-child {
          padding-bottom: 0;
          border-bottom: none;
        }

        &:first-child {
          padding-top: 0;
          border-top: none;
        }

        &-content {
          background-color: var(--skeleton-gray);
          width: 30%;
          height: 18px;
          border-radius: 24px;
          @extend .skeleton;

          &:last-child {
            width: 15%;
          }
        }
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 491px;
    border-radius: 24px;
    padding: 24px;
    background-color: var(--skeleton-gray-light);
    @extend .skeleton;
    margin-top: 0;

    @media (max-width: 960px) {
      margin-top: 12px;
    }

    &-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;

      .title-item {
        width: 80%;
        height: 24px;
        border-radius: 24px;
        background-color: var(--skeleton-gray);
        @extend .skeleton;
      }

      .title-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        .content-item {
          width: 70%;
          height: 18px;
          border-radius: 24px;
          background-color: var(--skeleton-gray);
          @extend .skeleton;

          &:first-child {
            width: 60%;
          }

          &:last-child {
            width: 50%;
          }
        }
      }
    }

    .button {
      width: 100%;
      height: 60px;
      border-radius: 56px;
      background-color: var(--skeleton-gray);
      @extend .skeleton;
    }
  }
}

.wrapper-other {
  min-height: 375px;
  margin-top: 96px;
  margin-bottom: 30px;

  @media screen and (max-width: $xs-up) {
    margin-top: 64px;
  }

  .title-other {
    position: relative;
    margin: 0 0 15px;
    @include font-30-bold;

    @media screen and (max-width: 1024px) {
      @include font-22-bold;
    }
  }
}
