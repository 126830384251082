@import '/src/styles/helpers/variables.scss';

.documents-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.documents-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  list-style: none;

  &__item {
    display: flex;
    gap: 6px;
    align-items: flex-start;

    &_info {
      display: flex;
      flex-direction: column;
      position: relative;
    }

    &_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: var(--accent-light);
      flex-shrink: 0;
    }

    &_header {
      display: flex;
      align-items: flex-end;
      gap: 4px;
    }

    &_label {
      display: inline-block;
      max-width: 640px;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      &:hover {
        color: var(--accent);
      }
      color: var(--text-color);
      @include font-14-bold;

      @media screen and (max-width: 1024px) {
        max-width: 600px;
      }
    }

    &_size {
      margin-left: 4px;
      white-space: nowrap;
      color: var(--grey);
      @include font-10;
    }

    &_desc {
      max-width: 640px;
      word-break: break-word;
      @include font-11;
      color: var(--holder-color);
    }
  }
}

.documents-list__item_center {
  align-items: center;

  .documents-list__item_header {
    align-items: center;
  }  
}
