@import 'styles/main.scss';

.table__wrapper {
  width: 100%;
}

.table {
  width: 100%;
  min-height: 200px;
  border-radius: 24px;
  padding-bottom: 50px;
  padding: 30px 32px;
  box-shadow: var(--shadow-default);

  @media (max-width: $xs-up) {
    overflow: visible;
    width: calc(100% + 40px);
    padding: 24px 20px 4px 20px !important;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 20px;
  }

  &__row {
    display: grid;
    grid-template-columns: 35px 250px 250px 180px 1fr;
    border-bottom: 1px solid var(--accent-light);

    @media screen and (max-width: $md) {
      grid-template-columns: 35px 190px 190px 175px 1fr;
    }

    &_mob {
      display: none;
    }

    &:first-child {
      @include font-12-bold;

      .table__cell {
        display: flex;
        align-items: center;
        gap: 6px;
        font-weight: 500;
        @include font-14-bold;

        .container {
          flex-direction: column;
        }

        svg {
          width: 16px;
          height: 16px;
        }

        &:first-child {
          padding: 0;
        }
      }
    }
  }

  &__sort {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    outline: none;
    color: var(--text);

    @media (max-width: $sm) {
      @include font-12;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 35px 250px 250px 180px 1fr;

    @media screen and (max-width: $md) {
      grid-template-columns: 35px 190px 190px 175px 1fr;
    }
  }

  &__cell {
    padding: 12px 16px;

    &:first-child {
      padding: 12px 8px;
      color: var(--text-light);
    }
  }

  &__search {
    padding: 0;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    svg {
      width: 20px;
      height: 20px;
      path {
        stroke: var(--text-light);
      }
    }
  }

  &__link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    color: var(--text);
    cursor: pointer;
    @include font-12;
    gap: 4px;

    svg {
      width: 16px;
      height: 16px;
      stroke: var(--accent);
    }
  }

  &__firstlink {
    color: var(--accent);
  }

  &__header {
    @include font-14;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__text {
    @include font-12;
    color: #9c93aa;
  }

  @media (max-width: 1024px) {
    padding: 0;

    &__row {
      padding: 0 8px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      border-bottom: 1px solid #f7f5ff;

      &_mob {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        padding: 0 8px;
      }

      &:first-child {
        display: none;
      }

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    &__content {
      padding: 0 8px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      border-bottom: 1px solid #f7f5ff;
      position: relative;

      @media screen and (max-width: $xs-up) {
        border-bottom: none;
      }

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }
    }

    &__cell {
      padding: 0;
      @include font-12;

      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        order: 0;

        .table__header {
          @include font-12-bold;
          color: var(--text);

          @media (max-width: $sm) {
            @include font-16-bold;
          }
        }
      }

      &:nth-child(3) {
        order: 1;
        // margin-bottom: 12px;
      }

      &:nth-child(4) {
        order: 1;
        // margin-bottom: 12px;
      }

      &:nth-child(5) {
        order: 2;
        position: absolute;
        top: 3px;
        right: 0;

        div {
          display: flex;
          align-items: center;
        }
      }

      &:nth-child(6) {
        order: 6;
        margin-top: 24px;

        > div {
          > div {
            @include font-22-bold;
            color: var(--text);
          }
        }
      }
    }

    &__header {
      @include font-12;
      color: #9c93aa;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: $sm) {
        @include font-14-bold;
        color: var(--text-light);
      }
    }

    &__sort {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      outline: none;
      color: var(--text);
    }

    &__search_mob {
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      outline: none;

      margin-left: auto;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__list_mob {
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      outline: none;

      margin-left: auto;
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__firstlink {
      color: var(--accent);
      @include font-16-bold;
    }

    &__link {
      font-weight: 500;
      margin-bottom: 2px;
    }

    &__text {
      line-height: 1.4;
    }
  }
}

.currency {
  font-family: $font-currency;
}

.pagination {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: $xs-up) {
    margin-top: 4px;
    margin-bottom: 40px;
  }
}

.search__input {
  input {
    padding: 8px 12px;
  }
}

.search__description {
  text-align: left;
  @include font-11-bold;
}

.description_date {
  @include font-12;
  color: var(--text-light);
}

.selectRoleInput {
  padding: 0;
  height: 0;
}

.nameRole {
  margin-top: 4px;
  @include font-14;
  color: var(--text-light);
}

.opacity {
  opacity: 0.5;
}

.you {
  color: var(--text-light);

  @media screen and (max-width: $sm) {
    @include font-16-bold;
  }
}

.wrapperHeader {
  display: flex;
  flex-wrap: nowrap;
  gap: 2px;

  @media screen and (max-width: $sm) {
    gap: 4px;
  }
}
