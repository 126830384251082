@import 'styles/helpers/variables.scss';

.item {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 24px;

  &::after {
    content: '';
    width: calc(100% - 40px);
    height: 1px;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: #f7f5ff;
  }

  @media screen and (max-width: $sm) {
    // padding: 20px 20px 60px;
    // border-radius: 24px;
    flex-wrap: wrap;
    padding: 20px;
    margin: 0;
    position: relative;

    &::after {
      content: '';
      width: calc(100% - 40px);
      height: 1px;
      bottom: 0;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: #f7f5ff;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}

.tags {
  display: flex;
  gap: 6px;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    white-space: nowrap;
  }

  .tagged {
    color: #8362f3;
    padding: 8px 12px 8px;
    background-color: #f7f5ff;
    border-radius: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .free {
    background-color: var(--ui-02-main);
    color: $white;
  }

  .new {
    background-color: var(--ui-03-main);
    color: $white;
  }

  .hit-tag {
    background-color: $orange;
    color: $white;
    display: flex;
    gap: 2px;
    padding-left: 9px;
  }
}

.tag {
  background: var(--accent-light);
  border-radius: 36px;
  padding: 3px 12px 0px 12px;
  width: fit-content;
  color: var(--accent);
  // margin-bottom: 16px;
  height: 24px;
  display: flex;
  align-items: center;
}

.link {
  @include font-14-bold;
  line-height: 140%;
  color: var(--text);
  display: block;
  max-width: 320px;
  // @media screen and (max-width: $sm) {
  //   margin-bottom: 13px;
  //   display: block;
  // }
}

.favButton {
  display: flex;
  align-items: center;
  max-width: max-content;
  @include font-12-bold;
  position: relative;
}

.favButton,
.delete {
  position: relative;
  opacity: 1 !important;

  svg {
    fill: var(--accent);
  }

  &::after {
    color: var(--text);
    transition: opacity 0.5s;
    opacity: 0;
    content: attr(aria-label);
    white-space: nowrap;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    position: absolute;
    width: min-content;
    border-radius: 8px;
    border: 1px solid #f2ecff;
    box-shadow:
      0px 4.522px 37.684px 0px rgba(201, 180, 250, 0.12),
      0px 15px 125px 0px rgba(201, 180, 250, 0.13);
    background: $white;
    z-index: 1;
    left: 0;
    bottom: -22px;

    @media (max-width: $sm) {
      display: none;
    }
  }
}

.favIcon {
  display: flex;
  align-items: center;
  position: relative;

  svg {
    width: 22px;
    height: 22px;
  }
}

.favButtonInner {
  display: flex;
  align-items: center;
}

.delete {
  color: var(--text-light);
  @include font-12-bold;
  display: flex;
  align-items: center;
}

.deleteIcon {
  display: flex;
  align-items: center;
  svg {
    width: 22px;
    height: 22px;
  }
}

.deleteText {
  position: relative;
  top: 1px;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.left {
  display: flex;
  gap: 23px;
  width: 100%;

  @media screen and (max-width: $sm) {
    gap: 8px;
    // flex-direction: column;
  }
}

.leftBlock {
  display: flex;
  // flex-direction: column;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: $sm) {
    align-items: flex-start;
    margin-bottom: 16px;
  }
}

.inFav {
  color: var(--text-light);
}

.right {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 24px;

  @media (max-width: 1024px) {
    margin-right: 12px;
  }

  @media screen and (max-width: $sm) {
    margin: 0;
    width: 100%;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;

    .counter {
      margin: 20px 0 0 24px;
    }
  }
}

.counter {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 4px 8px;
  background: var(--accent-light);
  border-radius: 80px;
  &__btn {
    width: 16px;
    height: 16px;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 16px;
      height: 16px;
      stroke: var(--accent);
    }

    .counter-icon {
      display: flex;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  &__input {
    width: 20px;
    height: 20px;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    color: var(--accent);
    text-align: center;
    @include font-14-bold;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      display: none;
      appearance: none;
      margin: 0;
      -webkit-appearance: none;
      -moz-appearance: textfield;
    }
  }
}

.price {
  width: 100%;
  max-width: 230px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 2px;
  margin-left: 16px;

  &__discount {
    max-width: 209px;
    position: relative;
    padding-left: 2px;
    color: var(--text-light);
    width: fit-content;
    @include font-11-bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 4px;
    height: fit-content;

    @media screen and (max-width: $sm) {
      padding: 0;
      @include font-11-bold;
      overflow: visible;
      text-overflow: none;
      align-self: center;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 8px;
      width: 100%;
      height: 50%;
      border-bottom: 1px solid #9c93aa;
      transform: rotate(-4deg);
    }

    &--small {
      @include font-14-bold;
    }
  }

  &__variations {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: $sm) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: unset;
    }
  }

  &__count {
    color: var(--text-light);
    @include font-12;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: $sm) {
      @include font-12-bold;
    }

    @media screen and (max-width: $xs-up) {
      margin-left: 4px;
      text-wrap: nowrap;
    }
  }

  &__longTotal {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media screen and (max-width: $sm) {
      align-items: flex-start;
    }
  }

  &__longTotalDesktop {
    font-size: 13px;
  }

  &__total {
    text-align: right;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: var(--text);

    @media screen and (max-width: $sm) {
      text-align: left;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  @media screen and (max-width: 460px) {
    &__longTotalMobile {
      font-size: 18px !important;
    }
  }

  &__info {
    @include font-11;
    text-align: right;
    color: var(--text-light);
  }

  @media (max-width: 1024px) {
    width: 160px;
  }

  @media screen and (max-width: $sm) {
    text-align: left;
    align-items: flex-start;
    width: auto;
    margin-left: 28px;
    flex-direction: row;
    align-items: center;
    &__total {
      font-size: 24px;
    }
    &__info {
      display: none;
    }

    &__hint_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      outline: none;
      padding: 0;
      margin: 0;
      margin-left: 4px;

      .hint-icon {
        display: flex;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  @media screen and (max-width: $sm) {
    flex-direction: column;
    align-items: flex-start;

    &__firstBlock {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
}

.price__hint_btn {
  display: none;

  @media screen and (max-width: $sm) {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    margin-left: 4px;

    .hint-icon {
      display: flex;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@media (max-width: $sm) {
  .item {
    &:nth-child(2) {
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
    }
    &:last-child {
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
    }
  }
}

.total {
  @include font-16-bold;

  @media screen and (max-width: $xs-up) {
    text-wrap: nowrap;
  }
}
// .total {
//   display: flex;
//   align-items: flex-end;
//   justify-content: space-between;
//   margin-bottom: 12px;

//   &:last-child {
//     margin-bottom: 0px;
//   }
// }

// .cost {
//   color: #898989;
//   @include font-12;
// }

// .price {
//   color: #323236;
//   font-weight: 500;
//   font-size: 24px;
//   line-height: 115%;

//   &--small {
//     @include font-20-bold;

//     @media screen and (max-width: 1024px) {
//       @include font-20-bold;
//     }
//   }
// }

// .priceSmall {
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 135%;
// }

// .noto {
//   font-family: $font-currency;
// }

// .saleInside {
//   @include font-12-bold;
//   color: var(--text-light);
//   padding-right: 8px;
//   position: relative;

//   &::before {
//     content: "";
//     position: absolute;
//     left: 10;
//     bottom: 8px;
//     width: 80%;
//     height: 50%;
//     border-bottom: 1px solid var(--text-light);
//     transform-origin: left bottom;
//     transform: rotate(-7deg);
//   }
// }

.tag {
  @include font-11-bold;
  height: 24px;
  padding-top: 0;
}

.tooltipWrap {
  position: relative;
  display: flex;
  align-items: center;

  .tooltip {
    min-width: 250px;
    white-space: normal;
    color: var(--text);
    display: block;
    background-color: #fff;
    font-size: 14px;
    line-height: 140%;
    box-shadow:
      0px 0px 8px rgb(0 0 0 / 10%),
      0px 4px 8px rgb(0 0 0 / 10%);
    border-radius: 8px;
    transition: 0.3s;
    width: 100%;
    text-align: center;

    * {
      white-space: normal;
    }

    :global(.common__tooltip-inner) {
      box-shadow: none;
      height: unset;
      background: transparent;
      display: block;
    }

    :global(.common__tooltip-text) {
      width: 100%;
      display: block;
    }

    @media screen and (max-width: $sm) {
      min-width: 200px;
    }
  }

  @media screen and (max-width: $sm) {
    margin-top: -6px;
  }
}

.tooltipContainer {
  position: relative;
  display: flex;
}

.closeTimeTooltip {
  display: flex;
  align-items: center;
  width: max-content;
  position: relative;
}

.closeTimeInner {
  display: flex;
  color: var(--text-light);
  @include font-12;
  padding-right: 5px;
  margin-top: 6px;

  @media screen and (max-width: $sm) {
    margin-top: 0;
  }
}

.closeTime {
  font-weight: 500;
  padding-left: 3px;
}

.tagInner {
  position: relative;
  top: 0;

  @media screen and (max-width: $sm) {
    font-size: 11px;
  }
}

.hitIcon {
  position: relative;
  left: -4px;
}

.modalContainer {
  max-width: 480px;
  width: 100%;
  padding: 40px;

  @media screen and (max-width: $sm) {
    height: 100%;
    padding-bottom: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.modalInner {
  margin-bottom: 40px;
}

.modalTitle {
  @include font-22-bold;
  display: block;
  margin-bottom: 8px;
}

.modalText {
  margin: 0;
  @include font-14;
}

.buttonModalWrap {
  display: flex;
  align-items: center;
  & > button {
    flex: 1 1;
  }

  @media screen and (max-width: $sm) {
    flex-direction: column;
    margin-bottom: 70px;
  }
}

.buttonModal {
  margin-right: 8px;
  height: 40px;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: $sm) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.modalClose {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  padding: 0;
  margin: 0;
  border: none;

  .close-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  @media (max-width: $sm) {
    display: block;
  }
}

@media (max-width: $sm) {
  .del_modal {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(13, 9, 24, 0.56);
    backdrop-filter: blur(1.5px);
    z-index: 101;

    > div:nth-child(1) {
      display: none;
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      max-width: unset;
      // width: calc(100% + 24px);
      margin: 0 -12px;
    }

    .modalContainer {
      background: #fff;
      padding: 32px 20px 64px 20px;
      height: 350px;
      border-radius: 24px 24px 0px 0px;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: unset;
      position: relative;
      margin-bottom: -35px; // in safari for some reason it had a big indent at the bottom, although on the computer it was fine
    }

    .modalTitle {
      @include font-20-bold;
      margin-bottom: 12px;
    }

    .modalText {
      @include font-14;
    }

    .buttonModalWrap {
      margin-top: auto;
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 0;
    }

    .buttonModal {
      padding: 13px 32px;
    }
  }
}

.checkbox {
  padding: 4px;

  @media screen and (max-width: $sm) {
    align-self: flex-start;
  }
}

.modalPriceContainer {
  padding: 16px;

  .modalInner {
    margin: 0;
    text-align: center;
    padding: 0 0 10px;
  }

  .buttonModalWrap {
    padding: 0;
    margin: 0;
  }

  .buttonModal {
    width: 255px;
    padding: 8px 24px;
  }
}

.modalPriceContainer {
  padding: 16px;

  .modalInner {
    margin: 0;
    text-align: center;
    padding: 0 0 10px;
  }

  .buttonModalWrap {
    padding: 0;
    margin: 0;
  }

  .buttonModal {
    width: 255px;
    padding: 8px 24px;
  }
}

.bonusCount {
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media screen and (max-width: $sm) {
    justify-content: flex-start;
  }
}

.hidden {
  display: none;
}

.price__countDesktop {
  margin-left: 4px;
  @media screen and (max-width: $sm) {
    display: none;
  }
}

.wrapperPrice {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: 'center';
  gap: '4px';

  @media screen and (max-width: $sm) {
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
}
