@import '../../../../styles/main.scss';

// .form {
// }

.top-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 64px;
  row-gap: 24px;
  column-gap: 16px;
  margin-bottom: 24px;

  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 64px;
  row-gap: 24px;
  column-gap: 16px;
  margin-bottom: 24px;

  .fullName {
    grid-area: 1 / 1 / 1 / 3;
    position: relative;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
}

.email {
  grid-column-start: 1;
//   grid-column-end: 3;
  position: relative;
}

.phone {
    position: relative;
  }

.bottom-fields {
  position: relative;
//   display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 64px;
  column-gap: 16px;
  margin-bottom: 32px;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    column-gap: 16px;
  }
}

.submit {
  display: flex;

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    gap: 24px;
  }
}

.button {
  height: 40px;

  @media screen and (max-width: 1024px) {
    height: 40px;
    margin-right: 0;
  }
}

.checkbox-block {
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.text {
  @include font-12;
  display: flex;
  max-width: 360px;
  flex-wrap: wrap;

  span {
    margin-right: 4px;
  }

  .link {
    margin-right: 4px;
    color: var(--accent);

    a {
      color: var(--accent);
    }
  }
}

.input-field {
  position: relative;
}

.show-password {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  right: 13px;
  bottom: 0px;
  z-index: 10;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    bottom: 1px;
  }
}

.password-check {
  position: absolute;
  right: 0;
  top: -140px;
  z-index: 11;
  padding: 16px;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
  width: 213px;
  height: fit-content;

  .title {
    @include font-12-bold;
    margin-bottom: 8px;
  }

  .checks {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .check {
    display: flex;
    gap: 11px;
  }

  .icon {
    margin-top: 2px;
  }

  .name {
    color: var(--text-light);
    @include font-12;
  }

  .correct {
    color: var(--ui-02-main);
  }

  .wrong {
    color: $red;
  }
}

.slight-up {
  top: -140px;
}

.repeat-check {
  position: absolute;
  right: 0;
  top: -69px;
  z-index: 11;
  padding: 16px;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
  width: 213px;
  height: fit-content;

  .title {
    @include font-12-bold;
    margin-bottom: 8px;
  }

  .checks {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .check {
    display: flex;
    gap: 11px;
  }

  .icon {
    margin-top: 2px;
  }

  .name {
    color: var(--text-light);
    @include font-12;
  }

  .correct {
    color: var(--ui-02-main);
  }

  .wrong {
    color: $red;
  }
}

.error {
  position: absolute;
  color: $red;
  width: fit-content;
  right: 12px;
  white-space: nowrap;
  bottom: calc(1em + 4px);
  z-index: 10;
  height: 18px;

  @media screen and (max-width: 1024px) {
    bottom: calc(1em - 4px);
  }
}

.register_theme {
  input {
    &::placeholder{
      color: #9C93AA;
    }
    // border-radius: 8px;
    // border: 1px solid #DDC8FF;
    // background: #F7F5FF;
    // line-height: 140%;
  }
}