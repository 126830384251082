.form {

    &__label {
        width: 100%;
        color: var(--text);
        display: flex;
        @include font-12-bold;

        &--left {
            justify-content: flex-start;
        }

        &--right {
            justify-content: flex-end;
        }
    }

    &__hint {
        width: 100%;
        color: var(--text);
        display: flex;
        @include font-12-bold;
        margin-top: 4px;

        &--left {
            justify-content: flex-start;
        }

        &--right {
            justify-content: flex-end;
        }
    }

    &__input, &__textarea {
        background: var(--accent-light);
        border: 1px solid $grey-middle;
        border-radius: 8px;
        color: var(--text);
        font-size: 16px;
        padding: 8px 12px;
        transition: $transition;
        width: 100%;

        &--icon {
            padding-left: 40px;
        }

        &-wrap {
            display: flex;
            flex-direction: column;
            position: relative;
        }

        &-inner {
            position: relative;
        }

        &:disabled {
            cursor: default;
            opacity: 0.5;

            &:hover {
                border-color: $grey-middle;
            }
        }

        &:hover, &:focus {
            border-color: var(--accent);
        }

        &::placeholder {
            color: var(--text-light); 
        }

        &.error {
            border-color: $red;
            padding-right: 43px;
        }
    }

    &__textarea {
        resize: none;
    }

    &__error {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 12px;
        display: flex;
        align-items: center;
    }

    &__item {
    
        &-icon {
            position: absolute;
            left: 14px;
            top: 0;
            bottom: 0;
            margin: auto;
            display: flex;
            align-items: center;
        }
    }
}