@import 'styles/helpers/variables.scss';

.wrapper {
  margin-top: 24px;
  margin-bottom: 40px;
  border-radius: 12px;
  padding: 16px 20px;
  min-width: 300px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  cursor: pointer;

  &.Signature {
    background: var(--gradient-1);
  }

  &.Platinum {
    background: var(--gradient-platinum-small);
  }

  &.Gold {
    background: var(--gradient-gold-small);
  }

  @media screen and (max-width: $xs) {
    width: 100%;
  }

  .left {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-right: 10px;

    .text {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .title {
        @include font-12;
        color: $white;
      }

      .description {
        @include font-16-bold;
        color: $white;
      }
    }
  }

  .arrow {
    transform: rotate(-90deg);
    width: fit-content;
    margin-right: -10px;

    svg path {
      fill: white;
    }
  }
}
