@import 'styles/main.scss';

.wrapper {
  padding: 48px 0;

  @media (max-width: 1024px) {
    padding: 48px 0 110px 0;
  }
}

.title {
  @include font-32-bold;

  span {
    position: absolute;
    color: var(--text-light);
    @include font-12-bold;
  }
}

.grid {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 24px;

  @media (max-width: 1024px) {
    gap: 16px;
    margin: 0 -20px;
    margin-top: 24px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

@media (max-width: 1024px) {
  .grid {
    margin-top: 24px;
  }
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 36px;
  background: $white;
  box-shadow: var(--cart-shadow);
  padding: 92px 32px;
  &__header {
    @include font-32-bold;
    margin-bottom: 16px;
  }

  &__text {
    @include font-14;
    margin-bottom: 40px;
    max-width: 340px;
  }

  &__back {
    display: block;
    text-align: center;
    padding: 9px 32px;
    border-radius: 80px;
    background: var(--accent);
    color: $white;
    @include font-14-bold;
  }

  @media (max-width: 680px) {
    padding: 56px;
    &__header {
      margin-bottom: 8px;
    }
    &__text {
      margin-bottom: 32px;
    }
  }
}

// .container {
//   position: relative;
//   height: 100%;
//   overflow: hidden;

//   @media screen and (min-width: 1024px) {
//     height: calc(100vh - 148px);
//   }

//   .title {
//     width: 100%;
//     max-width: 494px;
//     height: 47px;
//     margin: auto;
//     text-align: center;
//     @include font-32-bold;
//   }

//   .text {
//     width: 100%;
//     max-width: 494px;
//     height: 62px;
//     margin: 16px auto 0;
//     text-align: center;
//     @include font-22;
//   }

//   .button {
//     width: 205px;
//     height: 40px;
//     margin: 48px auto 0;
//     text-align: center;
//   }

//   @media only screen and (max-width: 1024px) {
//     .title {
//       text-align: center;
//       @include font-32-bold;
//     }

//     .text {
//       margin: 13px auto 0;
//       text-align: center;
//       @include font-20;
//     }
//   }

//   @media only screen and (max-width: 360px) {
//     .title {
//       height: 29px;
//       @include font-22-bold;
//     }

//     .text {
//       height: 44px;
//       margin: 8px auto 0;
//       @include font-14;
//     }

//     .button {
//       height: 32px;
//       margin: 32px auto 0;
//     }
//   }
// }

// .inner {
//   position: relative;
//   z-index: 1;
//   height: 100%;

//   @media screen and (max-width: 960px) {
//     display: flex;
//     flex-direction: column;
//   }
// }

// .info {
//   padding-top: 120px;
//   position: relative;
//   z-index: 1;

//   @media screen and (max-width: 1440px) {
//     padding-top: 90px;
//   }
// }

// .imgWrap {
//   width: 100%;
//   bottom: -120px;
//   margin: auto;
//   left: 0;
//   right: 0;
//   max-width: 900px;
//   display: flex;
//   align-items: flex-end;
//   position: absolute;

//   img {
//     display: block;
//     width: 100%;
//   }

//   @media screen and (max-width: 960px) {
//     display: flex;
//     position: relative;
//     height: 100%;
//     align-items: center;

//     img {
//       display: block;
//       width: 800px;
//       position: absolute;
//       left: 50%;
//       transform: translateX(-50%);
//       top: 0;
//     }
//   }

//   @media screen and (max-width: 650px) {
//     img {
//       display: block;
//       width: 700px;
//       position: absolute;
//       left: 50%;
//       transform: translateX(-50%);
//       top: 0;
//     }
//   }
// }

// .gradient {
//   width: 100%;

//   @media only screen and (max-width: 960px) {
//     background-image: url("../../assets/img/Sources/BG-Grad.png");
//     position: relative;
//     background-size: cover;
//     min-height: 60vh;
//   }

//   @media only screen and (max-width: 360px) {
//     background-image: url("../../assets/img/Sources/BG-Grad-Small.png");
//     position: relative;
//     left: calc(-50vw + 50%);
//     background-size: cover;
//     width: 360px;
//     min-height: 70vh;
//   }
// }
