@import 'styles/main.scss';

.wrapper {
  background-color: var(--accent-light);
  border-radius: 12px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;

  .title {
    color: var(--text);
    @include font-12-bold;
    margin-bottom: 4px;
  }

  .description {
    @include font-11;
    color: var(--text-light);
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    .link {
      color: var(--text-light);
      cursor: pointer;
      text-decoration: underline;
    }

    .link:visited {
      color: var(--text-light);
    }
  }
}
