@import '../../../styles/main.scss';

.wrapper {

}

.heading {
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-bottom: 45px;
  @include font-32-bold;

  @media screen and (max-width: 1024px) { 
    @include font-22-bold;
  }

  svg {
    width: 64px;
    height: 53px;

    @media screen and (max-width: 1024px) { 
      width: 64px;
      height: 53px;
      margin-bottom: 18px;
    }
  }
}

.body {
  padding: 32px;
  background: $grey;
  border-radius: 8px;

  @media screen and (max-width: 1024px) { 
  padding: 16px;
  }

  .title {
    @include font-22-bold;
    margin-bottom: 24px;

    @media screen and (max-width: 1024px) { 
      @include font-20-bold;
    }
  }

  .text {
    @include font-14;
    margin-bottom: 12px;

    span {
      @include font-14-bold;
    }
  }

  .help {
    @include font-14;

    .link {
      margin-left: 3px;
      color: var(--accent);
      cursor: pointer;
      @include font-14-bold;

      a {
        color: #8362F3;
      }
    }
  }
}
.notice {
  position: absolute;
  top: 10px;
  right: 10px;
}