@import 'styles/helpers/variables.scss';

.mainBlock {
  display: flex;
  flex-direction: column;
  width: 996px;
  gap: 24px;
  padding: 32px;
  border-radius: 24px;
  background: var(--white);
  box-shadow: var(--fire-shadow);

  @media screen and (max-width: 1024px) {
    width: auto;
    padding: 24px 20px;
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--text-light);
    @include font-12;
    line-height: 140%;

    svg{
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }

    &--active{
      color: var(--accent);
      font-weight: 700;
    }

    @media (max-width: 600px) {
    gap: 14px;
    color: var(--text-light);
    @include font-14;

    &--active{
      color: var(--accent);
      font-weight: 700;
    }
    
    svg{
      width: 17.5px;
      height: 17.5px;
      flex-shrink: 0;
    }
    }
  }

  &__imgBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-height: 477px;
    border-radius: 16px;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
      max-height: 352px;
    }

    @media screen and (max-width: 600px) {
      max-height: 180px;
    }

    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-tags {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    &-title {
      @include font-32-bold;

      @media screen and (max-width: 1024px) {
        @include font-22-bold;
      }
    }
  }

  &__info {
    display: flex;
    align-items: flex-start;
    gap: 48px;

    @media (max-width: 700px) {
      flex-direction: column;
      gap: 20px;
    }

    &-completed {
      color: var(--text-light);
    }

    &-link {
      display: flex;
      align-items: center;
      gap: 6px;
      color: var(--accent);
      @include font-14-bold;
      line-height: 140%;
    }

    &-textButton {
      font-size: 11px;
      margin: 8px 0px 0px 40px;
      text-wrap: nowrap;
    }

    &-mainButton {
      align-self: center;
      margin-left: auto;
      padding: 12px 24px;
      text-wrap: nowrap;
      min-width: 178px;

      &.disabled {
        display: flex;
        align-items: center;
        gap: 8px;
        min-width: 170px;
        cursor: default;
        &:hover {
          opacity: 1;
        }

        svg {
          transform: scale(1.1);
          fill: var(--accent);
        }
      }

      &.float {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 70px;
        z-index: 3;
        max-width: 350px;
        animation: slideUp 0.2s ease-out;
      }

      @media screen and (max-width: 700px) {
        align-self: unset;
        margin-left: unset;
      }

      @media screen and (max-width: 600px) {
        align-self: unset;
        margin-left: unset;
        width: 100%;
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
    }

    strong {
      font-weight: 500;
    }

    ol {
      list-style: decimal;
      li {
        padding-left: 5px;
      }
    }
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100px) translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0) translateX(-50%);
    opacity: 1;
  }
}

.attention {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 24px;
  border-radius: 12px;
  background-color: var(--ui-03-8);
  margin-bottom: 24px;
  max-width: 932px;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__title {
    @include font-14-bold;
  }

  &__description {
    @include font-12;
  }

  &__link {
    cursor: pointer;
    color: var(--accent);
  }

  &__file {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--accent);
    margin-top: 4px;
    @include font-12;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
