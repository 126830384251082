@import 'styles/helpers/variables.scss';

@keyframes skeleton-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.skeleton {
  animation: skeleton-animation 2s infinite;
}

.wrapper {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  overflow-x: clip;
  padding-top: 15px;
  margin-right: -20px;

  @media screen and (min-width: $xs-up) {
    margin-right: 0px;
  }

  .card {
    flex-shrink: 0;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 311.75px;
    border-radius: 36px;
    padding: 20px;
    background-color: var(--skeleton-gray-light);

    @media screen and (min-width: $xs-up) {
      width: 309px;
      height: 310px;
    }

    .top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      .tag {
        width: 70px;
        height: 32px;
        border-radius: 24px;
        background-color: var(--skeleton-gray);
        @extend .skeleton;
      }

      .title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        width: 100%;

        .title-item {
          width: 100%;
          height: 22px;
          border-radius: 24px;
          background-color: var(--skeleton-gray);
          @extend .skeleton;

          &:last-child {
            width: 50%;
          }
        }
      }
    }

    .button {
      width: 100%;
      height: 48px;
      border-radius: 72px;
      background-color: var(--skeleton-gray);
      @extend .skeleton;
    }
  }
}
