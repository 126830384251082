@import 'styles/helpers/variables.scss';

.fire {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: start;
  background: var(--fire-color);
  border-radius: 112px;
  margin: auto;
  position: relative;
  z-index: 1;
  margin-bottom: -4px;

  img {
    height: 45.45px;
    left: 4px;
    top: 3px;
    position: absolute;
    transform: rotate(-8deg);
  }

  @media screen and (max-width: $xs) {
    width: 36px;
    height: 36px;
    margin-bottom: -2px;

    img {
      height: 29px;
      left: 2px;
      top: 3px;
    }
  }
}

.fireDisabled {
  margin: auto;
  margin-bottom: -4px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $xs) {
    margin-bottom: -2px;

    img {
      width: 36px;
      height: 36px;
    }
  }
}

.desc {
  @include font-12;
  color: var(--text);

  @media screen and (max-width: $xs) {
    font-size: 8px;
    line-height: 130%;
    margin-bottom: -1px;
  }
}

.link {
  position: fixed;
  bottom: 42px;
  left: 0;
  z-index: 29;
  display: block;
  width: 144px;
  padding-bottom: 20px;
  color: var(--text);

  .linkInner {
    position: absolute;
    bottom: 0;
    padding-bottom: 20px;
  }

  &:hover {
    color: var(--text);
  }

  @media screen and (max-width: 960px) {
    padding-bottom: 60px;
    margin-left: 0;
    width: 90px;
    bottom: 80px;
  }
}

.timer {
  background: #ffffff;
  border: 1px solid var(--accent-light);
  box-shadow:
    var(--fire-shadow),
    0px 3px 9px rgba(124, 99, 184, 0.1);
  border-radius: 40px;
  padding: 3px 16px;
  text-align: center;

  @media screen and (max-width: $xs) {
    padding: 2px 10px;
  }
}

.inner {
  color: var(--accent);
  @include font-12-bold;

  @media screen and (max-width: $xs) {
    font-size: 9px;
    font-weight: 500;
    line-height: 140%;
    height: 12px;
    overflow: hidden;
  }
}

.timerInner {
  display: flex;
  flex-direction: row-reverse;
  background: #ffffff;
  border: 1px solid #f7f5ff;
  box-shadow: var(--fire-shadow);
  border-radius: 69px;
  padding: 4px;
}

.transparent {
  background-color: transparent;
  box-shadow: none;
  padding: 4px 15px;
  border: none;

  @media screen and (max-width: $xs) {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
  }
}

.fireRelative {
  margin-bottom: 0;
  width: 50px;
  height: 50px;

  img {
    height: 39.45px;
  }

  @media screen and (max-width: $xs) {
    height: 40px;
    width: 40px;

    img {
      height: 34px;
    }
  }
}

.row {
  font-weight: 500;
  font-size: 32px;
  line-height: 130%;
  position: relative;
  top: 4px;

  @media screen and (max-width: $xs) {
    height: unset;
    @include font-22-bold;
  }
}

.timerInnerFeature {
  position: relative;
  background: rgba(114, 90, 214, 0.6);
  border-radius: 80px;
  border: none;
  padding: 7px 12px;
}

.feature {
  position: absolute;
  background: #ffffff;
  border: 1px solid #f7f5ff;
  box-shadow: var(--fire-shadow);
  border-radius: 40px;
  padding: 2px 12px;
  left: 0;
  right: 0;
  margin: auto;
  max-width: max-content;
  top: 0;
  transform: translateY(-80%);
  font-size: 14px;
  line-height: 140%;
  color: var(--accent);

  @media screen and (max-width: $xs) {
    font-size: 12px;
  }
}

.featureBold {
  font-weight: 500;
}

.rows {
  display: flex;
  position: relative;
  top: 5px;

  @media screen and (max-width: $xs) {
    height: unset;
    top: 3px;
  }
}

.timeColumn {
  display: flex;

  &:after {
    content: ':';
    color: $white;
    @include font-32-bold;
    display: block;
    margin: 0 5px;
  }

  &:last-child {
    &:after {
      content: none;
    }
  }
}

.timeInner {
  display: flex;
  flex-direction: column;
}

.timeNumber {
  color: $white;
  @include font-32-bold;
  margin-bottom: -5px;

  @media screen and (max-width: $xs) {
    @include font-22;
  }
}

.wbr {
  padding: 0 5px;
}

.timeName {
  background: linear-gradient(
    157.88deg,
    #efefef 15.24%,
    rgba(255, 255, 255, 0.24) 108.48%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media screen and (max-width: $xs) {
    @include font-12;
  }
}

.clock {
  position: relative;
  top: 2px;
  left: 3px;

  @media screen and (max-width: $xs) {
    width: 44px;
    height: 44px;
    top: 0;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

.small {
  border: 0.872962px solid var(--accent);
  box-shadow: var(--fire-shadow);
  max-width: max-content;
  padding: 1px 4px;
  margin-top: 16px;

  .fire {
    height: 25px;
    width: 25px;
    margin: 0;
    top: 0px;
    right: -2px;

    img {
      width: 22px;
      height: 22px;
      left: 1px;
      top: 0px;
    }
  }

  .row {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    position: relative;
    top: 4px;
    padding: 0 4px;

    @media screen and (max-width: $xs-up) {
      top: 2px;
    }
  }

  .transparent {
    padding: 0;
  }

  @media screen and (max-width: $xs-up) {
    margin-top: 12px;
    margin-bottom: 22px;
  }
}

.detail {
  &.small {
    margin-top: 0;
    padding: 3px 4px;
    border: none;

    @media screen and (max-width: $xs-up) {
      margin-bottom: 0;
    }
  }

  .row {
    padding-left: 8px;
    padding-right: 8px;
  }
}
