@import 'styles/main.scss';

.card {
  width: 100%;
  border-radius: 36px;
  background: #fff;
  padding: 32px;
  box-shadow: var(--cart-shadow);

  @media (max-width: 1024px) {
    padding: 24px 20px;
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 8px;
    flex-direction: column;

    &_mobile {
      display: none;
    }

    &__bottom {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      &__separate {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #9c93aa;
        margin: 0 6px;
      }
    }

    @media (max-width: 1024px) {
      display: none;

      &_mobile {
        display: block;
      }
    }
  }

  &__source {
    gap: 2px;
    color: var(--accent);
    @include font-14;

    &__mobile {
      @include font-12;
      color: var(--accent);
      gap: 2px;
    }
  }

  &__header {
    margin: 0;
    @include font-22-bold;
    line-height: 120%;

    @media (max-width: 1024px) {
      margin: 12px 0 4px 0;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 16px;
    color: black;
    margin-bottom: 8px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
  &__link:hover {
    color: var(--accent);
  }

  &__description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__arrowIcon {
    width: 9px;
    height: 9px;
    margin-left: 5px;
    stroke-width: 1.5px;
  }

  &__tags {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__tag {
    border-radius: 36px;
    padding: 4px 12px;
    @include font-11-extra-bold;
    letter-spacing: 0.24px;
    color: var(--accent);
    display: inline-block;

    &_purple {
      color: var(--ui-03-main);
      background: rgba(var(--ui-03-main-rgb), 0.08);
    }

    &_yellow {
      color: var(--warning-main);
      background: rgba(var(--warning-main-rgb), 0.08);
    }

    &_green {
      color: var(--success-16);
      background: rgba(var(--success-16-rgb), 0.08);
    }

    &_red {
      color: var(--error-main);
      background: rgba(var(--error-main-rgb), 0.08);
    }

    // &_purple {
    //   color: var(--accent);
    //   background: var(--accent-light);
    // }

    // &_yellow {
    //   color: #fab005;
    //   background: rgba(250, 176, 5, 0.08);
    // }

    // &_green {
    //   color: #37b24d;
    //   background: rgba(55, 178, 77, 0.08);
    // }

    // &_red {
    //   color: #f03e3e;
    //   background: rgba(240, 62, 62, 0.08);
    // }
  }

  &__id {
    @include font-12-bold;
    color: var(--text-light);
  }

  &__grid {
    display: flex;
    gap: 20px;

    @media (max-width: 1024px) {
      flex-direction: column;
      gap: 12px;
    }

    &__card {
      padding: 24px;
      background: var(--accent-light);
      border-radius: 20px;
      flex: 1 1;
      font-size: 14px;
      line-height: 140%;
      display: flex;
      flex-direction: column;

      @media (max-width: 1024px) {
        padding: 16px 20px;
      }

      &_manager {
        @media (max-width: 1024px) {
          .card__grid__card__header {
            margin-bottom: 0;
          }

          flex-direction: row;
          align-items: center;
        }
        &_header {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          @media (max-width: 1024px) {
            &_withName {
              flex-direction: row;
              align-items: center;
            }
          }
        }
      }

      &__header {
        margin-bottom: 16px;
        color: var(--text-light);
        @include font-12;

        @media (max-width: 1024px) {
          margin-bottom: 12px;
        }
      }

      &__coast {
        @include font-32-bold;
        line-height: 110%;
        margin-bottom: 4px;

        @media (max-width: 1024px) {
          margin-top: 4px;
        }
      }

      &__text {
        @include font-12;
        margin-bottom: 43px;
        &:last-child {
          margin-bottom: 0;
        }
      }

      &__budget {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        gap: 6px;

        &__item {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          gap: 8px;
        }

        &__line {
          width: 100%;

          svg {
            width: 100%;
            height: 4px;
          }
        }

        &__text {
          flex-shrink: 0;
        }
      }

      &__img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 8px;

        @media (max-width: 1024px) {
          width: 48px;
          height: 48px;
          margin-bottom: 0;
          margin-right: 8px;
        }
      }

      &__title {
        @include font-16-bold;
        margin: 0;
      }

      &__btn {
        padding: 12px 20px 12px 24px;
        border-radius: 80px;
        background: #fff;
        align-self: flex-start;
        text-align: center;
        color: #8362f3;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        margin-top: auto;

        svg {
          display: none;
        }

        @media (max-width: 1024px) {
          margin-top: 0;
          margin-left: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          padding: 0;

          span {
            display: none;
          }

          svg {
            display: block;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  .map {
    position: relative;
    width: 100%;

    &__link {
      display: flex;
      align-items: center;
      gap: 4px;
      color: var(--accent);
      @include font-12-bold;
    }

    .step {
      width: 100%;
      position: relative;
      margin-bottom: 16px;
      // margin-left: 0.5em;

      & > svg {
        flex-shrink: 0;
      }

      &__date {
        @include font-11;
        width: 85px;
        margin-left: auto;
      }

      &__block {
        margin-top: 1px;
        flex-shrink: 0;

        span {
          color: var(--text);
          font-weight: 500;
          @include font-14-bold;
        }

        p {
          margin: 0;
          margin-bottom: 6px;
          @include font-12;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .empty {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        border: 1px solid rgba(var(--text), 0.2);
      }

      &_time {
        &::after {
          content: '';
          opacity: 0.2;
        }
      }

      &_empty {
        .step__block span {
          font-weight: 400;
        }

        &::after {
          content: '';
          opacity: 0.2;
        }
      }

      &::after {
        content: '';
        width: 2px;
        height: calc(100% + 2px);
        position: absolute;
        top: 18px;
        left: 0.65em;
        background-color: var(--accent);
      }

      &:last-child {
        margin-bottom: 0px;

        &::after {
          content: none;
        }
      }

      .name {
        display: flex;
        gap: 8px;
        align-items: flex-start;
        @include font-14-bold;

        span {
          padding-top: 3px;
        }
      }

      .past {
        color: var(--text);
      }
    }
  }
}

.no_manager_description,
.no_manager_title {
  margin: 0;
}

.no_manager_title {
  @include font-16-bold;
}

.no_manager_description {
  @include font-12;
  color: var(--text-light);
}
