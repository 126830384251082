@import 'styles/helpers/variables.scss';

.form {
  position: relative;

  .fields {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .input-field {
    position: relative;

    .password {
      @include font-14-bold;

      a {
        color: #8362f3;
      }
    }

    .svg {
      fill: var(--text-light);
    }
  }

  .button {
    height: 40px;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .buttons {
    display: flex;
    gap: 8px;
  }
}

.error {
  position: absolute;
  color: $red;
  width: fit-content;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.mobile-error {
  background: linear-gradient(
      0deg,
      rgba(245, 34, 45, 0.04),
      rgba(245, 34, 45, 0.04)
    ),
    #ffffff;
  border: 1px solid $red;
  border-radius: 8px;
  margin-top: 28px;
  padding: 8px 16px;
}

.input-field {
  position: relative;
}

.show-password {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  right: 13px;
  z-index: 10;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (max-width: 1024px) {
    bottom: 1px;
  }
}

.password-check {
  position: absolute;
  right: 0px;
  bottom: 50px;
  z-index: 11;
  padding: 16px;
  background-color: $white;
  border-radius: 8px;
  box-shadow:
    0px 0px 4px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.06);
  width: 213px;
  height: fit-content;

  @media screen and (max-width: 1024px) {
    left: auto;
    right: 0;
    top: -100px;
  }

  .title {
    @include font-12-bold;
    margin-bottom: 8px;
  }

  .checks {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .check {
    display: flex;
    gap: 11px;
  }

  .icon {
    margin-top: 2px;
  }

  .name {
    color: var(--text-light);
    @include font-12;
  }

  .correct {
    color: var(--ui-02-main);
  }

  .wrong {
    color: $red;
  }
}

.repeat-check {
  position: absolute;
  right: 0px;
  bottom: 50px;
  z-index: 11;
  padding: 16px;
  background-color: $white;
  border-radius: 8px;
  box-shadow:
    0px 0px 4px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.06);
  width: 213px;
  height: fit-content;

  @media screen and (max-width: 1024px) {
    left: auto;
    right: 0;
  }

  .title {
    @include font-12-bold;
    margin-bottom: 8px;
  }

  .checks {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .check {
    display: flex;
    gap: 11px;
  }

  .icon {
    margin-top: 2px;
  }

  .name {
    color: var(--text-light);
    @include font-12;
  }

  .correct {
    color: var(--ui-02-main);
  }

  .wrong {
    color: $red;
  }
}

.row {
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
}

.titleWrap {
  margin-bottom: 24px;

  .info {
    margin: 0;
    margin-top: 8px;
  }
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  @include font-22-bold;

  @media (max-width: $sm) {
    @include font-18-bold;
  }
}

.field {
  position: relative;
}

.notice {
  position: absolute;
  top: 10px;
  right: 0;
}

.box {
  margin-bottom: 48px;
}

.button {
  @media screen and (max-width: $xs-up) {
    width: 100%;
  }
}

.inner {
  @media screen and (max-width: $xs-up) {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 280px);
  }
}

.field,
.titleWrap {
  max-width: 600px;
}
