@import 'styles/helpers/variables.scss';

.wrapper {
  padding: 0px 8px;

  &:not(:first-child) {
    padding-top: 20px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--accent-light);
    padding-bottom: 20px;
  }
}

.transaction {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__date {
    @include font-12;
    color: var(--text-light);
  }

  &__body {
    span {
      display: block;

      &:first-child {
        @include font-16-bold;
      }

      &:last-child {
        @include font-11;
        color: var(--text-light);
      }
    }
  }

  &__footer {
    span {
      display: block;

      &:first-child {
        @include font-12-bold;
        line-height: 140%;
        margin-bottom: 2px;
      }

      &:last-child {
        @include font-10;
        line-height: 130%;
        color: var(--text-light);
      }
    }
  }

  &__sticker {
    &--red {
      background-color: var(--error-8);
      color: var(--error-main);
    }

    &--green {
      background-color: var(--green-unknown);
      color: var(--green-unknown-text);
    }

    &--yellow {
      background-color: var(--ui-01-8);
      color: var(--ui-01-main);
    }

    &--blue {
      background-color: var(--ui-04-8);
      color: var(--ui-04-main);
    }
  }
}

.price {
  span {
    @include font-22-bold;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}
