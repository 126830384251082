@import 'styles/helpers/variables.scss';

.wrapper {
  width: 100%;
  margin-bottom: 32px;
  @media (max-width: 960px) {
    margin-bottom: 24px;
  }
}

.img {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 16px 0 0;
  overflow: hidden;

  @media screen and (max-width: $xs-up) {
    border-radius: 0;
  }
}

.input {
  width: 100%;
  position: relative;
  z-index: 1;

  input {
    padding: 8px 55px 8px 12px;
    background: var(--accent-light);
  }
}

.btn {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
}

.error_msg {
  position: absolute;
  color: $red;
  width: fit-content;
  right: 17.67px;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;

  @media screen and (max-width: $xs) {
    :global(.common__tooltip-inner) {
      right: -20px !important;
      top: 10px !important;
    }

    ::after {
      right: -120px !important;
    }
  }
}

.infoIcon {
  @media screen and (max-width: $xs) {
    padding: 5px 10px;
    height: 28px;
    width: 38px;
  }
}
