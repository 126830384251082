@import 'styles/helpers/variables.scss';

.file {
  @include font-12;
  width: 100%;
  display: flex;
  align-items: end;
  gap: 4px;

  .dashed {
    flex-grow: 1;
    border-bottom: 1px dashed var(--accent-second);
    transform: translateY(-2px);
  }

  &__title {
    display: flex;
    align-items: end;
    @include font-14;

    a {
      display: inline-block;
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__size {
    display: inline-block;
    color: var(--grey);
  }

  &__date {
    display: inline-block;
    color: var(--text-light);
  }

  @media screen and (max-width: 1240px) {
    &__title {
      a {
        max-width: 180px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &__title {
      a {
        max-width: 500px;
      }
    }
  }

  @media screen and (max-width: 491px) {
    &__title {
      a {
        max-width: 140px;
      }
    }
  }
}
