@import 'styles/main.scss';

.formhint {
  width: 100%;
  color: var(--text);
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  margin-top: 4px;
}
.formhint.left {
  justify-content: flex-start;
}
.formhint.right {
  justify-content: flex-end;
}

.input {
  width: 100%;
  border: 1px solid var(--accent-second);
  padding: 8px 50px 6px 12px;
  border-radius: 8px;
  @include font-14;
  background: var(--accent-light);
  color: var(--text);
  transition: 0.3s;
  &::placeholder,
  input::placeholder {
    color: var(--text-light);
  }

  @media screen and (max-width: $xs) {
    @include font-16;
  }
}

.isBigInput {
  padding: 12.7px 50px 12.7px 16px;

  border: 1.5px solid var(--accent-second);
}

.input:focus + .inputLabelPlaceholderDefault {
  @include font-12;
  top: 25%;
  transform: translateY(-25%);
}

.textarea {
  background: var(--accent-light);
  border: 1.5px solid var(--accent-second);
  border-radius: 8px;
  color: var(--text);
  font-size: 14px;
  padding: 8px 12px;
  transition: 0.3s;
  width: 100%;
  min-height: 72px;
  transition:
    background 0.3s,
    border 0.3s;

  &:hover,
  &:active {
    background: none;
    border: 1.5px solid var(--accent);
  }

  &::placeholder {
    color: var(--text-light);
  }

  @media screen and (max-width: $xs) {
    @include font-16;
  }
}

.inputicon,
.formtextarea.icon {
  padding-left: 52px;
}

.inputCurrency,
.formtextarea.icon {
  padding-left: 34px;
}

.inputWrap,
.formtextareaWrap {
  display: flex;
  flex-direction: column;
  position: relative;
}

.inputInner,
.formtextareaInner {
  position: relative;
}

.input:disabled,
.formtextarea:disabled {
  cursor: default;
  opacity: 0.5;
}

.input:not(:disabled):hover,
.form__textarea:not(:disabled):hover,
.input:not(:disabled):focus,
.form__textarea:not(:disabled):focus {
  border: 1.5px solid var(--accent);
  background-color: $white;
}

.input::placeholder,
.form__textarea::placeholder {
  color: var(--text-light);
}

.inputError,
.textareaError {
  border-color: var(--error-main) !important;
  background-color: var(--error-8);
  padding-right: 43px;
}

.textarea {
  resize: none;
}

.iconWrap {
  position: absolute;
  left: 14px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
}

.currencyWrap {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  color: var(--text-light);
  padding-bottom: 5px;
  padding-top: 7px;

  &__dollarIcon {
    display: flex;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.inputLabel {
  height: 56px;
  border: 1.5px solid transparent;
  transition: border, background-color, 0.3s;
  padding-top: 20px;

  .input:hover,
  .form__textarea:hover,
  .input:focus,
  .form__textarea:focus {
    border: 1.5px solid var(--accent);
    background-color: $white;
  }
}

.inputLabelEmptyInput {
  padding-top: 8px;
}

.inputLabelPlaceholder {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  color: var(--text-light);
  transition:
    top 0.3s,
    transform 0.3s;
  @include font-14;
}

.inputLabelPlaceholderActive {
  @include font-12;
  top: 25%;
  transform: translateY(-25%);
}

.inputIconPlaceholder {
  padding-left: 52px;
}

.inputLabelPlaceholderDefault {
  pointer-events: none;
}

.clearIcon {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  cursor: pointer;
}

.error {
  position: absolute;
  color: var(--text);
  width: fit-content;
  right: 12px;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);
  height: 18px;
}

.ru {
  background: $white;
}

.bigInput {
  left: 12px;
}

.errorText {
  margin-top: 4px;
  color: var(--error-main);
  @include font-12;
}

:global(.common__tooltip-inner) {
  background: #615a6d !important;
  color: #ffffff;
  text-align: left;

  &:after {
    border-top: 10px solid #615a6d !important;

    @media screen and (max-width: 1024px) {
      right: -80px;
    }
  }
}

.errorAbsolute {
  position: absolute;
  top: 56px;
  left: 0;
}

.helperTextTooltip {
  @media screen and (max-width: $xs-up) {
    :global(.common__tooltip-inner) {
      right: -20px !important;
    }

    ::after {
      right: -140px !important;
    }
  }
}

.wadeTooltip {
  width: 300px !important;
  max-width: 300px !important;

  @media screen and (max-width: $xs) {
    right: -80px !important;

    ::after {
      right: -250px !important;
    }
  }
}

@media screen and (max-width: $xs) {
  .input,
  .textarea,
  .formhint,
  .errorText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.questionIcon {
  path {
    stroke: var(--grey);
  }
}
