@import 'styles/helpers/variables.scss';

.wrapper {
  padding: 32px 0;

  @media (max-width: 1024px) {
    padding: 46px 0;
    margin-bottom: 26px;
  }

  @media screen and (max-width: 480px) {
    margin: 36px -20px;
  }
}

.breadcrumbs {
  margin-bottom: 24px;

  @media screen and (max-width: 480px) {
    margin-left: 16px;
  }
}

.content {
  display: flex;
  gap: 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.selectBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .selectWrapper {
    width: 350px;
  }
}
