.clientList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    gap: 16px;
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 624px) {
    grid-template-columns: 1fr;
  }
}
