@import 'styles/helpers/variables.scss';

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 4;
  max-width: 500px;
  margin: auto;
}

.text {
  margin: 0;
}

.title {
  margin-bottom: 16px;
  text-align: center;

  @media screen and (max-width: $xs-up) {
    h2 {
      font-size: 24px;
    }
  }
}

.subtitle {
  margin-bottom: 48px;
  text-align: center;

  @media screen and (max-width: $xs-up) {
    margin-bottom: 40px;
    h3 {
      font-size: 16px;
    }
  }
}

.wrap {
  margin-top: 138px;

  &:after {
    content: '';
    display: block;
    width: 100vw;
    height: calc(100vh - 60.22px);
    position: fixed;
    top: 0;
    left: 0;
    background-image: url('/assets/img/System/gradient.jpg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (max-width: $sm) {
      height: 100vh;
    }

    @media screen and (max-width: $xs-up) {
      background-image: url('/assets/img/System/gradient-mobile.png');
    }
  }

  @media screen and (max-width: $xs-up) {
    margin-top: 80px;
  }
}

.button {
  color: $white;
}

.img {
  position: fixed;
  bottom: 0;
  z-index: 2;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;

  @media screen and (max-width: $xs-up) {
    max-width: 250px;
    margin: auto;
    bottom: 30px;

    img {
      max-width: 100%;
    }
  }
}
