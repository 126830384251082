@import 'styles/helpers/variables.scss';

.wrapper {
  display: flex;
  align-items: flex-start;
  gap: 12px;

  svg {
    width: 17.5px;
    height: 17.5px;

    fill: var(--accent);
  }

  .title {
    display: block;
    @include font-14-bold;
    margin-bottom: 4px;
  }

  .description {
    display: block;
    @include font-12;
    line-height: 140%;
  }
}
