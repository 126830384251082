@import '../../../../styles/main.scss';

// .form {
// }

.top-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 64px;
  row-gap: 24px;
  column-gap: 16px;
  margin-bottom: 24px;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
}

.email {
  grid-column-start: 1;
  grid-column-end: 3;
}

.goals {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .title {
    @include font-12-bold;
  }
}

.check {
  margin-bottom: 32px;

  @media screen and (max-width: 1024px) {
    margin-bottom: 32px;
  }
}

.bottom-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 64px;
  column-gap: 16px;
  margin-bottom: 32px;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    column-gap: 16px;
  }
}

.submit {
  display: flex;
  align-items: center;
  max-height: 40px;
}

.submit-button {
  display: inline-flex;
  height: 40px;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.button {
  margin-right: 24px;
  svg {
    fill: var(--accent);
  }
}

.text {
  @include font-12;
  display: flex;
  max-width: 360px;
  flex-wrap: wrap;

  span {
    margin-right: 4px;
  }

  .link {
    margin-right: 4px;

    a {
      color: #8362F3;
    }
  }
}

.tabs {
  width: 100%;
  margin-bottom: 16px;
}

.comment {
  margin-bottom: 28px;
}

.input-field {
  position: relative;
  @include font-12-bold;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &--full {
    grid-column: span 2;
  }
}

.error {
  position: absolute;
  color: $red;
  width: fit-content;
  right: 12px;
  white-space: nowrap;
  bottom: calc(1em + 5px);
  z-index: 10;
  height: 18px;

  @media screen and (max-width: 1024px) {
    bottom: calc(1em - 4px);
  }
}

.captcha {
  position: relative;
  margin-top: 32px;
  margin-bottom: 30px;

  @media screen and (max-width: 1024px) {
    margin-top: 28px;
    margin-bottom: 22px;
  }
}