@import 'styles/helpers/variables.scss';

.tag {
  display: flex;
  padding: 4px 12px;
  align-items: center;
  border-radius: 36px;
  max-width: max-content;
  color: var(--white);
  gap: 4px;

  svg {
    width: 14px;
    height: 14px;
  }

  &__text {
    @include font-10-bold;
    letter-spacing: 0.24px;
    text-wrap: nowrap;
  }

  &_purple {
    background: var(--ui-03-main);
  }

  &_yellow {
    background-color: var(--ui-01-main);
  }

  &_green {
    background-color: var(--ui-02-main);
  }
}
