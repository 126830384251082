@import 'styles/helpers/variables.scss';

.wrapper {
  padding: 40px 0;

  .title {
    @include font-32-bold;
    position: relative;
    margin-bottom: 24px;

    @media screen and (max-width: 1024px) {
      @include font-32-bold;
    }
  }

  .content {
    display: flex;
    gap: 32px;

    @media screen and (max-width: 1300px) {
      gap: 24px;
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      height: 100%;
    }
  }

  .left {
    width: 240px;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .right {
    width: 1024px;
    padding-bottom: 40px;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    @media screen and (max-width: 960px) {
      padding-bottom: 80px;
    }
  }

  .wrapper-other {
    min-height: 375px;
    margin-top: 96px;
    margin-bottom: 30px;

    @media screen and (max-width: $xs-up) {
      margin-top: 64px;
    }
  }

  .title-other {
    position: relative;
    margin: 0 0 15px;
    @include font-30-bold;

    @media screen and (max-width: 1024px) {
      @include font-22-bold;
    }
  }
}
