@import 'styles/main.scss';

.wrapper {
  padding: 40px 0;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .filters {
    display: flex;
    gap: 8px;

    .mobileSortIcon {
      display: flex;

      svg {
        color: #9c93aa;
      }
    }
  }
}

.title {
  @include font-32-bold;
  position: relative;
  margin-bottom: 24px;

  @media screen and (max-width: 1024px) {
    @include font-32-bold;
  }

  span {
    position: absolute;
    @include font-12-bold;
    color: var(--text-light);
  }
}

.content {
  display: flex;
  gap: 32px;

  @media screen and (max-width: 1300px) {
    gap: 24px;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: 100%;
  }
}

.left {
  width: 240px;

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.right {
  width: 1024px;
  padding-bottom: 40px;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 960px) {
    padding-bottom: 80px;
  }

  @media (min-width: 2000px) {
    width: 100%;
  }
}

.zero-favorites {
  margin-top: 100px;

  &:after {
    content: '';
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-image: var(--cart-back);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media screen and (max-width: $xs-up) {
    &:after {
      background-image: var(--cart-back-mob);
    }
  }

  @media screen and (max-width: $xs-up) {
    margin-top: 80px;
    padding-bottom: 50px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 4;
  }

  &__text {
    margin: 0;
  }

  &__title {
    margin-bottom: 16px;
    text-align: center;

    @media screen and (max-width: $xs-up) {
      h2 {
        @include font-22-bold;
      }
    }
  }

  &__subtitle {
    margin-bottom: 48px;
    text-align: center;
    @include font-14;

    @media screen and (max-width: $xs-up) {
      margin-bottom: 40px;
      h3 {
        @include font-14;
      }
    }
  }

  &__button {
    color: $white;
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    height: 372px;
    transform: rotate(-5deg);

    @media screen and (max-width: $xs-up) {
      margin-top: 40px;
      transform: scale(0.7) rotate(-5deg);
      height: unset;
    }
  }
}

.modalMobileFilters {
  overflow: -moz-scrollbars-none;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.modalMobileFilters::-webkit-scrollbar {
  display: none;
}
