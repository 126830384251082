@import 'styles/helpers/variables.scss';

.title {
  margin-bottom: 0px;
  margin-top: 0;
  @include font-22-bold;
  @media (max-width: $sm) {
    @include font-18-bold;
  }
}

.item-label {
  @include font-12-bold;
  margin-bottom: 4px;
}

.row {
  margin-bottom: 32px;

  &:first-child {
    gap: 32px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .input-field {
    position: relative;
    font-weight: 500;

    &--full {
      grid-column: span 2;
    }
  }

  .error {
    display: flex;
    position: absolute;
    color: $red;
    width: fit-content;
    right: 12px;
    white-space: nowrap;
    bottom: 8px;
    z-index: 10;

    @media screen and (max-width: 1024px) {
      bottom: 5px;
    }
  }
}

.input {
  height: 40px;
}

.subtitle {
  @include font-14-bold;
  margin-bottom: 4px;
}

.tabs {
  width: 100%;
  margin-bottom: 16px;
}

.notice {
  position: absolute;
  top: 10px;
  right: 0;
}

.button {
  @media screen and (max-width: $xs-up) {
    width: 100%;
  }
}

.inner {
  z-index: 1;
  position: relative;

  @media screen and (max-width: $xs-up) {
    display: flex;
    flex-direction: column;
  }
}

.source-wrapper {
  display: flex;
  width: 100%;
  gap: 8px;
  padding-bottom: 8px;
  position: relative;
  z-index: 1;

  .source {
    width: 100%;
  }

  .delete {
    width: 36.4px;
    height: 36.4px;
    border: 1px solid #e4e4e5;
    border-radius: 8px;
    padding: 0;
    align-self: flex-end;

    svg {
      width: 18.3px;
      height: 18.3px;
    }
  }
}

.add {
  margin-top: 8px;
  align-self: flex-start;
}

.save {
  position: absolute;
  right: 55px;
  top: 0;
  bottom: 7px;
  margin: auto;
  cursor: pointer;
}

.submit {
  margin-top: 48px;
}

.oneInput,
.selectSize {
  max-width: 600px;

  @media screen and (max-width: $xs-up) {
    max-width: 100%;
  }
}

.box {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 600px;

  @media screen and (max-width: $xs-up) {
    max-width: 100%;
  }

  .two-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    &--input {
      position: relative;
      .error {
        position: absolute;
        color: $red;
        width: fit-content;
        top: 35%;
        right: 14px;

        @media screen and (max-width: $xs-up) {
          :global(.common__tooltip-inner) {
            right: -20px !important;
          }

          ::after {
            right: -140px !important;
          }
        }
      }
    }

    .firstInput {
      position: relative;
      z-index: 2;
    }

    @media screen and (max-width: $xs-up) {
      display: flex;
      flex-direction: column;
    }
  }
}

.helperTextTooltip {
  @media screen and (max-width: $xs) {
    ::after {
      right: -30px;
    }
  }
}

.questionIcon {
  path {
    stroke: var(--grey);
  }
}

.mainInput {
  @media screen and (min-width: $xs-up) {
    z-index: 1;
  }
}
