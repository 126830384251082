@import 'styles/main.scss';

.default,
.transparent,
.filled {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px 2px 6px;
  border-radius: 24px;
  @include font-11-bold;
  margin-bottom: 2px;
  margin-top: 2px;
  height: fit-content;
}

.default {
  color: $white;
  background: var(--gradient-1);
}

.filled,
.transparent {
  color: var(--accent);
  background: transparent;
  width: fit-content;

  svg {
    path {
      stroke: var(--accent);
    }
  }
}

.filled {
  border: 1px solid var(--accent);
}

.transparent {
  padding: 0;
}

.tooltipActive {
  cursor: pointer;
}

.tooltip {
  position: relative;
  z-index: 1;

  &-bonus {
    position: absolute;
    z-index: 9999;
    right: -214px;
    top: 50%;
    width: 205px;
    color: $white;
    background-color: var(--tooltip);
    padding: 6px 12px;
    border-radius: 8px;
    @include font-12;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    transition:
      opacity 0.3s ease-in-out,
      visibility 0.3s ease-in-out;

    &::before {
      content: '';
      position: absolute;
      left: -5px;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 12px;
      background-color: var(--tooltip);
      clip-path: polygon(100% 0, 0 50%, 100% 100%);
    }
  }

  &:hover .tooltip-bonus {
    opacity: 1;
    visibility: visible;
  }
}

.tooltipAbove {
  .tooltip-bonus {
    bottom: calc(100% + 12px);
    top: auto;
    left: 50%;
    right: auto;
    transform: translateX(-50%);

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: calc(100% - 4px);
      transform: translateX(-50%) rotate(-90deg);
      width: 6px;
      height: 12px;
      background-color: var(--tooltip);
      clip-path: polygon(100% 0, 0 50%, 100% 100%);
    }
  }
}

.m {
  @include font-16-bold;
}

.sm {
  @include font-10-bold;
  line-height: normal;
}

.s {
  @include font-12-bold;
}

.icon-m {
  width: 18px;
  height: 18px;
}

.icon-s {
  width: 14px;
  height: 14px;
}

.icon-sm {
  width: 10px;
  height: 10px;
}

.link {
  color: $white;
  text-decoration: underline;
}
