@import 'styles/helpers/variables.scss';

.wrapper {
  flex-grow: 1;
  padding: 48px;
  background: var(--white);
  box-shadow: var(--fire-shadow);
  border-radius: 24px;
  max-height: 746px;
  max-width: 857px;

  @media screen and (max-width: 1024px) {
    height: 841px;
  }

  @media screen and (max-width: 700px) {
    padding: 24px 20px;
  }
}

.text {
  height: 100%;
  overflow: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  h1 {
    margin: 0px 0px 8px 0px;
    @include font-22-bold;
  }

  h2 {
    color: var(--tooltip);
    margin: 0px 0px 20px 0px;
    @include font-14;
    line-height: 140%;
  }

  h3 {
    @include font-14;
    margin: 0px 0px 12px 0px;
  }

  section {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  p {
    @include font-12;
    line-height: 140%;
    margin: 0px 0px 12px 0px;
  }

  ul {
    margin: 0px 0px 10px;
    padding: 0 20px;
    list-style: disc;
    @include font-12;
  }

  table {
    width: 100%;
    min-width: 470px;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  th,
  td {
    vertical-align: top;
    padding: 5px;
    border: 1px solid var(--text);
    text-align: left;
    width: 155px;
  }

  td {
    @include font-12;
    &:not(:first-child) {
      span {
        display: inline-block;
        margin-bottom: 8px;
      }
    }

    @media screen and (max-width: 420px) {
      @include font-10;
    }
  }

  th {
    @include font-12-bold;
    background-color: rgba(241, 75, 75, 0.1);

    @media screen and (max-width: 420px) {
      @include font-10-bold;
    }
  }

  a {
    text-decoration: none;
    color: var(--accent);
  }
}
