@import 'styles/helpers/variables.scss';

.button {
  min-height: 48px;
  width: 100%;
  margin-bottom: 16px;
}

.wrapper {
  background: #ffffff;
  box-shadow: var(--cart-shadow);
  border-radius: 24px;
  padding: 24px;
}

.title {
  @include font-18-bold;
  display: block;
  margin-bottom: 32px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  gap: 8px;
  &:last-child {
    margin-bottom: 0;
  }
  .subtitle {
    flex-shrink: 0;
    color: var(--text);
    @include font-14;
    span {
      color: #9c93aa;
      padding-left: 2px;
    }

    .tooltip {
      min-width: fit-content;
      white-space: normal;
      color: var(--text);
      display: block;
      background-color: #fff;
      font-size: 14px;
      line-height: 140%;
      box-shadow:
        0px 0px 8px rgb(0 0 0 / 10%),
        0px 4px 8px rgb(0 0 0 / 10%);
      border-radius: 8px;
      transition: 0.3s;
      width: 100%;
      text-align: center;

      * {
        white-space: normal;
      }

      :global(.common__tooltip-inner) {
        box-shadow: none;
        height: unset;
        background: transparent;
        display: block;
      }

      :global(.common__tooltip-text) {
        width: 100%;
        display: block;
        color: var(--text);
      }

      @media screen and (max-width: $sm) {
        min-width: 200px;
      }
    }

    .tooltipContainer {
      position: relative;
      display: flex;
      &_name {
        max-width: 20ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .line {
    background: var(--line-dotted);
    width: 100%;
    height: 5px;
    background-repeat: repeat-x;
    background-size: 10px auto;
    align-self: flex-end;
    margin-bottom: 2px;
  }
}

.discount {
  .cost {
    color: var(--accent);
  }
}

.subtitle {
  color: var(--text-light);
  @include font-14;
}

.box {
  margin-bottom: 16px;
}

.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.subtitleBig {
  @include font-14-bold;
}
.cost {
  @include font-14-bold;
  flex-shrink: 0;
}

.costBig {
  @include font-22-bold;
  line-height: 1;
  &--small {
    @include font-22-bold;

    @media screen and (max-width: 1024px) {
      @include font-20-bold;
    }
  }
}

.desc {
  color: var(--text-light);
  text-wrap: balance;
  @include font-11;
}

.prm {
  color: var(--accent);
  margin-top: 8px;
}

.appliedCoupon {
  margin-top: 4px;
  @include font-14-bold;
}

.disclaimer {
  font-weight: 500;
  color: var(--accent);
}

@media (max-width: 960px) {
  .wrapper {
    margin: 0 -20px;
    margin-top: 12px;
    padding: 20px;
  }

  .title {
    font-size: 15px;
    margin-bottom: 20px;
  }

  .button {
    margin-bottom: 12px;
  }
}

.attention {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 24px;
  border-radius: 12px;
  background-color: var(--ui-03-8);
  margin-top: 32px;
  max-width: 600px;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__title {
    @include font-14-bold;
  }

  &__description {
    @include font-12;
  }
}
