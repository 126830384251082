@import '~styles/main.scss';

.wrapper {
  position: relative;
  display: flex;
  align-items: baseline;

  .content {
    position: absolute;
    top: 30px;
    left: -25px;
    width: 208px;
    height: fit-content;
    background-color: $white;
    padding: 12px;
    border-radius: 12px;
    box-shadow: var(--shadow-small);
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition:
      opacity 0.3s ease-in-out,
      visibility 0.3s ease-in-out;

    &::before {
      content: '';
      position: absolute;
      left: 33px;
      top: -6px;
      transform: translateX(-50%);
      width: 12px;
      height: 6px;
      background-color: $white;
      clip-path: polygon(50% 0, 0 100%, 100% 100%);
    }
  }

  .last {
    right: -25px;
    left: auto;

    &::before {
      content: '';
      position: absolute;
      left: auto;
      right: 21px;
      top: -6px;
      transform: translateX(-50%);
      width: 12px;
      height: 6px;
      background-color: $white;
      clip-path: polygon(50% 0, 0 100%, 100% 100%);
    }
  }

  .open {
    opacity: 1;
    visibility: visible;
  }
}
