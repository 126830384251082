@import 'styles/helpers/variables.scss';

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 48px;

  .errorMessage {
    color: $red-middle;
    @include font-12();
  }
}

.isBigInputWrapper {
  height: fit-content;
}

.main-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-right: 10px;
  border-radius: 8px;
  border: 1.5px solid var(--accent-second);
  background: var(--accent-light);
  transition:
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: var(--white);
    border-color: var(--accent);
  }

  &.error {
    border-color: $red-middle;
    background: $red-light;

    input:hover {
      border-color: transparent !important;
      background: transparent !important;
    }
  }

  .drop {
    width: 100%;

    input {
      background-color: transparent;
      border: none;
      cursor: pointer;
      opacity: 1 !important;
      @include font-14;
      pointer-events: none;

      @media screen and (max-width: $xs) {
        @include font-16;
      }

      &:hover {
        border-color: $grey-middle;
        background-color: var(--accent-light);
      }

      &:focus {
        border-color: $grey-middle;
        background-color: var(--accent-light);
      }
    }
  }

  .arrow {
    display: flex;
    align-items: center;
    height: 100%;
    margin-top: 5px;
    cursor: pointer;

    svg {
      width: 13px;
      height: 8px;
    }
  }

  .arrowActive {
    margin-top: 3px;
    transform: rotate(180deg);
  }
}

.isBigInput {
  padding: 8px 8px 6px 12px;
}

// .main-input:has(.drop input:hover) {
// }

.dropActive {
  border-color: var(--accent);
  background-color: var(--white);
}

.dropdown {
  position: absolute;
  z-index: 100;
  width: 100%;
  box-shadow: var(--card-shadow);
  background-color: $white;
  border-radius: 8px;

  @media screen and (max-width: $xs) {
    box-shadow: var(--card-shadow-small);
  }

  .enter-source {
    position: relative;
    margin: 16px 16px 0;
  }

  .list {
    padding: 0;
    margin: 4px 0 4px 0;
    max-height: 158px;
    overflow: auto;
    background-color: $white;
    border-radius: 8px;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--accent-light);
      margin: 9px 0;
      border-radius: 10px;
      overflow: hidden;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--accent-third);
      margin: 9px 0;
      border-radius: 10px;
      overflow: hidden;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--accent-second);
      border-radius: 10px;
    }

    // @media screen and (max-width: $xs) {
    //   box-shadow: var(--card-shadow-small);
    // }

    .item {
      display: flex;
      gap: 6px;
      align-items: center;
      padding: 9px 16px;
      @include font-14;
      cursor: pointer;
      transition: background 0.3s linear;
      margin: 0 4px;
      border-radius: 4px;
      span {
        color: #9c93aa;
      }
      &:hover {
        background: var(--accent-light);
      }

      @media (max-width: 1024px) {
        padding: 9px 10px;
        margin: 0;
      }
    }

    .active {
      color: var(--accent);

      svg {
        fill: var(--accent);
        margin-left: auto;
      }
    }
  }
}

.selectLang {
  width: 50px;

  .main-input {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    // pointer-events: none;
  }

  input {
    border: none;
    background: transparent;
    width: 43px;
    padding-right: 0;
    padding-left: 8px !important;

    &:hover {
      background-color: transparent !important;
    }

    &:focus {
      background-color: transparent !important;
    }

    @media screen and (max-width: 1024px) {
      color: $white;
    }
  }

  .arrow {
    svg {
      path {
        fill: var(--text);

        @media screen and (max-width: 1024px) {
          fill: $white;
        }
      }
    }
  }

  .arrowActive {
    margin-bottom: 2px;

    @media screen and (max-width: 1024px) {
      margin-bottom: 4px;
    }
  }

  .dropdown {
    right: 0;
    width: 144px;

    .list {
      margin: 8px 0;
    }
  }
}

.placeholder {
  color: #9c93aa;
  pointer-events: none;
}

.ru .main-input {
  background: $white;
}

.disabled {
  opacity: 0.5;
}

.disabled .main-input,
.disabled .arrow {
  cursor: default;
}

.helper {
  position: absolute;
  color: var(--text);
  width: fit-content;
  right: 30px;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  height: 18px;
}
