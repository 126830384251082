@import "styles/main.scss";

.container {
  width: 100%;

  .header {
    padding-top: 24px;
    border-radius: 0 0 40px 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    overflow: hidden;

    &__contentBlock {
      max-width: 1344px;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      padding: 12px 24px;
      width: 100%;
      gap: 16px;
      position: relative;
      color: var(--white);

      @media screen and (max-width: $sm) {
        margin-top: 60px;
      }

      &__bg {
        position: absolute;
        top: -200px;
        right: -100px;
        width: 650px;
        height: 650px;

        @media screen and (max-width: $xs) {
          top: -250px;
          right: -250px;
          width: 600px;
          height: 600px;
        }
      }
    }

    &__back {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: var(--white);
      @include font-12-bold;
    }

    &__title {
      @include font-32-bold;
    }

    &__subtitle {
      @include font-16;
      width: 726px;
      z-index: 0;
      transition: all 0.3s ease;
      overflow: hidden;


      &.expanded {
        max-height: 500px;
      }

      &.collapsed {
        max-height: 68px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      @media screen and (max-width: $md) {
        width: 650px;
      }

      @media screen and (max-width: 1200px) {
        width: 500px;
      }

      @media screen and (max-width: $xs) {
        width: 70%;
      }
    }

    &__showMore {
      display: flex;
      align-items: center;
      @include font-14-bold;
      cursor: pointer;

      .icon {
        &_expanded {
          transform: rotate(90deg);
        }

        &_collapsed {
          transform: rotate(270deg);
        }
      }
    }

    header {
      margin: 0 auto;
      width: 100%;
    }

  }

  &__mainContent {
    max-width: 1344px;

    &_block {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 80px;
    }
  }

  .list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin: 40px 0;

    @media screen and (max-width: 1300px) {
      gap: 16px;
    }

    @media screen and (max-width: 1140px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 724px) {
      grid-template-columns: 1fr;
    }

    @media (min-width: 2000px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  .timer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    position: absolute;
    right: 20px;
    top: 100px;

    @media screen and (max-width: $xs) {
      position: unset;
    }

    &__elements {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;

      &_item {
        display: flex;
        flex-direction: row;
        width: 40px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: #FFF;
        box-shadow: 0 4px 12px 0 rgba(36, 18, 18, 0.04);
        backdrop-filter: blur(6px);
        color: var(--text-color);
        @include font-32-bold;
      }
    }
    &__period {
      color: var(--grey-2);
      text-align: center;
    }
  }
}