@import 'styles/helpers/variables.scss';

.wrapper {
  padding: 40px 0;
  position: relative;

  @media screen and (max-width: 1024px) {
    padding: 54px 0;
  }
}

.breadcrumbs {
  display: flex;
  margin-bottom: 24px;
  @include font-14;

  &__item {
    display: flex;
    align-items: center;
    margin-right: 6px;

    &::after {
      content: '';
      width: 3px;
      height: 3px;
      margin-left: 6px;
      background: var(--text);
      border-radius: 50%;
    }

    &:last-child {
      .breadcrumbs__link {
        color: var(--text-light);
      }

      &::after {
        display: none;
      }
    }
  }

  &__link {
    color: inherit;
    transition: color 0.3s;

    &:hover {
      color: var(--accent);
    }
  }
}

.project {
  &__header {
    @include font-30-bold;
    margin-bottom: 12px;
  }

  &__tabs {
    display: inline-flex;
    padding: 4px;
    border-radius: 20px;
    background: var(--accent-light);
    @include font-14;
    margin-bottom: 24px;
  }

  &__title__description {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__statusProject {
    @include font-11-extra-bold;
    padding: 4px 12px;
    border-radius: 36px;
    background: var(--accent-light);
  }

  &__tab {
    cursor: pointer;

    div {
      border-radius: 36px;
      padding: 6px 16px 5px 16px;
      display: flex;
      align-items: center;
      text-transform: capitalize;

      span {
        margin-left: 6px;
        color: var(--accent-second);
      }
    }

    input {
      display: none;
    }

    input:checked + div {
      box-shadow: var(--lil-shadow);
      background: $white;
      color: var(--accent);
    }
  }

  &__link {
    align-items: center;
    gap: 2px;
    color: var(--accent);
    display: inline-flex;
    margin-bottom: 24px;
    @include font-14;

    &.desktop {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: flex-start;

      a {
        margin: 0;
      }
    }

    svg {
      width: 18px;
      height: 18px;
    }

    a {
      color: var(--accent);
      margin-left: 5px;
    }

    .arrowIcon {
      width: 9px;
      height: 9px;
      margin-left: 5px;
      stroke-width: 1.5px;
      stroke: var(--accent);
    }
  }

  &__id {
    @include font-11-bold;
    color: var(--text-light);
  }
}

.grid {
  display: flex;
  gap: 20px;

  &__main {
    width: 100%;
    padding: 24px 24px 40px 24px;
    border-radius: 24px;
    background: $white;
    z-index: 1;
    box-shadow: var(--card-shadow);
  }

  &__side {
    width: 400px;
    flex-shrink: 0;

    &__card {
      padding: 24px;
      border-radius: 24px;
      background: $white;
      box-shadow: var(--card-shadow);
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &__top {
        display: flex;
        align-items: center;
        gap: 12px;

        svg,
        img {
          width: 48px;
          height: 48px;
          flex-shrink: 0;
          object-fit: cover;
          border-radius: 50%;
        }

        p,
        h4 {
          margin: 0;
        }
      }

      &__header {
        @include font-22-bold;

        &_mini {
          @include font-16-bold;
        }
      }

      &__par {
        @include font-12;
        color: var(--text-light);
      }

      &__btn {
        margin-top: 24px;
        padding: 12px 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--accent-light);
        border: none;
        border-radius: 80px;
        color: var(--accent);
        @include font-12-bold;

        svg {
          display: none;
        }
      }
    }
  }
}

.map {
  position: relative;
  width: 100%;

  &__link {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--accent);
    @include font-12-bold;
  }

  .step {
    width: 100%;
    position: relative;
    margin-bottom: 16px;
    margin-left: 0.5em;

    &__date {
      @include font-12;
      width: 85px;
    }

    &__block {
      margin-top: 1px;

      span {
        color: var(--text);
        font-weight: 500;
        @include font-14-bold;
      }

      p {
        margin: 0;
        margin-bottom: 6px;
        @include font-12;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .empty {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      border: 1px solid rgba(var(--text-light), 0.2);
    }

    &_empty {
      &::after {
        content: '';
        opacity: 0.2;
      }
    }

    &::after {
      content: '';
      width: 2px;
      height: calc(100% + 2px);
      position: absolute;
      top: 18px;
      left: 7.3em;
      background-color: var(--accent);
    }

    &:last-child {
      margin-bottom: 0px;

      &::after {
        content: none;
      }
    }

    .name {
      display: flex;
      gap: 8px;
      align-items: flex-start;
      @include font-14-bold;

      span {
        padding-top: 3px;
      }
    }

    .past {
      color: var(--text-light);
    }
  }
}

.invoices {
  &__cards {
    display: flex;
    gap: 15px;
    margin-bottom: 24px;
  }

  &__card {
    padding: 16px;
    background: var(--accent-light);
    border-radius: 20px;
    flex: 1 1;
    display: flex;
    flex-direction: column;

    &__header {
      @include font-12;
      color: var(--text-light);
      margin-bottom: 6px;
    }

    &__price {
      @include font-22-bold;
    }

    &__text {
      @include font-11;
      margin-bottom: 25px;
    }

    &__btn {
      margin-top: auto;
      padding: 6px 24px 6px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      background-color: var(--accent);
      border: none;
      border-radius: 80px;
      color: $white;
      align-self: flex-start;
      @include font-12-bold;
      svg {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
      }
    }

    &__pricing {
      margin-top: auto;
      display: flex;
      flex-direction: column;
      gap: 2px;
      &__item {
        display: flex;
        align-items: flex-end;
        gap: 8px;

        &__text {
          @include font-12;
          flex-shrink: 0;
        }

        &__fill {
          background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjIiIHZpZXdCb3g9IjAgMCA4IDIiIGZpbGw9Im5vbmUiPgogICAgPHBhdGggZD0iTTEgMUgyNDAuNSIgc3Ryb2tlPSIjRERDOEZGIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1kYXNoYXJyYXk9IjIgMiIgLz4KPC9zdmc+');
          width: 100%;
          height: 5px;
          background-repeat: repeat-x;
          background-size: 10px auto;
          margin-bottom: 6px;
        }
      }
    }
  }

  &__table {
    &__row,
    .mini {
      display: grid;
      grid-template-columns: 30px 300px 166px 166px 166px;
      border-bottom: 1px solid var(--accent-light);
      @include font-12;

      &:first-child {
        @include font-12-bold;
        .invoices__table__cell {
          display: flex;
          align-items: center;
          gap: 6px;

          svg {
            width: 16px;
            height: 16px;
            path {
              stroke: var(--text-light);
            }
          }

          &:first-child {
            padding: 0;
          }
        }
      }
      > div:nth-child(2) {
        display: none;
      }
    }

    &__cell {
      padding: 12px 16px;

      &:first-child {
        padding: 12px 8px;
        color: var(--text-light);
      }
    }

    &__search {
      padding: 12px 6px;
      background: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      svg {
        width: 20px;
        height: 20px;
        path {
          stroke: var(--text-light);
        }
      }
    }

    &__link {
      display: flex;
      align-items: center;
      color: var(--text);
      @include font-12;
      gap: 4px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &__header {
      @include font-12;
    }

    &__text {
      @include font-12;
      color: var(--text-light);
    }

    &__button {
      background: var(--accent);
      color: $white;
      @include font-12-bold;
      border: none;
      padding: 6px 24px 6px 20px;
      border-radius: 80px;
      &:disabled {
        opacity: 0.6;
        background: $grey;
        color: var(--text-light);
        cursor: default;
      }
    }
  }
}

.goals {
  &__main {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    min-height: 128px;
    padding: 12px;
    gap: 8px;
    margin-bottom: 24px;
    border-radius: 20px;
    background: var(--border-dotted);
    box-shadow: var(--top-shadow);
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 4px 12px;
    gap: 4px;
    border-radius: 36px;
    border: 1px solid var(--accent);
    @include font-12;
    div[contenteditable='true'] {
      @include font-12;
      min-width: 1ch;
    }
    svg {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }

    &_active {
      svg {
        transform: rotate(45deg);
      }
    }

    &_add {
      background: var(--accent-light);
      border: 1px solid transparent;
      color: var(--accent);
      cursor: pointer;

      div[contenteditable='true'] {
        display: none;
      }
      svg {
        display: none;
      }
    }
  }

  &__bottom_text {
    @include font-12;
    color: var(--text-light);
    margin-bottom: 12px;
  }

  &__bottom {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
  }
}

@media (max-width: 1024px) {
  .breadcrumbs {
    @include font-14;
  }

  .project {
    &__header {
      @include font-22-bold;
      margin-bottom: 4px;
    }
    &__top {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;
      margin-bottom: 24px;
    }
    &__id {
      margin-bottom: 0;
      @include font-12-bold;
      position: relative;
      display: flex;
      align-items: center;

      &__source {
        &::after {
          content: '';
          width: 4px;
          height: 4px;
          background: var(--text-light);
          display: block;
          margin: 0 6px;
          border-radius: 50%;
        }
      }
    }
    &__link {
      padding-bottom: 20px;
      margin-bottom: 0;
      @include font-12;

      a {
        margin: 0;
      }
    }
    &__tabs {
      @include font-12;
      margin-bottom: 20px;
    }
  }

  .grid {
    flex-direction: column-reverse;
    gap: 16px;
    padding-bottom: 24px;
    margin: 0 -20px;
    &__side {
      border-radius: 24px;
      background: $white;
      padding: 20px;
      width: 100%;
      box-shadow: var(--card-shadow);
      &__card {
        margin-bottom: 12px;
        background: var(--accent-light);
        box-shadow: none;
        padding: 16px 20px;
        &:last-child {
          display: flex;
          justify-content: space-between;
        }
        &__header {
          font-size: 18px;
        }
        &__btn {
          display: flex;
          align-items: center;
          width: 48px;
          height: 48px;
          padding: 0;
          background-color: $white;
          margin-top: 0;
          span {
            display: none;
          }
          svg {
            display: block;
          }
        }
      }
    }
    &__main {
      padding: 24px 20px;
      width: 100%;
    }
  }

  .map {
    .step {
      &__date {
        max-width: 53px;
        margin-right: 8px;
        flex-shrink: 0;
      }
      svg {
        flex-shrink: 0;
      }
      &::after {
        left: 5.55em;
      }
    }
  }

  .invoices {
    &__cards {
      flex-direction: column;
      // margin-bottom: 20px;
      gap: 12px;
    }
    &__card {
      &__pricing {
        margin-top: 24px;
      }
      &__text {
        margin-bottom: 24px;
      }
    }
    &__table {
      padding: 8px 0 0 0;
      &__row,
      .mini {
        padding: 0 8px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border-bottom: 1px solid #ddc8ff;
        &:first-child {
          display: none;
        }
        &:last-child {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
      &__cell {
        padding: 0;
        &:nth-child(1) {
          display: none;
        }
        &:nth-child(2) {
          order: 2;
          width: 100%;
          margin-bottom: 24px;
        }
        &:nth-child(3) {
          order: 1;
          display: flex;
          align-items: center;
          gap: 0.6em;
          width: 100%;
          margin-bottom: 12px;
          line-height: 140%;
        }
        &:nth-child(4) {
          order: 3;
          .invoices__table__header {
            @include font-22-bold;
            color: var(--text);
            line-height: 120%;
          }
        }
        &:nth-child(5) {
          order: 4;
          margin-left: auto;
        }
      }
      &__header {
        @include font-12;
        color: var(--text-light);
      }
      &__link {
        font-weight: 500;
        margin-bottom: 2px;
      }
      &__text {
        line-height: 1.4;
      }
    }
  }

  .goals {
    &__main {
      margin-bottom: 20px;
    }
  }
}
.pagination {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 960px) {
    margin-top: 16px;
  }
}

.no_manager {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .no_manager_description,
  .no_manager_title {
    margin: 0;
  }

  .no_manager_title {
    @include font-16-bold;
  }

  .no_manager_description {
    @include font-12;
    color: var(--text-light);
  }
}

.dot {
  width: 4px;
  height: 4px;
  background-color: var(--grey-2);
  border-radius: 100%;
}

.arrowIconSource {
  margin-left: -2px !important;
}

.ACTIVE {
  color: var(--success-16);
  background-color: rgba(var(--success-16-rgb), 0.08);
}

.COMPLETED {
  color: var(--ui-03-main);
  background-color: rgba(var(--ui-03-main-rgb), 0.08);
}

.CANCELLED {
  color: var(--error-main);
  background-color: rgba(var(--error-main-rgb), 0.08);
}

.PAUSED {
  color: var(--warning-main);
  background-color: rgba(var(--warning-main-rgb), 0.08);
}
