@import 'styles/helpers/variables.scss';

.selectBox {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    @include font-16();
    line-height: 125%;
    color: #9c93aa;
    padding-right: 10px;
  }

  .wrapper {
    position: relative;
    height: 40px;
    overflow: unset;
    width: 230px;

    .main-input {
      display: flex;
      align-items: center;
      height: 100%;
      padding-right: 12px;
      border-radius: 20px;
      border: 1px solid var(--accent-second);

      &.error {
        border-color: $red-middle;
        background: $red-light;

        input:hover {
          border-color: transparent !important;
          background: transparent !important;
        }
      }

      .drop {
        width: 100%;

        input {
          background-color: transparent;
          border: none;
          cursor: pointer;
          opacity: 1 !important;
          height: 100%;
          padding: 9px 8px 9px 16px;
          text-overflow: ellipsis;

          @media (max-width: 1024px) {
            padding: 8px 8px 6px 12px;
          }
        }
      }

      .arrow {
        display: flex;
        align-items: center;
        height: 100%;
        margin-top: 2px;
        cursor: pointer;

        svg {
          width: 13px;
          height: 8px;
        }
      }

      .arrowActive {
        margin: 0 0 2px 0;
        transform: rotate(180deg);
      }
    }
  }
}

.dropActive {
  border-color: var(--accent);
}

.dropdown {
  position: relative;
  z-index: 100;
  width: 100%;
  background-color: $white;
  box-shadow: var(--card-shadow);
  border-radius: 20px;
  border: 1px solid var(--accent-second);
  overflow: hidden;
  height: 216px;
  margin-top: 10px;

  .enter-source {
    position: relative;
    margin: 16px 16px 0;
  }

  .list {
    padding: 0;
    margin: 4px 0;
    max-height: 100%;
    overflow-y: auto;
    position: absolute;
    top: 0;
    right: 6px;
    left: 0;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--accent-third);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--accent-second);
    }

    .item {
      display: flex;
      gap: 6px;
      align-items: center;
      padding: 6px 16px;
      @include font-14();
      line-height: 135%;
      color: #323236;
      cursor: pointer;
      margin: 4px;
      border-radius: 20px;
      transition: background 0.3s linear;
      span {
        color: #323236;
      }
      &:hover {
        background: var(--accent-light);
      }
      @media (max-width: 1024px) {
        padding: 9px 10px;
        margin: 0;
      }
    }

    .active {
      color: var(--accent);
      background: var(--accent-third);
      display: flex;
      justify-content: space-between;

      .checkIcon {
        display: flex;
        align-items: center;
        padding-top: 4px;
        svg {
          fill: var(--accent);
        }
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0d09188f;
  z-index: 9998;
  animation: fadeIn 0.3s ease-out;
}

.overlay.close {
  animation: fadeOut 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fixedButton {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  border-radius: 24px 24px 0 0;
  width: 100%;
  height: auto;
  padding: 32px 20px 90px 20px;
  z-index: 9998;
  animation: slideUp 0.3s ease-out;

  .title {
    @include font-20-bold();
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    svg {
      width: 15px;
      height: 14px;
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    .button {
      display: flex;
      border-radius: 20px;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      height: 22px;
      padding: 20px 15px;
      text-align: left;
      justify-content: space-between;
      color: #323236;

      &:hover {
        background: var(--accent-light);
      }

      &.active {
        background: var(--accent-light);

        .checkIcon {
          display: flex;
          margin-top: 4px;
          width: 20px;
          height: 20px;

          svg {
            fill: var(--accent);
          }
        }
      }
    }
  }

  &.close {
    animation: slideDown 0.3s ease-out;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}
