@import 'styles/helpers/variables.scss';

.cards {
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  background: $white;
  box-shadow: var(--cart-shadow);
}

.list {
  display: block;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 24px;
  box-shadow: var(--cart-shadow);
  padding: 16px 24px;
  margin-bottom: 16px;

  &__reset {
    color: var(--text-light);
    background: none;
    padding: 0;
    margin: 0;
    border: none;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }

  .checkbox {
    padding: 4px;
  }
}

@media (max-width: 1024px) {
  .cards {
    margin: 0 -20px;
  }
  .top {
    margin: 0 -20px;
    margin-bottom: 12px;
    border-radius: 24px;
    padding: 16px 20px;
  }
}
