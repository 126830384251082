@import '~styles/main.scss';

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;

  .item {
    display: flex;
    align-items: center;
    gap: 8px;
    text-align: left;
    @include font-12;
    cursor: pointer;

    .down {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .item__last {
    .search__input {
      input {
        padding: 8px 12px;
      }
    }

    .search__description {
      border-top: 1px solid var(--accent-light);
      padding-top: 8px;
      margin-top: 0;
      margin-bottom: 4px;
      text-align: left;
      @include font-11-bold;
      color: var(--text-light);
    }
  }
}
