@import 'styles/helpers/variables.scss';

.stand {
  height: 365px;
  padding: 32px;
  border-radius: 24px;
  background: var(--white);
  box-shadow: var(--fire-shadow);

  @media screen and (max-width: 1024px) {
    margin-bottom: 56px;
  }

  @media screen and (max-width: 480px) {
    padding: 20px;
  }

  &__title {
    @include font-22-bold;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90%;

    &-title {
      @include font-22-bold;
    }

    &-description {
      text-align: center;
      margin-top: 12px;
    }

    &-button {
      margin-top: 24px;
      padding: 12px 24px;
    }
  }
}
