.buttons {
    &__container {
        &-list {
            display: flex;
            flex-direction: column;
        }

        &-item {
            width: 100%;
            margin-bottom: 40px;

            &-list {
                display: flex;
                margin-bottom: 40px;


                &-item {
                    width: 100%;
                    max-width: calc(100%/3 - 20px);
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }
    }
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    @include font-14-bold;
    line-height: unset;
    padding: 0px 32px;
    border-radius: 80px;
    transition: .3s;
    border: none;
    min-height: 48px;

    span {
        position: relative;
        top: 2px;
    }

    &--default {
        background: #F7F5FF;
        color: var(--accent);

        &:hover {
            opacity: 0.7;
        }

        &:focus {
            opacity: 0.9; 
        }

        &:disabled {
            background-color: #F7F5FF;
            color: #8362F3;
            cursor: default;
            opacity: 0.5;

            &:hover {
                background-color: #F7F5FF;
                color: #8362F3;
            }
        }
    }

    &--primary {
        background-color: var(--accent);
        color: $white;

        &:hover {
            opacity: 0.7;
        }

        &:focus {
            opacity: 0.9;
        }

        &:disabled {
            background-color: $grey;
            color: var(--text-light);
            opacity: 0.6;
            cursor: default;

            svg {
                fill: var(--text-light);
            }
        }
    }

    &--text {
        background-color: transparent;
        color: var(--accent);
        padding: 0;
        min-height: 0;
        @include font-14-bold;

        &:hover {
            opacity: 0.7;
        }

        &:focus {
            opacity: 0.9;
        }

        &:disabled {
            opacity: 0.5;            
            cursor: default;
        }

        &.btn--small {
            padding: 0;
            @include font-12;
            font-weight: 500;
        }
    }

    &--icon {
        display: flex;
        align-items: center;

        &-right {
            flex-direction: row-reverse;

            .common__icon {
                margin-left: 13px;
            }
        }

        &-left {
            flex-direction: row;

            .common__icon {
                margin-right: 13px;
            }
        }
    }

    &--middle {
        padding: 9px 32px;
        font-size: 16px;
        min-height: 40px;
    }

    &--small {
        padding: 6px 24px;
        font-size: 14px;
        min-height: 32px;
    }

    &--icon-default {
        background-color: var(--accent-light);
        padding: 12px;
        border-radius: 50%;
        min-height: unset;

        &:hover {
            opacity: 0.7;
        }

        &:disabled {
            opacity: 0.5;
        }

        &.btn--small {
            padding: 10px;
        }
    }

    &--icon-primary {
        background-color: var(--accent);
        padding: 12px;
        border-radius: 50%;
        min-height: unset;

        &:hover {
            opacity: 0.7;
        }

        &:disabled {
            opacity: 0.5;
        }

        &.btn--small {
            padding: 10px;
        }

        @media screen and (max-width: $sm) {
            padding: 5px;
        }
    }

    &--tab {
        background-color: transparent;
        padding: 0;
        min-height: unset;
    }

    &--test {
        background-color: $blue-dark;
        
        .btn__label--test {
            color: $white;
            margin-right: 20px;
        }

        img {
            margin-right: 10px;
        }
    }

    &--search {
        padding: 0;
        background: transparent;
        min-height: unset; 
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        left: 0;
        transition: $transition;
        transition-delay: $transition;
    }
}