@import 'styles/main.scss';

.container {
  @media screen and (max-height: 750px) {
    height: 100dvh;
    margin-top: 25px;
    padding-bottom: 80px;
  }
}

.form {
  z-index: 1;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 508px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: $xs) {
    padding: 24px 20px;
    width: calc(100% - 32px);
  }

  @media screen and (max-height: 750px) {
    position: relative;
    overflow-y: auto;
    height: 100%;
    justify-content: flex-start;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  :global(.common__tooltip) {
    min-width: 150px !important;
  }

  .headerWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;

    .headerTitle {
      text-align: center;
      @include font-28-bold;
      margin: 0;
    }

    .headerDescription {
      margin: 0;
      text-align: center;
      @include font-14;
    }
  }

  .top-fields {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 24px;
    column-gap: 16px;
    margin-bottom: 24px;

    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: column;
    }
  }

  .email {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .goals {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title {
      @include font-12-bold;
    }
  }

  .check {
    margin-bottom: 32px;

    @media screen and (max-width: 1024px) {
      margin-bottom: 32px;
    }
  }

  .bottom-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    margin-bottom: 32px;

    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      column-gap: 16px;
    }
  }

  .submit {
    display: flex;
    align-items: center;
    max-height: 40px;
  }

  .submit-button {
    display: inline-flex;
    height: 40px;
    width: 100%;
  }

  .button {
    svg {
      fill: var(--accent);
    }
  }

  .text {
    @include font-14;
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;

    span {
      margin-right: 4px;
    }

    .link {
      margin-right: 4px;
      color: var(--accent);
    }
  }

  .tabs {
    width: 100%;
    margin-bottom: 16px;
  }

  .comment {
    margin-bottom: 28px;
  }

  .input-field {
    position: relative;
    @include font-12-bold;
    display: flex;
    flex-direction: column;
    gap: 4px;

    &--full {
      height: fit-content;
    }
  }

  .error {
    position: absolute;
    color: $red;
    width: fit-content;
    right: 12px;
    white-space: nowrap;
    top: 50%;
    transform: translate(0%, -50%);
    z-index: 10;
    height: 18px;

    @media screen and (max-width: 1024px) {
      bottom: calc(1em - 4px);
    }
  }

  .captcha {
    position: relative;
    margin-top: 32px;
    margin-bottom: 30px;

    @media screen and (max-width: 1024px) {
      margin-top: 28px;
      margin-bottom: 22px;
    }
  }

  .checkbox-block {
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
