@import 'styles/helpers/variables.scss';

.wrapper {
  padding: 32px 0;

  @media (max-width: 1024px) {
    padding: 46px 0;
    margin-bottom: 26px;
  }

  @media screen and (max-width: 480px) {
    margin: 36px -20px;
  }
}

.breadcrumbs {
  margin-bottom: 24px;

  @media screen and (max-width: 480px) {
    margin-left: 16px;
  }
}

.content {
  display: flex;
  gap: 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.announceBlocks {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  height: max-content;

  @media (max-width: 1024px) {
    flex-direction: row;
  }
}

.map {
  padding: 40px;

  @media screen and (max-width: 640px) {
    padding: 20px;
  }

  span {
    display: block;
  }

  &__title {
    @include font-22-bold;
    margin-bottom: 8px;

    @media screen and (max-width: 640px) {
      @include font-16-bold;
      text-align: center;
    }
  }

  &__address {
    line-height: 140%;
    margin-bottom: 24px;
  }

  &__container {
    height: 500px;
    width: 800px;

    @media screen and (max-width: 1024px) {
      height: 442px;
      width: 520px;
    }

    @media screen and (max-width: 740px) {
      height: 320px;
      width: 420px;
    }

    @media screen and (max-width: 640px) {
      height: 332px;
      width: 100%;
    }
  }
}
