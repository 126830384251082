@import '~styles/main.scss';

.empty {
  padding: 40px 0 60px 0;
  border-radius: 36px;
  background: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__header {
    margin-bottom: 16px;
    @include font-26-bold;
  }

  &__text {
    margin-bottom: 40px;
  }

  &__back {
    border-radius: 80px;
    background: var(--accent);
    padding: 9px 32px;
    @include font-14;
    font-weight: 500;
    color: $white;
  }

  @media (max-width: 960px) {
    margin: 0 -20px;
    padding: 66px 20px;

    &__header {
      margin-bottom: 8px;
      @include font-22-bold;
    }

    &__text {
      margin-bottom: 32px;
      @include font-12;
    }
  }
}

.table {
  overflow-x: auto;

  tr {
    grid-template-columns: 30px 180px 144px 180px 156px 144px;
  }
}
