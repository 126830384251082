@import 'styles/helpers/variables.scss';

.inner {
  min-width: 100%;
  padding: 40px;
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (min-width: $xs-up) {
    min-width: 583px;
    height: unset;
    flex-direction: column;
    gap: 24px;
  }

  .header {
    .title {
      @include font-22-bold;
      margin: 0;

      @media screen and (max-width: $xs) {
        @include font-18-bold;
      }
    }

    .title-description {
      @include font-14;
      color: var(--text-light);
      margin: 0;

      @media screen and (max-width: $xs) {
        @include font-12;
      }
    }
  }

  .input-description {
    @include font-14;
    color: var(--text-light);
    margin: 4px 0 0 0;
  }

  .btn {
    width: 100%;
  }
}

.input {
  border-color: transparent;

  input {
    background-color: var(--accent-light);

    &:hover {
      border-color: var(--accent-color);
    }

    &:focus {
      border-color: var(--accent);
    }
  }
}
