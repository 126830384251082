@import 'styles/helpers/variables.scss';

.wrapper {
  @media screen and (max-width: 480px) {
    padding: 0 16px;
  }

  .header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    &__title {
      @include font-22-bold;
    }

    &__more {
      color: var(--accent);
      @include font-14;
      visibility: hidden;
    }
  }

  .list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    position: relative;
    border-radius: 24px;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (max-width: 1070px) {
      gap: 8px;
    }

    @media screen and (max-width: 1045px) {
      overflow-x: auto;
      box-shadow: var(--fire-shadow);
      margin: 0 -20px;
    }

    @media screen and (max-width: 480px) {
      grid-template-columns: 1fr;
      padding: 0 16px;
      overflow: visible;
      box-shadow: none;
    }
  }
}
