.tag {
    color: var(--accent);
    padding: 9px 12px 7px;
    background-color: var(--accent-light);
    border-radius: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $xs) {
        padding: 6px 10px 4px;
    }

    &__text {
        @include font-12-bold;
        text-transform: uppercase;
        position: relative;
        top: 1px;

        @media screen and (max-width: $xs) {
            @include font-10-bold-upper;
        }
    }

    &--red {
        background-color: #F96D58;

        .tag__text {
            color: $white;
        }
    }

    &--green {
        background-color: var(--ui-02-main);

        .tag__text {
            color: $white;
        }
    }

    &--purple {
        background-color: var(--accent);

        .tag__text {
            color: $white;
        }
    }

    &--blue {
        background-color: var(--ui-04-main);

        .tag__text {
            color: $white;
        }
    }

    &--small {
        padding: 5px 10px 5px;

        .tag__text {
            @include font-10-bold-upper;
        }
    }

    &__icon {
        margin-right: 7px;
    }
}