@import 'styles/helpers/variables.scss';

.clientPreview {
  display: flex;
  height: 243px;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 24px;
  background: var(--white);
  box-shadow: var(--fire-shadow);

  @media screen and (max-width: 1024px) {
    grid-column: 1 / -1;
    height: 194px;
    align-items: flex-start;
  }

  @media screen and (max-width: 620px) {
    align-items: unset;
  }

  &__header {
    @include font-12;
    span {
      display: block;
    }
  }

  &__title {
    color: var(--text-light);
    margin-bottom: 12px;
  }

  &__name {
    @include font-20-bold;
    line-height: 140%;
    margin-bottom: 4px;
  }

  &__description {
    overflow: hidden;
    text-overflow: ellipsis;
    @include font-12;
    line-height: 140%;
    max-height: 34px;
    margin: 0;
  }

  &__button {
    background-color: var(--accent-light) !important;
    padding: 12px 24px;
  }
}
