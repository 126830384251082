@import 'styles/helpers/variables.scss';

.wrapper {
  position: relative;
  padding: 40px 0;

  @media screen and (max-width: 1024px) {
    padding: 54px 0;
  }
}

.container {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 960px) {
    padding: 0 0 54px;
  }
}

.back {
  margin-bottom: 24px;
  @include font-12-bold;

  a {
    display: inline-flex;
    align-items: center;
    padding: 8px 24px 8px 0;
    gap: 8px;
    color: var(--accent);
    line-height: 1.5;
    svg {
      fill: var(--accent);
    }
  }
}

.tabs {
  justify-content: center;
}

.title {
  @include font-32-bold;
  position: relative;

  @media screen and (max-width: 1024px) {
    @include font-32-bold;
  }
}

.search-term {
  color: #898989;
}

.tab-name {
  @include font-22-bold;
  margin-top: 32px;
  margin-bottom: 16px;
}

.list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  &.projectList {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 1140px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 724px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 2000px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.item-orders {
  @media screen and (max-width: 450px) {
    max-width: calc(100% - 40px);
  }

  @media screen and (max-width: 375px) {
    max-width: calc(100% - 60px);
  }
}

.no-results {
  @include font-14;
  color: #898989;
}

.pagination {
  width: 100%;
  margin: 32px 0 0 0;
  display: flex;
  gap: 40px;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .button {
    height: 32px;
  }
}
