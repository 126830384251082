@import 'styles/main.scss';

.container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;

  .logo {
    position: absolute;
    width: 60px;
    height: 40px;
    top: 48px;
    left: 48px;
    z-index: 10;

    &_ru {
      height: 24px;
      width: auto;
    }

    @media screen and (max-width: 1024px) {
      width: 40px;
      height: 26px;
      top: 16px;
      left: 16px;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: 100%;
  }
}

.left {
  position: relative;
  width: 43%;
  background: var(--gradient-2);

  @media screen and (max-width: 1024px) {
    z-index: -1;
    width: 100%;
    min-height: 288px;
    padding-top: 30px;
  }

  .image {
    object-fit: cover;
    width: 100%;
  }

  .success {
    object-fit: contain;
    width: 441px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 100%;

    @media screen and (max-width: 1024px) {
      width: 246px;
    }
  }
}

.right {
  width: 57%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 156px;

  @media screen and (max-width: 1270px) {
    padding: 0 80px;
  }

  @media screen and (max-width: 1024px) {
    z-index: 1;
    width: 100%;
    padding: 24px 16px;
    margin-top: -28px;
    border-radius: 28px 28px 0px 0px;
    background-color: white;
  }

  @media screen and (min-width: 1024px) {
    position: relative;
  }

  .steps {
    color: var(--text-light);
    text-transform: uppercase;
    margin-bottom: 20px;
    @include font-12-bold;
  }

  .title {
    color: var(--text);
    margin-bottom: 4px;
    @include font-28-bold;

    @media screen and (max-width: 1024px) {
      @include font-22-bold;
    }
  }

  .text {
    @include font-14;
    margin-bottom: 32px;

    span {
      display: flex;
    }

    .link {
      margin-left: 8px;
      @include font-14-bold;

      a {
        color: var(--accent);
      }
    }
  }
}

.lang {
  position: absolute;
  top: 48px;
  right: 48px;
  z-index: 500;

  @media screen and (max-width: 1024px) {
    top: 16px;
    right: 16px;
  }
}

.backBtn {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 20px;

  .step2 {
    margin-bottom: 0;
  }

  .arrow {
    cursor: pointer;
  }
}

.invitation {
  padding: 4px 12px;
  border-radius: 36px;
  @include font-11-bold;
  color: var(--accent);
  background-color: var(--accent-light);
  margin-bottom: 20px;
  width: fit-content;
}

.highlight {
  font-weight: bold;
  background: var(--gradient-1);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
