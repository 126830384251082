@import 'styles/helpers/variables.scss';

.map {
  position: relative;
  width: 100%;
  margin-bottom: 35px;

  .loader div {
    height: 215px;
  }

  @media screen and (max-width: $sm) {
    position: relative;
    box-shadow: none;
    width: 100%;
    left: auto;
    background-color: var(--accent-light);

    .loader div {
      height: 170px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--accent);
    z-index: 1;
  }

  .step {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 16px;
    font-weight: 500;

    .empty {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      border: 1px solid rgba(var(--text-light), 0.2);
      font-weight: 400;
      &::after {
        background-color: rgba(var(--text-light), 0.2);
      }
    }

    &::after {
      content: '';
      width: 2px;
      height: 100%;
      position: absolute;
      bottom: -17px;
      left: 9px;
      background-color: var(--accent);
    }

    &:last-child {
      margin-bottom: 0px;

      &::after {
        content: none;
      }
    }

    .name {
      display: flex;
      gap: 8px;
      align-items: flex-start;
      @include font-12;

      > svg {
        fill: var(--accent);
        width: 20px;
        height: 20px;
      }

      &__main {
        display: flex;
        flex-direction: column;
        padding-top: 3px;
        svg {
          stroke: var(--accent);
        }
      }
    }

    .past {
      font-weight: 500;
    }

    .date {
      align-self: flex-start;
      color: var(--text-light);
      @include font-11;
      position: relative;
      cursor: default;
      &::after {
        position: absolute;
        bottom: -25px;
        left: 0;
        right: 0;
        margin: 0 auto;
        content: attr(title);
        border-radius: 8px;
        border: 1px solid #f2ecff;
        background: $white;
        box-shadow:
          0px 15px 125px 0px rgba(201, 180, 250, 0.13),
          0px 4.522px 37.684px 0px rgba(201, 180, 250, 0.12);
        padding: 4px 8px;
        white-space: nowrap;
        width: max-content;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s;
        font-size: 11px;
        line-height: 130%;
        color: var(--text);
      }
      &:hover::after {
        opacity: 1;
      }

      @media (max-width: 1024px) {
        @include font-11;
      }
    }
  }

  .empty,
  .waited {
    &::after {
      background-color: var(--text-light);
      opacity: 0.2;
    }
  }
}

.buttonContainer {
  width: 100%;
  padding: 90px 0 10px 0;
  position: absolute;
  bottom: -20%;
  background: var(--projects-shadow);

  button {
    background-color: transparent !important;
    border-radius: unset;
    padding: 0;
    @include font-12-bold;
  }

  .mobileContent {
    padding-top: 7px;
    background: var(--accent-light);
  }

  .content {
    padding-top: 7px;
    background: var(--accent-light);
  }
}

.tooltipContainer {
  position: relative;
  top: -2px;
  margin-left: 6px;
}

.tooltip {
  position: absolute;
  padding: 0;
  white-space: nowrap;
  pointer-events: none;
  font-size: 11px;
  line-height: 130%;
  color: var(--text);
  width: fit-content;
  right: 17.67px;
  bottom: 3.67px;
  z-index: 10;
  transform: translate(-20%, 60%) !important;
  transition: 0.3s;

  @media (max-width: 1024px) {
    transform: translate(70%, 60%) !important;
  }

  .tooltipInner {
    width: fit-content;
    font-size: 11px;
    line-height: 130%;
    color: #323236;
    font-weight: 400;
    padding: 4px 8px;
    transition: opacity 0.3s;

    &::after {
      display: none;
    }
  }
}

.map__name {
  color: var(--accent);
  @include font-12-bold;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: $netbook) {
    max-width: 180px;
  }

  @media screen and (max-width: $md) {
    max-width: 170px;
  }

  @media screen and (max-width: 1230px) {
    max-width: 160px;
  }

  @media screen and (max-width: 1200px) {
    max-width: 150px;
  }

  @media screen and (max-width: 1170px) {
    max-width: 140px;
  }

  @media screen and (max-width: 1140px) {
    max-width: 130px;
  }

  @media screen and (max-width: 1110px) {
    max-width: 120px;
  }

  @media screen and (max-width: 1090px) {
    max-width: 110px;
  }

  @media screen and (max-width: $sm) {
    max-width: 200px;
  }

  @media screen and (max-width: 450px) {
    max-width: 150px;
  }

  @media screen and (max-width: 400px) {
    max-width: 130px;
  }
}
