@import 'styles/helpers/variables.scss';

.map {
  padding: 40px;

  @media screen and (max-width: 640px) {
    padding: 20px;
  }

  span {
    display: block;
  }

  &__title {
    @include font-22-bold;
    margin-bottom: 8px;

    @media screen and (max-width: 640px) {
      @include font-16-bold;
      text-align: center;
      margin-bottom: 16px;
    }
  }

  &__address {
    line-height: 140%;
    margin-bottom: 24px;

    @media screen and (max-width: 640px) {
      margin-bottom: 16px;
    }
  }

  &__container {
    height: 500px;
    width: 800px;

    @media screen and (max-width: 1024px) {
      height: 442px;
      width: 520px;
    }

    @media screen and (max-width: 740px) {
      height: 320px;
      width: 420px;
    }

    @media screen and (max-width: 640px) {
      height: 332px;
      width: 100%;
    }
  }
}
