@import 'styles/helpers/variables.scss';

.inner {
  min-width: 100%;
  padding: 32px 20px;
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (min-width: $xs-up) {
    padding: 40px;
    min-width: 583px;
    height: unset;
    flex-direction: column;
    gap: 24px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
      @include font-22-bold;
      margin: 0;

      @media screen and (max-width: $xs) {
        @include font-18-bold;
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .listItem {
      cursor: pointer;
    }

    .listItemActive {
      color: var(--accent);
    }
  }
}
