@import 'styles/helpers/variables.scss';

.box {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.titleWrap {
  margin-bottom: 8px;
}

.title {
  margin-bottom: 0px;
  margin-top: 0;
  @include font-22-bold;
}

.row {
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: $xs-up) {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.item {
  @media screen and (max-width: $xs-up) {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.field {
  position: relative;
}

.error {
  position: absolute;
  color: $red;
  width: fit-content;
  right: 12px;
  white-space: nowrap;
  bottom: 50%;
  transform: translateY(50%);
  z-index: 10;
}

.notice {
  position: absolute;
  top: 10px;
  right: 0;
}

.submit {
  margin-top: 48px;
}

.button {
  @media screen and (max-width: $xs-up) {
    width: 100%;
  }
}

.inner {
  @media screen and (max-width: $xs-up) {
    display: flex;
    flex-direction: column;
  }
}

.wrapper {
  @media screen and (max-width: $xs-up) {
    height: 100%;
  }
}

.info {
  margin: 0 0 24px;
  @include font-14();

  &__link {
    color: var(--accent);
    transition: all ease-in-out 0.3;

    &:hover {
      text-decoration: underline;
    }
  }
}

.attention {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 24px;
  border-radius: 12px;
  background-color: var(--ui-03-8);
  margin-bottom: 24px;
  max-width: 600px;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__title {
    @include font-14-bold;
  }

  &__description {
    @include font-12;
  }

  &__link {
    cursor: pointer;
    color: var(--accent);
  }
}

.groupRow {
  display: flex;
  align-items: flex-start;
  gap: 12px;

  @media screen and (max-width: $xs-up) {
    flex-direction: column;

    .groupField {
      width: 100%;
    }
  }

  .groupField {
    position: relative;
  }

  .input {
    width: 294px;

    @media screen and (max-width: $xs-up) {
      width: 100%;
    }
  }
}
