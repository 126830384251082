@import 'styles/main.scss';

.wrapper {
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 40px 0 0;

  @media screen and (max-width: 960px) {
    padding: 54px 0;
  }
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .filters {
    display: flex;
    gap: 8px;
  }
}

.title {
  position: relative;
  margin-bottom: 24px;
  @include font-32-bold;

  @media screen and (max-width: 1024px) {
    margin-bottom: 16px;
    @include font-32-bold;
  }

  span {
    position: absolute;
    @include font-12-bold;
    color: var(--text-light);
  }
}

.content {
  display: flex;
  gap: 32px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.left {
  padding-right: 12px;
  @media (max-width: 960px) {
    padding-right: 0;
  }
}

.right {
  @media (max-width: 960px) {
    margin-top: 0;
  }
}

.orderDetails {
  display: block;
}

.sticky {
  position: sticky;
  top: 20px;
}

.modalContainer {
  padding: 40px;
}

.modalInner {
  margin-bottom: 40px;
}

.modalTitle {
  @include font-22-bold;
  display: block;
  margin-bottom: 8px;
}

.modalText {
  margin: 0;
  @include font-14;
}

.buttonModalWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    width: 100%;
  }
}

.buttonModal {
  margin-right: 8px;
  height: 40px;
  width: 100%;

  &:last-child {
    margin-right: 0;
  }
}

.innerDeals {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 94px);
  padding-bottom: 50px;

  @media screen and (min-width: $xs-up) {
    display: block;
    min-height: auto;
    height: unset;
    padding-bottom: 0;
  }
}

.itemDeals {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.modal {
  .header {
    position: static;
  }
}

.gradient {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 94px);
  padding: 34px;

  &:after {
    content: '';
    position: absolute;
    width: 100vw;
    min-height: 100vh;
    background-image: var(--back-shadow-grad);
    background-repeat: no-repeat;
    background-size: cover;
    left: -10px;
    top: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
  }

  @media screen and (min-width: $xs-up) {
    width: 30vw;
    max-width: 1047px;
    height: unset;
    min-height: 300px;
    padding: 40px;
    border-radius: 30px;
    background-image: var(--back-shadow-grad);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;

    &::after {
      content: none;
    }
  }
}

.boxDeals {
  margin-bottom: 20px;
}

.iconDeals {
  width: 96px;
  height: 96px;
  margin: 15px auto 26px;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.titleDeals {
  margin: 0 0 9px;
  text-align: center;
  @include font-20-bold();

  @media screen and (min-width: $xs-up) {
    @include font-22-bold();
  }
}

.textDeals {
  text-align: center;
  margin-bottom: 40px;
}

.textItemDeals {
  margin: 0;
}

.btnDeals {
  display: flex;
  justify-content: center;
  margin-top: auto;

  @media screen and (min-width: $xs-up) {
    margin-top: 0;
  }
}

.btnSuccessDeals {
  display: flex;
  justify-content: center;
}

.recommended {
  margin: auto 0 0;
}

.hint {
  /*change by react */
  opacity: 0;
  pointer-events: none;
  z-index: 99;
  /*change by react */

  background: rgba(13, 9, 24, 0.56);
  backdrop-filter: blur(1.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  &__wrapper {
    background: $white;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border-radius: 20px;
    max-width: 287px;
    text-align: center;
  }
  &__text {
    @include font-14;
  }
  &__btn {
    padding: 9px 32px;
  }
}

.fixedButton {
  position: fixed;
  bottom: 50px;
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 24px 24px 0 0;
  width: 100%;
  height: auto;
  padding: 16.5px 20px 20px 20px;
  z-index: 80;
  box-shadow: var(--card-shadow);

  span {
    display: flex;
    height: fit-content;
    line-height: 25.5px;
  }

  .button {
    width: fit-content;
    padding: 6px 24px;
    min-height: 32px;
  }
}
