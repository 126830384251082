@import 'styles/helpers/variables.scss';

.logo {
  display: flex;
  flex-shrink: 0;
  max-height: 33px;

  @media screen and (max-width: $xs) {
    width: auto;
    height: 24px;
    max-width: 140px;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    width: 100%;
  }
}
