@import '/src/styles/helpers/variables.scss';
@import '/src/styles/global/grid.scss';

.code__button {
  background-color: transparent;
  color: var(--text-light);
  @include font-14-bold;
  border: none;

  &-wrap {
    display: flex;
    justify-content: flex-end;
  }

  &-inner {
    position: relative;
    width: 100%;
    border: var(--text-light);
    background-color: $grey;
    padding: 40px 10px 10px 10px;
  }

  &-copy {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: transparent;
    color: var(--text-light);
    border: none;
  }
}

.buttons {
  &__container {
    &-list {
      display: flex;
      flex-direction: column;
    }

    &-item {
      width: 100%;
      margin-bottom: 40px;

      &-list {
        display: flex;
        margin-bottom: 40px;

        &-item {
          width: 100%;
          max-width: calc(100% / 3 - 20px);
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  @include font-14-bold;
  line-height: unset;
  padding: 0px 32px;
  border-radius: 80px;
  transition: 0.3s;
  border: none;
  background-color: transparent;

  &:disabled {
    cursor: default;
  }
}

.default {
  background: var(--accent);
  color: $white;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    opacity: 0.9;
  }

  &:disabled {
    background-color: var(--accent-light);
    color: var(--accent);
    cursor: default;
    opacity: 0.5;

    &:hover {
      background-color: var(--accent-light);
      color: var(--accent);
    }
  }
}

.primary {
  background-color: var(--accent);
  color: $white;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;

    svg {
      fill: var(--text-light);
    }
  }
}

.primary-light {
  background-color: var(--accent-light);
  color: var(--accent);

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;

    svg {
      fill: var(--text-light);
    }
  }
}

.light {
  background: var(--accent-light);
  color: var(--accent);

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    opacity: 0.9;
  }

  &:disabled {
    background-color: var(--accent-light);
    color: var(--accent);
    cursor: default;
    opacity: 0.5;

    &:hover {
      background-color: var(--accent-light);
      color: var(--accent);
    }
  }
}

.text {
  background-color: transparent;
  color: var(--accent);
  padding: 0;
  min-height: 0;
  @include font-14-bold;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.btn--small {
    padding: 0;
    @include font-12;
    font-weight: 500;
  }
}

.big {
  padding: 9px 32px;
  @include font-14-bold;
  min-height: 46px;
}

.middle {
  padding: 9px 32px;
  @include font-14-bold;
  min-height: 40px;
}

.small {
  padding: 6px 24px;
  font-size: 14px;
  min-height: 32px;
}

.icon-default {
  background-color: var(--accent-light);
  padding: 6px;
  border-radius: 50%;
  min-height: unset;
  height: 32px;
  width: 32px;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.5;
  }

  &.btn--small {
    padding: 10px;
  }
}

.icon-primary {
  background-color: var(--accent);
  padding: 12px;
  border-radius: 50%;
  min-height: unset;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.5;
  }

  &.btn--small {
    padding: 10px;
  }

  @media screen and (max-width: $sm) {
    padding: 5px;
  }
}

.tab {
  background-color: transparent;
  padding: 0;
  min-height: unset;
}

.test {
  background-color: $blue-dark;

  .btn__label--test {
    color: $white;
    margin-right: 20px;
  }

  img {
    margin-right: 10px;
  }
}

.search {
  padding: 0;
  background: transparent;
  min-height: unset;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  left: 0;
  transition: $transition;
  transition-delay: $transition;
}

.btnSpan {
  display: block;
}

.btnSpan.left {
  padding-left: 10px;
}

.btnSpan.right {
  padding-right: 10px;
}

.btn.light.ru {
  background-color: $white;
}
