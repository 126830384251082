@import 'styles/helpers/variables.scss';

.clientPoints {
  display: flex;
  height: 243px;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 24px;
  background: var(--white);
  box-shadow: var(--fire-shadow);

  @media screen and (max-width: 1024px) {
    height: 194px;
  }

  &__header {
    @include font-12;
  }

  &__title {
    display: inline-block;
    color: var(--text-light);
    margin-bottom: 12px;
  }

  &__points {
    display: flex;
    padding: 6px 16px 6px 12px;
    align-items: center;
    gap: 6px;
    border-radius: 24px;
    background: var(--gradient-1);
    max-width: max-content;
    color: var(--white);
    @include font-22-bold;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__link {
    display: block;
    &:first-child {
      margin-bottom: 6px;
    }

    color: var(--accent);
    @include font-14-bold;
    line-height: 140%;
  }
}
