@import 'styles/helpers/variables.scss';

.radio {
  display: flex;
}

.disabledRadio {
  opacity: 0.5;
  cursor: default;
}

.hidden {
  display: none;

  &:checked {
    & ~ .visible {
      background: var(--accent);
    }

    & ~ .visible:after {
      opacity: 1;
    }
  }

  &:disabled {
    & ~ .visible {
      cursor: default;
    }

    & ~ .label {
      cursor: default;
    }
  }
}

.visible {
  width: 18px;
  height: 18px;
  border: 1px solid #9c93aa;
  border-radius: 50%;
  cursor: pointer;
  background-color: #fff;
  transition: $transition;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $white;
    opacity: 0;
    transition: $transition;
  }

  &:hover {
    border-color: var(--accent);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;

  .label,
  .description {
    display: block;
    margin-left: 12px;
    cursor: pointer;
    transition: $transition;

    cursor: default;
  }

  .label {
    @include font-14;
  }

  .description {
    @include font-12;
    color: var(--text-light);
  }
}

.disabled {
  cursor: default;
}
