@import '/src/styles/helpers/variables.scss';

.rulocale {
  --cart-back: none;
  --cart-back-mob: none;
}

.enlocale {
  --cart-back: url('/assets/img/Favorite/favorites-back.png');
  --cart-back-mob: url('/assets/img/Sources/bg-grad-mobile.png');
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 85px 0 0;

  @media screen and (max-width: 960px) {
    padding: 54px 0;
  }
}

.container {
  margin: auto 0 0;

  @media screen and (max-width: 1440px) {
    margin: 20px 0 0;
  }

  .title {
    width: 100%;
    max-width: 494px;
    margin: auto;
    text-align: center;
    @include font-32-bold;
  }

  .text {
    width: 100%;
    max-width: fit-content;
    margin: 8px auto 32px;
    text-align: center;
    @include font-14;
  }

  .button {
    padding: 9px 32px;
    margin: 0 auto;
    text-align: center;
  }

  @media only screen and (max-width: 1024px) {
    .title {
      margin-top: 30px;
      @include font-22-bold;
    }

    .text {
      @include font-14;
    }

    .button {
      padding: 6px 24px;
    }
  }
}

.inner {
  position: relative;
  z-index: 1;
  height: 100%;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
  }
}

.info {
  position: relative;
  z-index: 1;
}

.imgWrap {
  width: 100%;
  margin-top: 25px;

  img {
    display: block;
    margin: auto;
    max-width: 100%;
  }
}

.recommended {
  z-index: 1;
  margin: auto 0 0;
}
