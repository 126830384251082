@import 'styles/main.scss';

.table__wrapper {
  @media (max-width: 1024px) {
    width: 100%;
  }
}

.table {
  &__row {
    display: grid;
    grid-template-columns: 30px 226px 270px 223px 223px 223px;
    border-bottom: 1px solid var(--accent-light);

    @media (max-width: 1350px) {
      grid-template-columns: 30px 226px 270px 150px 223px 140px;
    }

    &_mob {
      display: none;
    }

    &:first-child {
      @include font-12-bold;

      .table__cell {
        display: flex;
        align-items: center;
        gap: 6px;
        font-weight: 500;

        .container {
          flex-direction: column;
        }

        svg {
          width: 16px;
          height: 16px;
        }

        &:first-child {
          padding: 0;
        }
      }
    }
  }

  &__sort {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    outline: none;
    color: var(--text);

    @media (max-width: $sm) {
      @include font-12;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 30px 277px 270px 223px 223px 223px;
  }

  &__cell {
    padding: 12px 16px;

    &:first-child {
      padding: 12px 8px;
      color: var(--text-light);
    }
  }

  &__search {
    padding: 0;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    svg {
      width: 20px;
      height: 20px;
      path {
        stroke: var(--text-light);
      }
    }
  }

  &__link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    color: var(--text);
    cursor: pointer;
    @include font-12;
    gap: 4px;

    svg {
      width: 16px;
      height: 16px;
      stroke: var(--accent);
    }
  }

  &__firstlink {
    color: var(--accent);
  }

  &__header {
    @include font-12;
  }

  &__text {
    @include font-12;
    color: #9c93aa;
  }

  @media (max-width: 1024px) {
    padding: 0;

    &__row {
      padding: 0 8px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      border-bottom: 1px solid #f7f5ff;

      &_mob {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        padding: 0 8px;
      }

      &:first-child {
        display: none;
      }

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    &__content {
      padding: 0 8px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      // align-items: center;
      flex-wrap: wrap;
      border-bottom: 1px solid #f7f5ff;
      position: relative;
    }

    &__cell {
      padding: 0;

      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        order: 2;

        .table__header {
          @include font-12-bold;
          color: var(--text);
        }
      }

      &:nth-child(3) {
        order: 1;
        margin-bottom: 12px;
      }

      &:nth-child(4) {
        order: 0;
        margin-bottom: 12px;
      }

      &:nth-child(5) {
        order: 0;
        position: absolute;
        top: 3px;
        right: 0;

        div {
          display: flex;
          align-items: center;
        }

        div > div:first-child::after {
          content: ',';
          margin-left: 0px;
          margin-right: 4px;
        }
      }

      &:nth-child(6) {
        order: 6;
        margin-top: 24px;

        > div {
          > div {
            @include font-22-bold;
            color: var(--text);
          }
        }
      }
    }

    &__header {
      @include font-12;
      color: #9c93aa;

      @media (max-width: $sm) {
        color: var(--text-light);
      }
    }

    &__sort {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      outline: none;
      color: var(--text);
    }

    &__search_mob {
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      outline: none;

      margin-left: auto;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__list_mob {
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      outline: none;

      margin-left: auto;
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__firstlink {
      color: var(--accent);
      @include font-16-bold;
    }

    &__link {
      font-weight: 500;
      margin-bottom: 2px;
    }

    &__text {
      line-height: 1.4;
    }
  }
}

.currency {
  font-family: $font-currency;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.label {
  width: fit-content;
  padding: 4px 12px;
  border-radius: 36px;
  @include font-11-bold;
}

// IN_WAIT_APPROVE
.type_0 {
  background-color: $blue-ru-light;
  color: $blue-ru;
}

// IN_WORK
.type_1 {
  background-color: var(--accent-light);
  color: var(--accent-light);
}

// IN_OK
.type_2 {
  background-color: $green-ru-light;
  color: var(--ui-02-main);
}

// IN_CANCELLED
.type_3 {
  background-color: $red-ru-light;
  color: var(--accent);
}

// IN_QUEUE
.type_4 {
  background-color: $turquoise-light;
  color: $turquoise;
}

// IN_MANAGER_PROCESSE
.type_5 {
  background-color: $turquoise-light;
  color: $turquoise;
}

// IN_PENDING_REQUEST
.type_6 {
  background-color: $orange-ru-light;
  color: $orange-ru;
}

.search__input {
  input {
    padding: 8px 12px;
  }
}

.search__description {
  text-align: left;
  @include font-11-bold;
}

.description_date {
  @include font-12;
  color: var(--text-light);
}
