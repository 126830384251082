.tab {
    display: inline-flex;
    align-items: center;
    background: $grey;
    border-radius: 32px;
    justify-content: center;
    padding: 7px 12px;
    transition: $transition;
    
    &__text {
        @include font-12-bold-upper;
        position: relative;
        top: 1px;
    }

    &__button {
        display: flex;

        &-wrap {
            display: flex;

            &--left {

                .btn {
                    margin-right: 10px;
                }
            }

            &--right {

                .btn {
                    margin-left: 10px;
                }
            }
        }
    }

    &:hover {
        opacity: 0.8;
    }

    &--disabled {        
        .tab__text {
            color: var(--text-light);
        }

        svg {
            fill: var(--text-light);
        }

        &:hover {
            opacity: 1;

            .btn {
                cursor: default;
            }
        }
    }

    &--prm {
        background-color: var(--accent);

        .tab__text {
            color: $white;
        }

        &.tab--disabled {
            background-color: $grey;

            svg {
                fill: $white;
            }
        }
    }

    &--small {

        .tab__text {
            text-transform: none;
        }
    }
}