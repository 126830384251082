@import 'styles/helpers/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 354px;
  padding: 24px;
  margin: 16px 0 0;
  background: $white;
  box-shadow:
    0px 33px 100px rgba(28, 20, 37, 0.0201946),
    0px 7.37098px 22.3363px rgba(28, 20, 37, 0.0298054),
    0px 2.19453px 6.6501px rgba(28, 20, 37, 0.05);
  border-radius: 36px;

  @media screen and (max-width: $xs-up) {
    width: 100%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    padding: 16px;
  }
}

.image {
  width: 108px;
  height: 108px;
  margin: 0 0 16px;
  border-radius: 50%;

  @media screen and (max-width: $xs-up) {
    width: 92px;
    height: 92px;
  }
}

.title,
.titleForAccountPage {
  margin: 0 0 8px;
  @include font-18-bold;
  color: var(--text);
}

.titleForAccountPage {
  @include font-16-bold;
}

.text,
.textForAccountPage {
  margin: 0 0 24px;
  text-align: center;
  @include font-14;
  color: var(--text);

  @media screen and (max-width: $xs-up) {
    @include font-12;
  }
}

.textForAccountPage {
  @include font-12;
  text-align: left;
  margin: 0;
  color: var(--text-light);
}

.button {
  width: 100%;
  padding: 13px 0;
  text-align: center;
  background: var(--accent-light);
  border-radius: 80px;

  @media screen and (max-width: $xs-up) {
    padding: 9px 0;
  }
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100dvh;
  padding: 20px 20px 60px;
  overflow-y: auto;

  @media screen and (min-width: 960px) {
    height: auto;
    max-width: 480px;
    padding: 40px;
    overflow: hidden;
  }
}

.modalTitle {
  margin: 0 0 20px;
  @include font-22-bold;

  @media (max-width: $sm) {
    @include font-20-bold;
  }
}

.modalBlock {
  display: flex;
  flex-direction: column;
}

.textareaCustomBig {
  position: relative;
  padding: 0 0 24px;

  textarea {
    min-height: 164px;
    max-height: 164px;
    overflow-y: auto;
  }
}

.textareaCustomNormal {
  position: relative;
  padding: 0 0 24px;

  textarea {
    min-height: 72px;
    max-height: 72px;
    overflow-y: auto;
  }

  @media screen and (min-width: 960px) {
    margin: 0;
    padding: 0;
  }
}

.modalButton {
  width: 100%;
  min-height: 40px;
  margin: auto 0 0;
  padding: 9px 32px;
  align-self: start;
  background: var(--accent);
  color: $white;
  border-radius: 80px;
  margin-top: auto;

  @media screen and (min-width: 960px) {
    width: auto;
    min-height: auto;
    margin: 24px 0 0;
  }
}

.modalFeedbackImage {
  position: relative;
  z-index: 5;
  width: 96px;
  height: 96px;
  margin: 10px 0 40px;
  background: var(--check-circle-icon);
  background-repeat: no-repeat;
}

.modalFeedbackText {
  position: relative;
  z-index: 5;
  margin: 0;
  text-align: center;
  @include font-14();
}

.modalFeedbackBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    top: -40px;
    left: -40px;
    bottom: -40px;
    right: -40px;
    z-index: 1;
    border-radius: 36px;
    background: var(--back-shadow-grad);
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.modalFeedbackTitle {
  position: relative;
  z-index: 5;
  margin: 0 0 8px;
  @include font-22-bold();
}

.modalFeedbackButton {
  position: relative;
  z-index: 5;
  margin: 73px 0 0;
  padding: 9px 32px;
  border-radius: 80px;
  @include font-14-bold;
}

.header {
  position: relative;
  z-index: 100;
  width: 100%;
  height: 56px;
  padding: 16px;
  margin: 0 0 20px;
  border-radius: 28px;
  background-color: $white;
  box-shadow:
    0px 15px 125px rgba(201, 180, 250, 0.13),
    0px 4.52206px 37.6838px rgba(201, 180, 250, 0.0847066),
    0px 1.87823px 15.6519px rgba(201, 180, 250, 0.065),
    0px 0.67932px 5.661px rgba(201, 180, 250, 0.0452935);

  @media screen and (min-width: 960px) {
    display: none;
  }

  .wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      display: flex;
      gap: 8px;
      align-items: center;
      @include font-14-bold;

      svg {
        width: 24px;
        height: 24px;
        display: block;
      }
    }

    .close {
      width: 21px;
      height: 21px;

      svg {
        fill: var(--text);
      }
    }
  }
}

.error {
  position: absolute;
  left: 0;
  bottom: 3px;
  width: 100%;
  margin: 0;
  text-align: center;
  @include font-12();
  color: $red;
}

.policy {
  margin: 0;
  @include font-12;

  @media screen and (min-width: 960px) {
    margin: 20px 0 0;
  }

  &__link {
    color: var(--accent);
    font-weight: 500;
  }
}

.containerForAccountPage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
  padding: 0;
  margin: 0;
  background: transparent;
  box-shadow: none;
  border-radius: 0;

  @media screen and (max-width: $xs-up) {
    width: auto;
    max-width: auto;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }

  .wrapperTitle {
    display: flex;
    gap: 12px;
    align-items: center;
    // margin-bottom: 24px;

    .imageForAccountPage {
      width: 48px;
      height: 48px;
      margin: 0;
    }

    .wrapperContent {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
  }
}
