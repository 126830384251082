@import 'styles/main.scss';
@import 'styles/helpers/variables.scss';

.formBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 748px;

  input,
  textarea {
    @include font-14;
    color: var(--text-color);
  }

  .textarea {
    height: 104px;
    padding: 16px 12px;
  }

  .title {
    @include font-16-bold;
    color: var(--text-color);

    &__withCounter {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .counter {
        color: var(--holder-color);
        @include font-14;
      }
    }

    @media screen and (max-width: 1024px) {
      font-size: 16px;

      .counter {
        font-size: 14px;
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .endIconTooltip {
      position: relative;
      margin-left: auto;
      margin-right: 6px;
      align-self: center;

      :global(.common__tooltip.right) {
        left: -215px;
        top: 50%;
        width: 250px;
        z-index: 101;

        @media (max-width: 1024px) {
          transform: translate(-50%, -100%) !important;
        }
      }

      @media screen and (max-width: 1024px) {
        :global(.common__tooltip) {
          transform: translate(-40%, -100%);
        }
      }

      :global(.common__tooltip-inner) {
        background: #615a6d;
        color: #ffffff;
        text-align: left;

        &:after {
          border-top: 10px solid #615a6d;
          @media screen and (max-width: 1024px) {
            right: -80px;
          }
        }
      }

      :global(.common__tooltip--hidden) {
        right: 0;
        bottom: 0;
        left: unset;
        top: unset;
        width: 20px;
        height: 20px;
      }
    }

    .multiselectWrapper {
      min-height: 56px;
      cursor: pointer;
      border-color: #f7f5ff;

      &__input {
        border: 1px solid transparent;

        &:hover {
          border: 1px solid var(--accent-second);
          background: #ffffff !important;
        }
      }
    }
    .generalGoalsWrapper {
      position: relative;
    }

    .generalBlock,
    .detailedBlock {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &.disabled {
        position: relative;
        pointer-events: none;

        &::after {
          content: '';
          background-color: rgba(255, 255, 255, 0.603);
          position: absolute;
          top: 18px;
          left: 0;
          right: 0;
          bottom: -10px;
          pointer-events: none;
          z-index: 1;
        }
      }

      margin-bottom: 20px;
    }

    .goalsBlock {
      margin-bottom: 48px;

      &.disabled {
        position: relative;
        pointer-events: none;

        &::after {
          content: '';
          background-color: rgba(255, 255, 255, 0.5);
          position: absolute;
          top: 0px;
          left: 0;
          right: 0;
          bottom: -10px;
          pointer-events: none;
          z-index: 1;
        }
      }
    }

    .detailedStepsWrapper {
      position: relative;
    }

    .stepsBlock {
      &.disabled {
        position: relative;
        pointer-events: none;

        &::after {
          content: '';
          background-color: rgba(255, 255, 255, 0.5);
          position: absolute;
          top: 0px;
          left: 0;
          right: 0;
          bottom: -10px;
          pointer-events: none;
          z-index: 1;
        }
      }
    }

    .materialsBlock {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &.disabled {
        position: relative;
        pointer-events: none;

        &::after {
          content: '';
          background-color: rgba(255, 255, 255, 0.5);
          position: absolute;
          top: 18px;
          left: 0;
          right: 0;
          bottom: -10px;
          pointer-events: none;
          z-index: 1;
        }
      }
    }

    .bottomBlock {
      display: flex;
      flex-direction: row;
      gap: 16px;
      justify-content: space-between;
      margin-top: 48px;

      .left {
        display: flex;
        flex-direction: row;
        gap: 12px;
      }

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 12px 32px;

        svg {
          fill: var(--accent-color);
          width: 20px;
          height: 20px;
        }
      }

      @media (max-width: $sm-up) {
        flex-direction: column;
        gap: 8px;
        margin: 48px 0 64px 0;

        .left {
          flex-direction: column;
          gap: 8px;
        }
      }
    }

    .secondStep,
    .thirdStep {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .hintBlock {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      &.disabled {
        pointer-events: none;
        position: relative;

        &::after {
          content: '';
          background-color: rgba(255, 255, 255, 0.603);
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: -10px;
          pointer-events: none;
          z-index: 1;
        }
      }

      .disabledPrice {
        position: relative;
        pointer-events: none;

        &::after {
          content: '';
          background-color: rgba(255, 255, 255, 0.603);
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          pointer-events: none;
          z-index: 1;
        }
      }

      .noticeBlock {
        margin: 12px 0;
      }

      .priceBlock {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 20px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 8px;

        @media (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
        }

        .price {
          width: 50%;
          //height: 80px;

          @media (max-width: 768px) {
            width: 100%;
          }
        }

        .currencyBlock {
          display: flex;
          align-items: center;
          width: 50%;
          gap: 20px;

          @media (max-width: 768px) {
            width: 100%;
          }

          .divider {
            @include font-16-bold;
            height: 80px;
            display: flex;
            align-items: center;
            margin-bottom: 25px;
          }

          &__input {
            width: 100%;

            &.disabled {
              position: relative;
              pointer-events: none;
              z-index: 0;

              &::after {
                content: '';
                background-color: rgba(255, 255, 255, 0.603);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                pointer-events: none;
                z-index: 1;
              }
            }
          }

          &__select {
            width: 100%;
            height: 80px;

            &_disabled {
              pointer-events: none;
            }

            &__wrapper {
              width: 100%;
            }
          }
        }
      }

      .checkboxBLock {
        display: flex;
        flex-direction: row;
        gap: 8px;
        font-size: 16px;
        align-items: center;
      }

      .paymentBlock {
        margin-bottom: 8px;
      }

      .countriesBlock {
        margin-bottom: 8px;
      }

      .exampleBlock {
        width: 100%;
        padding: 40px;
        margin-top: 20px;
        box-shadow: var(--card-shadow);
        border-radius: 32px;

        @media (max-width: $sm-up) {
          box-shadow: unset;
        }

        .addCaseBlock {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          @media (max-width: $sm-up) {
            flex-direction: column;
            gap: 16px;
            align-items: flex-start;
          }
        }

        @media (max-width: $sm-up) {
          padding: 20px 0;
        }

        &__image {
          width: 100%;
          border-radius: 24px;
          box-shadow:
            0 4.52px 21.68px 0 #c9b4fa20,
            0 15px 77px 0 #c9b4fa21;
          margin-bottom: 32px;

          @media (max-width: $xs) {
            margin-bottom: 16px;
          }
        }

        &__imageRu {
          width: 100%;
        }

        &__description {
          color: var(--holder-color);
          @include font-12;
          width: 60%;
          margin-right: 20px;

          @media (max-width: 768px) {
            width: 100%;
          }
        }

        .mainButton {
          height: 38px;

          @include font-14-bold;

          .plusIcon {
            display: flex;
            margin-right: 10px;

            svg {
              stroke: #ffffff;
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}

.hintsTooltip {
  width: 270px;
  max-width: unset;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  padding: 40px;
  max-width: 480px;
  @include font-22-bold;
  color: var(--text-color);
  width: fit-content;

  .bottomBlock {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: 24px;
    justify-content: center;

    .button {
      padding: 12px 32px;
      width: 100%;
      white-space: nowrap;
      line-height: 140%;
    }
  }
}

.selectBox {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    @include font-16();
    line-height: 125%;
    color: var(--holder-color);
    padding-right: 10px;
  }

  .wrapper {
    position: relative;
    overflow: unset;
    width: 100%;

    .main-input {
      transition: 0.3s;
      display: flex;
      justify-content: space-between;
      gap: 6px;
      align-items: center;
      border-radius: 12px;
      background: var(--accent-light);
      height: 56px;
      padding: 8px 12px;
      cursor: pointer;
      border: 1px solid transparent !important;
      outline: none !important;

      &:hover {
        border: 1px solid var(--accent-second);
      }

      &.dropActive {
        border: 1px solid var(--accent-color);
        // background: var(--accent-light) !important;
        background: transparent !important;

        &.error {
          border-color: $red-middle;
        }
      }

      &.error {
        background: var(--error-bg);

        &:hover {
          border-color: var(--error-border-hover);
        }

        &.active {
          border: 1px solid var(--error-color);
          background: #ffffff;
        }
      }

      &__solutionForm {
        border-radius: 12px;
        border-color: transparent;
        min-height: 56px;

        .arrow {
          margin: 0 3px 0 0 !important;
          transform: translate(2px, 2px);
        }

        .endIcon {
          display: flex;

          svg {
            width: 20px;
            height: 20px;

            path {
              stroke-width: 1.3px;
            }
          }

          &.errorIcon {
            path {
              stroke: var(--error-color);
            }
          }
        }
      }

      .inputBlock {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        max-width: calc(100% - 60px);
        border: 1px solid transparent;

        .inputTitle {
          @include font-14;
          color: var(--text-light);
          &Selected {
            @include font-12;
          }
        }

        .drop {
          @media screen and (max-width: $xs) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        // &__disabled {
        // opacity: 0.5;
        // }
      }

      &:hover {
        border: 1px solid var(--accent-second);
        // background: #ffffff !important;
        background: transparent !important;
      }

      &.error {
        background: var(--error-bg);

        input:hover {
          border-color: transparent !important;
          background: transparent !important;
        }
      }

      .drop {
        width: 100%;
        color: var(--text-color);

        .additionalValue {
          color: var(--text-light);
        }

        input {
          background-color: transparent;
          border: none;
          cursor: pointer;
          opacity: 1 !important;
          height: 100%;
          padding: 0;
          color: var(--text-color);
          font-size: 16px;
          line-height: 22.4px;
          margin-top: -5px;

          &::placeholder {
            color: var(--holder-color);
            opacity: 1;
          }

          @media (max-width: $sm-up) {
            padding: 8px 8px 6px 12px;
          }
        }
      }

      .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 20px;
        margin-top: 2px;
        cursor: pointer;

        svg {
          width: 13px;
          height: 8px;
          transform: scale(1.2);
        }
      }

      .arrowActive {
        margin: 0 0 2px 0;
        transform: rotate(180deg);
      }
    }

    .main-input-disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.dropdown {
  position: absolute;
  z-index: 100;
  width: 100%;
  background-color: $white;
  box-shadow: var(--shadow-small);
  border-radius: 12px;
  border: 1px solid transparent;
  overflow: hidden;

  .enter-source {
    position: relative;
    margin: 16px 16px 0;
  }

  .list {
    padding: 0;
    margin: 4px 0;
    max-height: 150px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--accent-third);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--accent-second);
    }

    .item {
      display: flex;
      gap: 6px;
      align-items: center;
      padding: 6px 16px;
      @include font-14();
      line-height: 135%;
      color: var(--text-color);
      cursor: pointer;
      margin: 4px;
      border-radius: 4px;

      span {
        color: var(--text-color) !important;
      }

      &:hover {
        background: var(--accent-light) !important;
      }

      @media (max-width: 1024px) {
        padding: 9px 10px;
        margin: 0;
      }
    }

    .active {
      color: var(--accent-color);
      background: var(--accent-third);
      display: flex;

      .checkIcon {
        display: flex;
        align-items: center;
        padding-top: 4px;
        svg {
          fill: var(--accent-color);
        }
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0d09188f;
  z-index: 9998;
  animation: fadeIn 0.3s ease-out;
}

.overlay.close {
  animation: fadeOut 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fixedButton {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  border-radius: 24px 24px 0 0;
  width: 100%;
  height: auto;
  padding: 32px 20px 90px 20px;
  z-index: 9998;
  animation: slideUp 0.3s ease-out;

  .title {
    @include font-20-bold();
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    svg {
      width: 15px;
      height: 14px;
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    .button {
      display: flex;
      border-radius: 20px;
      @include font-16;
      line-height: 22px;
      height: 22px;
      padding: 20px 15px;
      text-align: left;
      justify-content: space-between;
      color: var(--text-color);

      &:hover {
        background: #f7f5ff;
      }

      &.active {
        background: var(--accent-third);

        .checkIcon {
          display: flex;
          margin-top: 4px;

          svg {
            fill: var(--accent-color);
          }
        }
      }
    }
  }

  &.close {
    animation: slideDown 0.3s ease-out;
  }
}

.errorText {
  font-size: 14px;
  margin-top: 4px;
  color: #ea4a70;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

.endIconContainer {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);

  @media screen and (max-width: $xs-up) {
    :global(.common__tooltip-inner) {
      right: -20px !important;
    }

    ::after {
      right: -140px !important;
    }
  }
}

.main-input-focused {
  transition: all 0.3s ease-in-out;
  background: #ffffff !important;

  min-height: 53px !important;
  height: 53px !important;
  padding: 6px 12px;

  &:hover {
    border: 1px solid transparent !important;
  }
}

.small {
  height: fit-content !important;
  min-height: 0 !important;
  padding: 0 !important;
  background-color: transparent !important;

  &:hover {
    border-color: 1px solid transparent !important;
  }
}

.dropdownRadio {
  width: 450px;
  right: 0;
  top: 58px;

  .list {
    margin: 0;
    overflow: visible;
    max-height: fit-content;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.dropdownRadioSmall {
  top: 30px;
}

.main-input-inInput {
  transition: all 0.3s ease-in-out;

  min-height: 53px !important;
  height: 53px !important;
  padding: 6px 12px;

  &:hover {
    border: 1px solid transparent !important;
  }
}

.inputBlock-notHoverEffect {
  &:hover {
    background-color: transparent !important;
  }
}
