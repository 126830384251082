@import 'styles/helpers/variables.scss';

.goals {
  &__main {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    min-height: 128px;
    padding: 12px;
    gap: 8px;
    margin-bottom: 24px;
    border-radius: 20px;
    background: var(--border-dotted);
    box-shadow: var(--top-shadow);
  }

  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 4px 12px;
    gap: 4px;
    border-radius: 36px;
    border: 1px solid var(--accent);
    @include font-12;
    transition: background 0.3s;
    &:hover {
      background: var(--accent-light);
    }

    .input {
      width: 10px;
      min-width: 10px;
      border: none;
      outline: none;
      resize: none;
    }

    .goalLength {
      color: var(--accent-second);
      margin-left: 4px;
    }

    div {
      overflow: hidden;
      white-space: nowrap;
    }
    div[contenteditable='true'] {
      @include font-12;
      min-width: 1ch;
    }
    svg {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      stroke: var(--accent);
    }

    &_active {
      svg {
        transform: rotate(45deg);
      }
    }

    &_add {
      background: var(--accent-light);
      border: 1px solid transparent;
      color: var(--accent);
      cursor: pointer;

      div[contenteditable='true'] {
        display: none;
      }
      svg {
        display: none;
      }
    }
  }

  &__bottom_text {
    @include font-12;
    color: var(--text-light);
    margin-bottom: 12px;
  }

  &__bottom {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
  }

  &__empty_block {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
  }
  &__empty_text {
    color: var(--text-light);
    margin: 0;
  }
  &__empty_btn {
    display: flex;
    align-items: center;
    background: var(--accent-light);
    color: var(--accent);
    padding: 6px 20px 6px 24px;
    border-radius: 50px;
    gap: 8px;
    font-weight: 500;

    svg {
      stroke: var(--accent);
    }
  }
}

@media (max-width: 1024px) {
  .goals {
    &__main {
      margin-bottom: 20px;
    }
  }
}
