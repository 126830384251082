@import 'styles/main.scss';

.toast-container {
  position: relative;
  align-items: center;
  z-index: 9999;

  &_new {
    border: none;
    width: 480px;

    .titleWrapper {
      margin: unset !important;
    }

    @media screen and (max-width: $xs-up) {
      width: 100%;
    }

    .toast.success {
      border: none !important;
      align-items: flex-start;
      padding: 16px 40px 16px 16px !important;
      border-radius: 24px;
      background: $success-notification;
      backdrop-filter: blur(4px);
      color: var(--text);

      @media screen and (max-width: $xs-up) {
        padding-right: 16px;
      }

      .titleText {
        top: unset;
        @include font-14-bold;
        font-weight: var(--font-weight-bold);
      }

      .titleWrapper {
        .text {
          top: unset;
        }
      }
    }

    .toast.error {
      border: none !important;
      align-items: flex-start;
      padding: 16px 40px 16px 16px !important;
      border-radius: 24px;
      background: $error-notification;
      backdrop-filter: blur(4px);
      color: var(--text);
    
      @media screen and (max-width: $xs-up) {
        padding-right: 16px;
        margin: 0 5px;
      }
    
      .titleText {
        top: unset;
        @include font-14-bold;
        font-weight: var(--font-weight-bold);
      }
    
      .titleWrapper {
        .text {
          top: unset;
        }
      }
    }
    

    .icon {
      display: flex;
      border-radius: 32px;
      width: 28px;
      height: 28px;
      padding: 6px;
      background: #37B24D1F;
      margin-top: 0 !important;

      svg {
        width: unset;
        height: unset;
      }
    }
  }
}

.toast {
  display: flex;
  align-items: baseline;
  border-radius: 8px;
  padding: 16px 40px 16px 16px;
  gap: 12px;

  &.success {
    background: linear-gradient(
        0deg,
        rgba(0, 200, 83, 0.04),
        rgba(0, 200, 83, 0.04)
      ),
      #ffffff;
    border: 1px solid var(--ui-02-main);
  }

  &.success_second {
    background: linear-gradient(
        0deg,
        rgba(64, 192, 87, 0.08) 0%,
        rgba(64, 192, 87, 0.08) 100%
      ),
      rgba(255, 255, 255, 0.9);
    padding: 16px;
    width: 358px;
    border-radius: 24px;
  }

  &.error {
    background: linear-gradient(
        0deg,
        rgba(245, 34, 45, 0.04),
        rgba(245, 34, 45, 0.04)
      ),
      #ffffff;
    border-radius: 24px;

    .icon {
      background: rgba(234, 74, 74, 0.12);
      padding: 5px 6px 7px 6px;
        }
  }
}

.icon {
  display: flex;
  flex-shrink: 0;

  .iconUI {
    width: 20px;
    height: 20px;
  }

  &__circle {
    width: 28px;
    height: 28px;
    background: rgba(134, 206, 83, 0.12) !important;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: -10px !important;

  .titleText {
    position: relative;
    top: -5px;
    font-size: 14px;
    font-weight: 700;
    color: var(--text);

    &.success_second {
      color: var(--text);
      line-height: 140%;
      transform: translateY(-2px);
    }
  }

  .text {
    position: relative;
    top: -5px;
    color: var(--text);

    &.success_second {
      @include font-12-bold;
      color: var(--text);
      line-height: 140%;
    }
  }
}
