@import 'styles/helpers/variables.scss';

.customTitle {
  @include font-18-bold;
}

.modal {
  padding: 40px;
  display: flex;
  flex-direction: column;
  max-width: 480px;
  gap: 24px;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__title {
    display: inline-block;
    color: var(--text);
    @include font-22-bold;
  }

  &__description {
    color: var(--text);
    @include font-14;
  }

  &__captcha {
    width: 300px;
  }

  &__button_wrapper {
    width: 100%;
  }

  &__button {
    width: 100%;
    padding: 12px 32px;
  }

  @media (max-width: $sm) {
    width: 100%;
    padding: 0;
    gap: 8px;
    height: 400px;
    justify-content: space-between;

    &__title {
      display: none;
    }

    &__description {
      color: var(--text);
      @include font-14;
      margin-bottom: 16px;
    }

    &__captcha {
      width: 300px;
    }

    &__button {
      width: 100%;
      padding: 12px 32px;
      align-self: flex-end;
    }
  }
}
