@import 'styles/main.scss';

.wrapper {
  padding: 48px 0;

  @media (max-width: 1024px) {
    padding: 48px 0 110px 0;
  }
}

.title {
  @include font-32-bold;

  span {
    position: absolute;
    color: var(--text-light);
    @include font-12-bold;
  }
}

.title_ru {
  margin-bottom: 24px;
}

.balance {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 24px;
  border-radius: 24px;
  background-color: $white;
  box-shadow: var(--top-shadow);

  @media (max-width: 960px) {
    margin-left: -20px;
    width: calc(100% + 40px);
  }

  &-count {
    .balance-title {
      @include font-11-bold;
      color: var(--text-light);
    }

    .balance-value {
      @include font-22-bold;
      margin: 0;
    }
  }

  &-bonus {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 2px 8px 2px 6px;
    border-radius: 24px;
    @include font-11-bold;
    color: $white;
    background: var(--gradient-3);

    @media (max-width: 960px) {
      cursor: default;
    }

    &-tooltip {
      position: absolute;
      right: -214px;
      top: 50%;
      width: 205px;
      color: white;
      background-color: var(--tooltip);
      padding: 6px 12px;
      border-radius: 8px;
      @include font-12-bold;
      transform: translateY(-50%);
      opacity: 0;
      visibility: hidden;
      transition:
        opacity 0.3s ease-in-out,
        visibility 0.3s ease-in-out;

      @media (max-width: 960px) {
        display: none;
      }

      &::before {
        content: '';
        position: absolute;
        left: -6px;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;
        height: 12px;
        background-color: var(--tooltip);
        clip-path: polygon(100% 0, 0 50%, 100% 100%);
      }
    }

    &:hover &-tooltip {
      opacity: 1;
      visibility: visible;
    }
  }
}

.right {
  padding: 24px;
  background-color: $white;
  border-radius: 36px;
  box-shadow: var(--top-shadow);
  margin-top: 0;

  @media (max-width: 960px) {
    margin-top: 24px;
    margin-left: -20px;
    width: calc(100% + 40px);
  }

  .tabs__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .desktop__btn {
      @media (max-width: 960px) {
        display: none;
      }
    }
  }

  .tabs {
    display: inline-flex;
    border-radius: 36px;
    background: var(--accent-light);
    @include font-14;
  }

  .tab {
    cursor: pointer;
    background-color: transparent;
    padding: 4px;

    div {
      border-radius: 36px;
      padding: 6px 16px 5px 16px;
      display: flex;
      align-items: center;
      // text-transform: capitalize;

      span {
        margin-left: 6px;
        color: var(--accent-second);
      }
    }

    input {
      display: none;
    }

    input:checked + div {
      box-shadow: 0px 4px 30px 0px #e4736821;
      background: $white;
      color: var(--accent);
    }

    .tab_label {
      @include font-14;
    }
  }
}

.left {
  .top-up-balance {
    width: 100%;
    height: 44px;
  }

  .top-up-balance-scroll {
    position: fixed;
    bottom: -400px;
    width: calc(100% - 40px);
    z-index: 10;
    transition: transform 0.5s ease-in-out;
  }

  .scrollBtn {
    transform: translateY(-475px);
  }
}

.empty {
  padding: 103.5px 50px;
  border-radius: 36px;
  background: $white;
  box-shadow:
    0px 4.522px 37.684px 0px rgba(201, 180, 250, 0.12),
    0px 15px 125px 0px rgba(201, 180, 250, 0.13);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__header {
    margin-bottom: 16px;
    @include font-32-bold;
  }

  &__text {
    margin-bottom: 40px;
  }

  &__back {
    border-radius: 80px;
    background: var(--accent);
    padding: 9px 32px;
    @include font-14;
    font-weight: 500;
    color: $white;
  }

  @media (max-width: 960px) {
    margin: 0 -20px;
    padding: 66px 20px;

    &__header {
      margin-bottom: 8px;
      @include font-22-bold;
    }

    &__text {
      margin-bottom: 32px;
      @include font-12;
    }
  }
}

.main {
  padding: 24px 24px 40px 24px;
  border-radius: 24px;
  background: $white;
  box-shadow:
    0px 4.522px 37.684px 0px rgba(201, 180, 250, 0.12),
    0px 15px 125px 0px rgba(201, 180, 250, 0.13);

  @media (max-width: 960px) {
    margin: 0 -20px;
    padding: 24px 20px;
  }
}

.table {

  &__header {
    @media screen and (max-width: $sm) {
      &__issued {
        font-size: 11px !important;
      }

      &__link {
        @include font-12-bold;
        color: var(--text-color);
      }
    }

  }

  &__row {
    display: grid;
    grid-template-columns: 30px 277px 270px 223px 223px 223px;
    border-bottom: 1px solid var(--accent-light);

    @media (max-width: 1350px) {
      grid-template-columns: 30px auto 270px 150px 223px 140px;
    }

    &_mob {
      display: none;
    }

    &:first-child {
      font-weight: bold;

      .table__cell {
        display: flex;
        align-items: center;
        gap: 6px;

        svg {
          width: 16px;
          height: 16px;
        }

        &:first-child {
          padding: 0;
        }
      }
    }
  }

  &__cell {
    padding: 12px 16px;

    &:first-child {
      padding: 12px 0;
      color: #9c93aa;
    }
  }

  &__search {
    padding: 0;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    svg {
      width: 20px;
      height: 20px;
      path {
        stroke: var(--text-light);
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    color: var(--text);
    @include font-12;
    gap: 4px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__firstlink {
    color: var(--accent);
  }

  &__text {
    @include font-11;
    color: var(--text-light);
    display: -webkit-box;
    -webkit-line-clamp: 4; 
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
      -webkit-line-clamp: 2;
    }
  }

  &__status {
    width: fit-content;
    padding: 4px 12px;
    border-radius: 36px;
    @include font-11-bold;
    font-size: 11px !important;

    &__paid {
      color: var(--success-16) !important;
      background: rgba(var(--success-16-rgb), 0.08);
    }

    &__sent {
      color: var(--ui-04-main) !important;
      background: rgba(var(--ui-04-main-rgb), 0.08);
    }

    &__partially_paid {
      color: var(--warning-main) !important;
      background: rgba(var(--warning-main-rgb), 0.08);
    }

    &__cancelled {
      color: var(--text-light) !important;
      background: rgba(var(--text-light-rgb), 0.08);
    }

    &__overdue {
      color: var(--error-main) !important;
      background: rgba(var(--error-main-rgb), 0.08);
    }
  }

  &__button {
    background: var(--accent);
    color: $white;
    @include font-12-bold;
    border: none;
    padding: 6px 24px 6px 20px;
    border-radius: 80px;

    &:disabled {
      opacity: 0.6;
      background: $grey;
      color: var(--text-light);
      cursor: default;
    }
  }

  @media (max-width: 1024px) {
    padding: 0;

    &__row {
      padding: 0 8px 20px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      border-bottom: 1px solid #f7f5ff;

      &_mob {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        padding: 0 8px;
      }

      &:first-child {
        display: none;
      }

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    &__cell {
      padding: 0;

      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        order: 2;
        margin-bottom: 12px;
      }

      &:nth-child(3) {
        order: 3;
        width: 100%;
        margin-bottom: 24px;
      }

      &:nth-child(4) {
        order: 1;
        display: flex;
        align-items: center;
        gap: 0.6em;
        width: 100%;
        margin-bottom: 12px;
        line-height: 140%;
      }

      &:nth-child(5) {
        order: 4;

        .table__header {
          @include font-22-bold;
          color: var(--text);
          line-height: 120%;
        }
      }

      &:nth-child(6) {
        order: 5;
        margin-left: auto;
      }
    }

    &__sort {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      outline: none;
      color: var(--text);
    }

    &__search_mob {
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      outline: none;

      margin-left: auto;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__list_mob {
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      outline: none;

      margin-left: 12px;
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__firstlink {
      color: var(--accent);
      font-size: 18px;
      font-weight: 500;
      line-height: 140%;
    }

    &__link {
      font-weight: 500;
      margin-bottom: 2px;
    }

    &__text {
      line-height: 1.4;
    }
  }
}

.pagination {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 960px) {
    margin-top: 16px;
  }
}
// .container {
//   position: relative;
//   overflow: hidden;

//   @media screen and (min-width: 1024px) {
//     height: calc(100vh - 148px);
//   }

//   .title {
//     width: 100%;
//     max-width: 494px;
//     height: 47px;
//     margin: auto;
//     text-align: center;
//     @include font-32-bold;
//   }

//   .text {
//     width: 100%;
//     max-width: 494px;
//     height: 62px;
//     margin: 16px auto 0;
//     text-align: center;
//     @include font-22;
//   }

//   .button {
//     width: 205px;
//     height: 40px;
//     margin: 48px auto 0;
//     text-align: center;
//   }

//   @media only screen and (max-width: 1024px) {
//     .title {
//       text-align: center;
//       @include font-32-bold;
//     }

//     .text {
//       margin: 13px auto 0;
//       text-align: center;
//       @include font-20;
//     }
//   }

//   @media only screen and (max-width: 360px) {
//     .title {
//       height: 29px;
//       @include font-22-bold;
//     }

//     .text {
//       height: 44px;
//       margin: 8px auto 0;
//       @include font-14;
//     }

//     .button {
//       height: 32px;
//       margin: 32px auto 0;
//     }
//   }
// }

// .inner {
//   position: relative;
//   z-index: 1;
//   height: 100%;

//   @media screen and (max-width: 960px) {
//     display: flex;
//     flex-direction: column;
//   }
// }

// .info {
//   padding-top: 120px;
//   position: relative;
//   z-index: 1;

//   @media screen and (max-width: 1440px) {
//     padding-top: 90px;
//   }
// }

// .imgWrap {
//   width: 100%;
//   margin: 0 auto;
//   max-width: 900px;
//   display: flex;

//   img {
//     display: block;
//     width: 100%;
//     transform: translateY(16%);
//   }

//   @media screen and (max-width: 960px) {
//     display: flex;
//     position: relative;
//     height: 100%;
//     align-items: center;
//     bottom: -7%;

//     img {
//       display: block;
//       width: 800px;
//       position: absolute;
//       left: 50%;
//       transform: translateX(-50%);
//       top: 0;
//     }
//   }

//   @media screen and (max-width: 650px) {
//     img {
//       display: block;
//       width: 700px;
//       position: absolute;
//       left: 50%;
//       transform: translateX(-50%);
//       top: 0;
//     }
//   }

//   @media screen and (max-width: 440px) {
//     img {
//       width: 550px;
//     }
//   }
// }

// .gradient {
//   width: 100%;

//   @media only screen and (max-width: 960px) {
//     background-image: url("../../assets/img/Finances/BG-Grad.png");
//     position: relative;
//     background-size: cover;
//     min-height: 60vh;
//   }

//   @media only screen and (max-width: 360px) {
//     background-image: url("../../assets/img/Finances/BG-Grad-Small.png");
//     position: relative;
//     left: calc(-50vw + 50%);
//     background-size: cover;
//     width: 360px;
//     min-height: 70vh;
//   }
// }
