@import 'styles/helpers/variables.scss';

.wrapper {
  margin-top: 96px;
}

.title {
  margin-bottom: 24px;
  @include font-22-bold;

  @media (max-width: $sm) {
    @include font-22-bold;
  }
}

.cases {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.case {
  background-color: $white;
  box-shadow: var(--preview-box-shadow);
  border-radius: 36px;
  max-width: 777px;
  padding: 24px;
  display: flex;
  gap: 32px;
  position: relative;
  flex-direction: row;

  @media screen and (max-width: $netbook) {
    flex-direction: column;
  }

  @media screen and (max-width: $xs) {
    flex-direction: column;
    gap: 20px;
    border-radius: 24px;
  }
}

.image-wrapper {
  width: 128px;
  display: flex;
  align-self: flex-start;

  @media screen and (max-width: 769px) {
    width: 64px;
    height: 64px;
  }
}

.image {
  width: 100%;
  object-fit: contain;
  border-radius: 12px;
  margin-right: 12px;

  @media screen and (max-width: 769px) {
    width: 80px;
    height: 100%;
  }
}

.info {
  width: 80%;

  @media screen and (max-width: $xs) {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
  }

  @media screen and (max-width: $lg) {
    width: 100%;
  }
}

.head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 24px;
  margin-bottom: 8px;
  padding-right: 96px;

  @media screen and (max-width: 769px) {
    padding-right: unset;
  }

  .type {
    @include font-12;

    span {
      margin: 0 4px;
    }
  }
}

.tags {
  display: flex;
  gap: 8px;
  position: absolute;
  top: 24px;
  right: 24px;

  @media (max-width: 1024px) {
    max-width: 50%;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  .tag {
    background: var(--accent-light);
    border-radius: 36px;
    padding: 9px 12px 7px 12px;
    width: fit-content;
    color: var(--accent-color);
    height: 32px;
    @include font-12-bold;

    &_img {
      padding: 6px;
      background: var(--accent-light);
      border-radius: 50px;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.body {
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  justify-content: space-between;
  padding-right: 96px;

  @media screen and (max-width: 769px) {
    height: auto;
    gap: 16px;
    padding-right: unset;
  }
}

.name {
  @include font-16-bold;
  width: 100%;
  max-width: 470px;

  @media screen and (max-width: $xs) {
    max-width: 100%;
  }
}

.button {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-top: 6px;
  @include font-12-bold;
  path {
    stroke: var(--accent-color);
  }
}

.rotate {
  transform: rotate(180deg);
}

.kpises {
  display: flex;
  gap: 8px;
  width: 100%;
  margin-top: 20px;
  flex-wrap: nowrap;

  @media screen and (max-width: $sm) {
    flex-wrap: wrap;
  }

  .kpi {
    border-radius: 12px;
    background-color: var(--accent-light);
    padding: 16px;
    height: 140px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 25%;

    .description {
      @include font-12;
    }

    .amount {
      background: var(--text-gradien);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      @include font-16-bold;
    }

    @media screen and (max-width: $sm) {
      min-width: calc(50% - 10px);
    }

    @media screen and (max-width: $phone) {
      min-width: calc(50% - 5px);

      .description {
        @include font-9;
      }

      .amount {
        @include font-12-bold;
      }
    }
  }
}

.modalContainer {
  max-width: 480px;
  width: 100%;
  padding: 40px;

  @media screen and (max-width: $sm) {
    height: 100%;
    padding-bottom: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media (max-width: $sm) {
  .del_modal {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(13, 9, 24, 0.56);
    backdrop-filter: blur(1.5px);
    z-index: 101;

    > div:nth-child(1) {
      display: none;
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      max-width: unset;
      margin: 0 -12px;
    }

    .modalContainer {
      background: #fff;
      padding: 32px 20px 64px 20px;
      border-radius: 28px 28px 0 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: unset;
      position: relative;
      height: fit-content;
    }

    .modalTitle {
      @include font-20-bold;
      margin-bottom: 12px;
    }

    .modalText {
      @include font-14;
    }

    .buttonModalWrap {
      margin-top: auto;
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 0;
    }

    .buttonModal {
      padding: 13px 32px;
    }
  }
}

.modalInner {
  margin-bottom: 40px;
  @media (max-width: 1024px) {
    margin-bottom: 14px;
  }
}

.modalTitle {
  @include font-22-bold;
  display: block;
  margin-bottom: 8px;
}

.modalClose {
  display: none;
  position: absolute;
  top: 35px;
  right: 20px;
  background: none;
  padding: 0;
  margin: 0;
  border: none;

  .close-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  @media (max-width: $sm) {
    display: block;
  }
}

.hidden {
  display: none;
}
