@import 'styles/helpers/variables.scss';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: none;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.open {
  display: flex;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: rgba(13, 9, 24, 0.56);
  backdrop-filter: blur(1.5px);
}

.modal {
  position: relative;
  z-index: 103;
  width: 100%;
  height: 100%;
  background-color: $white;

  @media screen and (min-width: 960px) {
    max-width: 480px;
    height: auto;
    border-radius: 36px;
    box-shadow:
      0px 33px 100px rgba(28, 20, 37, 0.0201946),
      0px 7.37098px 22.3363px rgba(28, 20, 37, 0.0298054),
      0px 2.19453px 6.6501px rgba(28, 20, 37, 0.05);
  }
}

.close {
  position: absolute;
  right: -50px;
  z-index: 101;

  svg {
    width: 15px;
    height: 14px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 36px;
  cursor: auto;
  background-size: cover;

  @media screen and (min-width: 960px) {
  }
}
