@import 'styles/main.scss';

.container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;

  .logo {
    position: absolute;
    width: auto;
    height: 40px;
    top: 48px;
    left: 48px;
    z-index: 10;

    &_ru {
      width: auto;
      height: 24px;
    }

    @media screen and (max-width: 1024px) {
      width: auto;
      height: 26px;
      top: 16px;
      left: 16px;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: 100%;
  }
}

.left {
  position: relative;
  width: 43%;
  background: var(--gradient-2);
  padding: 141px 48px;

  .image {
    position: absolute;
    width: calc(100% - 96px);
    bottom: 0;
    right: 48px;
    left: 48px;
    object-fit: cover;

    @media screen and (max-width: 1024px) {
      width: 100%;
      max-width: 133px;
      top: 70px;
      right: 16px;
      left: auto;
      filter: drop-shadow(0px 33px 100px rgba(28, 20, 37, 0.0201946))
        drop-shadow(0px 7.37098px 22.3363px rgba(28, 20, 37, 0.0298054))
        drop-shadow(0px 2.19453px 6.6501px rgba(28, 20, 37, 0.05));
    }
  }

  .image-two {
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0;
    width: 187px;
  }

  .left-title {
    color: $white;
    margin-bottom: 16px;
    @include font-18;

    @media screen and (max-width: 1024px) {
      @include font-12;
      max-width: 171px;
    }

    @media screen and (max-width: 350px) {
      text-align: start;
      max-width: 150px;
    }
  }

  .left-text {
    color: $white;
    @include font-30-bold;

    @media screen and (max-width: 1024px) {
      @include font-14-bold;
      text-align: start;
      max-width: 171px;
    }

    @media screen and (max-width: 350px) {
      @include font-12-bold;
      text-align: start;
      max-width: 180px;
    }
  }

  .success {
    object-fit: contain;
    width: 441px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 100%;

    @media screen and (max-width: 1024px) {
      width: 246px;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 288px;
    padding: 92px 16px;
  }
}

.right {
  position: relative;
  width: 57%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 156px;

  @media screen and (max-width: 1270px) {
    padding: 0 80px;
  }

  @media screen and (max-width: 1024px) {
    position: static;
    z-index: 11;
    width: 100%;
    padding: 24px 16px;
    margin-top: -27px;
    border-radius: 28px 28px 0px 0px;
    background-color: $white;
  }

  .invitation {
    padding: 4px 12px;
    border-radius: 36px;
    @include font-11-bold;
    color: var(--accent);
    background-color: var(--accent-light);
    margin-bottom: 20px;
    width: fit-content;
  }

  .title {
    color: var(--text);
    margin-bottom: 4px;
    @include font-28-bold;

    span {
      background: var(--gradient-1);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @media screen and (max-width: 1024px) {
      @include font-22-bold;
    }
  }

  .text {
    @include font-14;
    margin-bottom: 32px;

    span {
      display: flex;
    }

    .link {
      margin-left: 8px;
      @include font-14-bold;

      a {
        color: var(--accent);
      }
    }
  }
}

.word-logo {
  width: 152px;
  margin-left: 4px;

  @media screen and (max-width: 1024px) {
    width: 114px;
  }
}

.form {
  .fields {
    display: grid;
    row-gap: 32px;
  }

  .input-field {
    position: relative;

    .password {
      position: absolute;
      right: 0;
      top: 0;
      @include font-12-bold;

      a {
        color: var(--accent);
      }
    }

    .svg {
      fill: var(--text-light);
    }
  }

  .check {
    margin-top: 20px;
    width: fit-content;
  }

  .button {
    height: 40px;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}

.error {
  position: absolute;
  color: $red;
  width: fit-content;
  right: 22px;
  bottom: 4px;
  z-index: 10;
}

.captcha {
  position: relative;
  margin-top: 36px;
  margin-bottom: 30px;

  @media screen and (max-width: 1024px) {
    margin-top: 28px;
    margin-bottom: 22px;
  }
}

.mobile-error {
  background: linear-gradient(
      0deg,
      rgba(245, 34, 45, 0.04),
      rgba(245, 34, 45, 0.04)
    ),
    #ffffff;
  border: 1px solid $red;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 40px;
  padding: 8px 16px;

  @media screen and (max-width: 1024px) {
    margin-top: 0px;
    margin-bottom: 15px;
  }
}

.show-password {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  right: 13px;
  bottom: 0px;
  z-index: 10;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    bottom: 1px;
  }
}

.lang {
  position: absolute;
  top: 48px;
  right: 48px;
  height: 30px;

  @media screen and (max-width: 1024px) {
    top: 16px;
    right: 16px;
  }
}

.multiselect {
  width: 55px;
}

.disabledCaptcha {
  margin-top: 36px;

  @media screen and (max-width: 1024px) {
    margin-top: 28px;
  }
}
