@import 'styles/helpers/variables.scss';

.clientLevel {
  display: flex;
  height: 243px;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 24px;
  box-shadow: var(--fire-shadow);
  color: var(--white);

  @media screen and (max-width: 1024px) {
    height: 194px;
  }

  &.Signature {
    background: var(--gradient-1);
  }

  &.Platinum {
    background: var(--gradient-platinum);
  }

  &.Gold {
    background: var(--gradient-gold);
  }

  &__header {
    @include font-12;
    span {
      display: block;
    }
  }

  &__title {
    color: var(--accent-light);
    margin-bottom: 12px;
  }

  &__name {
    @include font-20-bold;
    line-height: 140%;
    margin-bottom: 4px;
    text-transform: capitalize;
  }

  &__description {
    overflow: hidden;
    text-overflow: ellipsis;
    @include font-12;
    line-height: 140%;
    max-height: 34px;
    margin: 0;
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 10px;
    color: inherit;
    @include font-14-bold;
    line-height: 140%;

    svg {
      transform: rotate(180deg) scale(1.2);
      fill: var(--white);
    }
  }
}
