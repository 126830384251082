@import 'styles/helpers/variables.scss';

.container {
  height: 100%;
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    width: 100%;
    background-image: var(--hotdeals-back);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
  }

  @media screen and (max-width: 960px) {
    &:after {
      background-size: cover;
      background-image: url('/assets/img/Sources/sources-back-mobile.png');
    }
  }

  .title {
    width: 100%;
    max-width: 494px;
    height: 47px;
    margin: auto;
    text-align: center;
    @include font-32-bold;
  }

  .text {
    width: 100%;
    max-width: 494px;
    height: 62px;
    margin: 16px auto 0;
    text-align: center;
    @include font-22;

    p {
      margin: 0;
    }
  }

  .button {
    width: 205px;
    height: 40px;
    margin: 48px auto 0;
    text-align: center;
  }

  @media only screen and (max-width: 1024px) {
    .title {
      text-align: center;
      @include font-32-bold;
    }

    .text {
      margin: 13px auto 0;
      text-align: center;
      @include font-20;
    }
  }

  @media only screen and (max-width: 360px) {
    .title {
      height: 29px;
      @include font-22-bold;
    }

    .text {
      height: 44px;
      margin: 8px auto 0;
      @include font-14;
    }

    .button {
      height: 32px;
      margin: 32px auto 0;
    }
  }
}

.inner {
  position: relative;
  z-index: 1;
  height: 100%;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
  }
}

.info {
  padding-top: 120px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1440px) {
    padding-top: 90px;
  }
}

.imgWrap {
  width: 100%;
  bottom: 150px;
  margin: auto;
  left: 0;
  right: 0;
  max-width: 450px;
  display: flex;
  align-items: flex-end;
  position: fixed;

  img {
    display: block;
    width: 100%;
  }

  @media screen and (max-width: 1440px) {
    bottom: 70px;
  }

  @media screen and (max-width: 1366px) {
    max-width: 300px;
  }

  @media screen and (max-width: 960px) {
    max-width: 250px;
  }
}

.gradient {
  width: 100%;

  @media only screen and (max-width: 960px) {
    background-image: var(--hotdeals-back);
    position: relative;
    background-size: cover;
    min-height: 60vh;
  }

  @media only screen and (max-width: 360px) {
    background-image: url('/assets/img/Sources/BG-Grad-Small.png');
    position: relative;
    left: calc(-50vw + 50%);
    background-size: cover;
    width: 360px;
    min-height: 70vh;
  }
}
