@import "/src/styles/helpers/variables.scss";

.wrapper {
  min-height: 375px;
  margin-top: 96px;
  margin-bottom: 30px;

  @media screen and (max-width: $xs-up) {
    margin-top: 64px;
  }
}

.title {
  position: relative;
  margin: 0 0 15px;
  @include font-22-bold;
  color: var(--text);

  @media screen and (max-width: 1024px) {
    @include font-22-bold;
  }
}

.swiper-wrapper {
  margin-left: -75px;
  margin-right: -75px;
  padding: 0 75px 0 75px;
  overflow-x: clip;
  // align-items: stretch;

  @media screen and (max-width: $lg) {
    margin-left: -50px;
    margin-right: -50px;
    padding: 0 50px 0 50px;
  }

  @media screen and (max-width: $netbook) {
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px 0 20px;
  }
}

.swiper {
  // margin: -20px;
  padding: 0px 50px 0 0;
  // overflow: hidden;
  overflow: visible;

  @media screen and (max-width: $xs-up) {
    padding: 0px 0px 0 0;
  }
}

.swiper-slide {
  // width: 100%;
  max-width: calc(100% / 4 - 10px);
  min-width: 309px;
  height: auto;
  // opacity: 0;

  @media screen and (max-width: 1024px) {
    max-width: unset;
    width: 309px;
  }
}

.content {
  height: 100%;
}

.swiper-slide {
  height: auto;
  transition: opacity ease-in-out 0.4s;
}

:global(.swiper-slide) {
  transition: opacity ease-in-out 0.4s;
  opacity: 0;
}

:global(.swiper-slide-visible) {
  transition: opacity ease-in-out 0.4s;
  opacity: 1;
}

:global(.swiper-button-next) {
  right: -20px;
}

:global(.swiper-button-prev) {
  left: -20px;
}